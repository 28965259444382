<template>
  <div
      v-tooltip="!exportReportAccess?.allowed ? exportReportAccess.error?.message : ''"
      class="relative"
  >
    <b-dropdown
        id="dropdown-right"
        :disabled="getReportsServiceCalls.export || disabled || !exportReportAccess?.allowed"
        variant="studio-theme"
        class="studio-theme-dropdown studio-theme-dropdown-blue dropdown-size-small pr-0"
        :class="{ 'opacity-70 pointer-events-none': !exportReportAccess?.allowed }"
        no-caret
    >
      <template v-if="getReportsServiceCalls.export" v-slot:button-content>
        <div class="opacity-70" style="display: flex; align-items: center">
          <span>Exporting </span>
          <clip-loader
              class="ml-2"
              :color="'#ffffff'"
              :size="'14px'"
          ></clip-loader>
        </div>
      </template>
      <template v-else v-slot:button-content>
        <i class="icon-download-cs studio-theme-icon"></i>
        <span class="capitalize_text">Export</span>
        <i class="icon-dropdown-cs"></i>
      </template>
      <b-dropdown-item
          v-if="exportReportAccess?.allowed"
          @click.prevent="saveReport"
      >Export PDF</b-dropdown-item>
      <b-dropdown-item
          v-if="exportReportAccess?.allowed"
          @click="sendReportByEmail"
      >Send PDF as Email</b-dropdown-item>
      <b-dropdown-item
          v-if="exportReportAccess?.allowed"
          @click="schedulePDF"
      >Schedule PDF as Email</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import textHelper from '@common/lib/text-helper'
import {EventBus} from "@common/lib/event-bus";
import useDateFormat from "@common/composables/useDateFormat";
import {useFeatures} from "@modules/billing/composables/useFeatures";

export default {
  name: 'ExportButton',
  props: {
    accounts: {
      type: Array,
      default: () => []
    },
    // eslint-disable-next-line vue/prop-name-casing
    start_date: {
      type: String,
      default: ''
    },
    // eslint-disable-next-line vue/prop-name-casing
    end_date: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    topPostsLimit: {
      type: [String, Number],
      default: 10
    },
    allAccountsSelected: {
      type: Boolean,
      default: false
    },
    labels:{
      type: Array,
      default: () => []
    },
    campaigns:{
      type: Array,
      default: () => []
    },

  },
  setup(){
    const {canAccess} = useFeatures()
    const exportReportAccess = canAccess('exports_schedule_reports')
    const {momentWrapper} = useDateFormat()
    return {
      exportReportAccess,
      momentWrapper
    }
  },
  computed: {
    ...mapGetters(['getReportsServiceCalls', 'getActiveWorkspace'])
  },
  methods: {
    ...mapActions(['renderReportsService','toastNotification']),
    async saveReport () {
      // open schedule modal if prop type is group
      if (!['campaign-and-label'].includes(this.type) ) {
        // eslint-disable-next-line no-undef
        $('#scheduleReport').modal('show')
        EventBus.$emit(
          'export-report-overview',
            {
              accounts: this.accounts,
              network: this.type,
              startDate: this.start_date,
              endDate: this.end_date,
              topPosts: this.topPostsLimit,
              allAccountsSelected: this.allAccountsSelected,
              labels: this.labels,
              campaigns: this.campaigns
            }
        )
         return;
      }

      const res = await this.renderReportsService({
        date: `${this.start_date} - ${this.end_date}`,
        accounts: this.accounts,
        type: this.type,
        action: 'render',
        topPosts: this.topPostsLimit,
        allAccountsSelected: this.allAccountsSelected,
        labels: this.labels,
        campaigns: this.campaigns
      })

      if (res?.status) {
        this.toastNotification({
          message: `Your report is being generated. You will receive a notification once the report is ready. You can track the progress in the <a onclick="redirectUsingVueRoute('download_reports_v3')" href="#">Download Reports</a> section.`,
          type: 'success',
        })
      }
    },
    schedulePDF () {
      EventBus.$emit('schedule-report', {
            accounts: this.accounts,
            network: this.type,
            topPosts: this.topPostsLimit,
            labels: this.labels,
            campaigns: this.campaigns
          })
      // eslint-disable-next-line no-undef
      $('#scheduleReport').modal('show')
    },
    sendReportByEmail () {
      if (!['campaign-and-label'].includes(this.type) ) {
        // eslint-disable-next-line no-undef
        $('#scheduleReport').modal('show')
      }
      else {
        // eslint-disable-next-line no-undef
        $('#sendReportByEmail').modal('show')
      }
      EventBus.$emit(
        'send-report-by-email',
          {
            accounts: this.accounts,
            network: this.type,
            startDate: this.start_date,
            endDate: this.end_date,
            topPosts: this.topPostsLimit,
            allAccountsSelected: this.allAccountsSelected,
            labels: this.labels,
            campaigns: this.campaigns
          }
      )
    },
    getFileName(){
      if(this.type === 'campaign-and-label'){
        return 'Campaign and Label'
      }
      else if( this.type === 'twitter'){
        return 'X (Twitter)'
      }
      return textHelper.ucFirst(this.type)
    }
  }
}
</script>
