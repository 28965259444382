<template>
  <div>
    <b-modal
      id="approval-modal"
      centered
      hide-footer
      header-class="cs-modal-header px-8"
      modal-class="custom-thumbnail-modal"
      title-class="cs-modal-title"
      no-close-on-backdrop
      size="xl"
      @hidden="
        () => {
          selectedCount = 0
          message = ''
          type = 'anyone'
          selectedApprover = []
          selectedData = []
          isDisabled = false
          memberSearch = ''
        }
      "
    >
      <template v-slot:modal-title>Send for Approval</template>
      <div class="px-2 2 h-auto">
        <!--        modal body -->
        <div
          id="modalBody"
          class="relative flex flex-col w-full lg:h-fit py-1 px-5 items-center overflow-auto grow"
        >
          <div
            class="flex flex-col md:flex-row justify-between items-center w-full"
          >
            <div class="grid grid-cols-1 place-items-center">
              <p class="text-gray-900 text-sm"
                >Selected approver(s):
                <span class="font-bold text-black">{{
                  selectedData.length
                }}</span>
              </p>
            </div>

            <div class="search-bar-input">
              <div class="search-bar-inner">
                <input
                  v-model="memberSearch"
                  type="text"
                  placeholder="Search by name..."
                />
                <button class="search_btn">
                  <i class="icon-Search"></i>
                </button>
              </div>
            </div>
          </div>

          <div
            class="w-full h-72 max-h-96 relative mt-5 overflow-auto py-1 px-2"
          >
            <!--            {{membersWorkspace}}-->
            <div
              v-if="filteredMembers.length >= 1"
              class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 w-full h-auto m-auto"
            >
              <template v-for="(card, key) in filteredMembers" :key="key">
                <CstCardCheckbox
                  v-model="selectedData"
                  class="w-full overflow-hidden relative"
                  :value="card.user_id"
                  :title="
                    card.user.firstname ? card.user.full_name : card.user.email
                  "
                >
                  <div
                    v-if="
                      ['collaborator', 'approver'].includes(card.role) &&
                      showErrorForMember(card)
                    "
                    class="absolute top-2 right-2"
                  >
                    <i
                      v-tooltip="
                        'This user has access to specific social accounts only. Ensure you send posts for approval that align with their permissions to prevent restricted visibility'
                      "
                      class="fas fa-warning text-red-500"
                    ></i>
                  </div>
                  <div class="flex items-center">
                    <img
                      :src="
                        card.user.image
                          ? card.user.image
                          : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                      "
                      alt=""
                      class="rounded-full w-10 h-10"
                      @error="
                        $event.target.src =
                          'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                      "
                    />
                    <div
                      class="flex flex-col justify-center items-start w-full ml-4 truncate"
                    >
                      <span
                        v-if="card.user.firstname"
                        class="font-medium text-sm"
                      >
                        {{ card?.user?.firstname }}
                        {{ card?.user?.lastname || '' }}
                      </span>
                      <span v-else class="font-medium text-sm">{{
                        card.user.email
                      }}</span>
                      <span class="text-xs" :class="getRoleClass(card.role)">
                        {{ getMutatedUserRole(card.role) }}
                      </span>
                    </div>
                  </div>
                </CstCardCheckbox>
              </template>
            </div>
            <div
              v-else-if="filteredMembers.length < 1"
              class="w-full h-auto text-lg p-4 flex justify-center items-center"
            >
              No member found.
            </div>
          </div>

          <div class="w-full h-1/5 sm:h-fit">
            <textarea
              id="message"
              v-model="message"
              rows="4"
              class="block p-2.5 h-16 w-full border-2 border-gray-400 rounded-lg focus:outline-none focus:border-blue-400 hover:border-blue-300 resize-none"
              placeholder="Add a note to the approver(s)"
            >
            </textarea>
          </div>
        </div>
        <!--        end of modal body -->

        <!--        footer-->
        <div
          id="modalFooter"
          class="flex flex-col md:flex-row w-full h-fit py-2 md:py-4 px-5 justify-between items-center"
        >
          <div
            class="grid lg:grid-cols-2 lg:gap-x-3 xl:gap-x-5 pb-4 md:pb-0 md:py-0"
          >
            <div class="flex justify-center items-center">
              <p class="text-sm">Post needs approval from: </p>
            </div>
            <div class="grid grid-cols-2 gap-x-3">
              <CstRadio
                v-model="type"
                v-b-tooltip.hover="
                  selectedData.length < 2
                    ? 'Please select at least two team members to set the approval rule.'
                    : 'Approval from any of the assigned team member will schedule the post.'
                "
                name="thumbnail-type"
                value="anyone"
                checked
                :disabled="selectedData.length < 2"
              >
                Anyone
              </CstRadio>

              <CstRadio
                v-model="type"
                v-b-tooltip.hover="
                  selectedData.length < 2
                    ? 'Please select at least two team members to set the approval rule.'
                    : 'All of the assigned team members would need to approve the post in order to be scheduled.'
                "
                name="thumbnail-type"
                value="everyone"
                :disabled="selectedData.length < 2"
              >
                Everyone
              </CstRadio>
            </div>
          </div>

          <div class="flex justify-end">
            <CstButton
              size="large"
              variant="secondary"
              class="mr-3 w-24"
              text="Cancel"
              @click="closeModal"
            />
            <div
              v-b-tooltip.hover="
                !filteredMembers || selectedData.length === 0
                  ? 'You must select an approver'
                  : 'Send for approval'
              "
            >
              <CstButton
                variant="primary"
                size="large"
                text="Send"
                class="w-20"
                :disabled="
                  !filteredMembers || selectedData.length === 0 || isDisabled
                "
                @click="sendForApproval"
              />
            </div>
          </div>
        </div>
        <!--    Footer    -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import CstRadio from '@ui/Radio/CstRadio'
import CstCardCheckbox from '@ui/CheckBox/CstCardCheckbox'
import {
  ref,
  reactive,
  computed,
  onMounted,
  onBeforeUnmount,
  inject,
} from 'vue'
import CstButton from '@ui/Button/CstButton'
import useFetchMembers from '@src/modules/composer_v2/composables/useFetchMembers.js'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'
import { swalAttributes } from '@common/constants/common-attributes'
import proxy from '@/src/modules/common/lib/http-common'
import { sendPlansForBulkApproversURL } from '@/src/modules/planner/config/api-utils'

export default {
  name: 'ApprovalModal',
  components: {
    CstRadio,
    CstButton,
    CstCardCheckbox,
  },
  props: {
    module: {
      type: String,
      default: 'social',
    },
    accountSelection: {
      type: Object,
      default() {
        return {}
      },
    },
    selectedPlanIds: {
      type: Array,
      default() {
        return []
      },
    },
  },
  emits: ['approvalData'],
  /**
   * @description - This component is used to send for approval. Created using vue (2) composition api.
   */
  setup: function (props, { emit }) {
    const root = inject('root')
    const message = ref('')
    const selectedApprover = ref([])
    const selectedData = ref([])
    const isDisabled = ref(false)
    const approvalData = reactive({
      data: {
        approvers: [
          {
            userId: '',
            status: '',
            last_action_time: '',
            last_action_note: '',
          },
        ],
        members: [],
        approve_option: '',
        notes: '',
        status: 'pending',
      },
    })

    const memberSearch = ref('')
    const selectedCount = ref(0)
    const type = ref('anyone')
    const store = useStore()

    const membersWorkspace = store.getters.getWorkspaces.activeWorkspace.members
    const profile = computed(() => store.getters.getProfile._id)

    const { fetchFilteredMembers } = useFetchMembers(
      store,
      computed(() => memberSearch.value)
    )
    const filteredMembers = computed(() => fetchFilteredMembers())

    /**
     * @description - This function is used to get the selected approvers in case of editing the post.
     */
    onMounted(() => {
      EventBus.$on('previous-approval-data', (data) => {
        if (!data) {
          console.log('no data')
        } else {
          selectedData.value = data.members
          approvalData.data = data
          message.value = data.notes
          type.value = data.approve_option
          console.log('approvalData now', approvalData.data)
        }
      })
    })

    onBeforeUnmount(() => {
      EventBus.$off('previous-approval-data')
    })

    /**
     * @description - This function is used reset the states and close the modal
     */
    const closeModal = () => {
      root.$bvModal.hide('approval-modal')
      selectedCount.value = 0
      message.value = ''
      type.value = 'anyone'
      selectedData.value = []
      isDisabled.value = false
      memberSearch.value = ''
    }

    /**
     * @description - This function is used to add the selected approvers to the list to send in approvalData.
     */
    const selectedCards = () => {
      selectedApprover.value = []
      selectedData.value.forEach((item) => {
        selectedApprover.value.push({
          id: item,
          status: 'pending',
        })
      })
      console.log(
        'selected approver are: ',
        selectedApprover.value,
        selectedData.value.length
      )
    }

    /**
     * @returns {void}
     * @description - This function is used to send for approval.
     */
    const sendForApproval = async () => {
      const selectedMembersWithErrors = selectedData.value.filter(
        (memberId) => {
          const member = filteredMembers.value.find(
            (m) => m.user_id === memberId
          )
          return (
            ['collaborator', 'approver'].includes(member.role) &&
            showErrorForMember(member)
          )
        }
      )

      if (selectedMembersWithErrors.length > 0) {
        let res = false
        const options = {
          ...swalAttributes(),
          modalClass:
            'sweet-alert-confirmation fade sweet-alert-minimize-composer',
          okVariant: 'danger',
          hideHeader: false,
        }
        res = await root.$bvModal.msgBoxConfirm(
          `In your selection, some users have access to specific social accounts only. Ensure you send posts for approval that align with their permissions to prevent restricted visibility.

            `,
          {
            title: '⚠️ Missing Permissions !',
            ...options,
            cancelTitle: 'Cancel',
            okTitle: 'Got It, Proceed',
          }
        )

        if (res === null) {
          return
        }
        if (!res) {
          return
        }
      }
      isDisabled.value = true
      selectedCards()
      approvalData.data.members = selectedApprover.value.map(
        (member) => member.id
      )
      approvalData.data.notes = message.value
      approvalData.data.approve_option = type.value
      approvalData.data.status = 'pending_approval'
      approvalData.data.approvers = selectedApprover.value.map((member) => {
        return {
          user_id: member.id,
          status: 'pending',
          last_action_time: '',
          last_action_note: '',
        }
      })
      console.log('approval data is', approvalData.data) // store.dispatch('addApproval', approvalData.value)

      // handle bulk approval
      if(props.module === 'planner-bulk-approval') {
        sendForApprovalBulk()

        return
      }
      emit('approvalData', approvalData.data)

      // TODO: later on, this might get useless so have to find a valid solution
      EventBus.$emit('approval-selected', approvalData.data)
    }

    const showErrorForMember = (card) => {
      for (const [platform, accountIds] of Object.entries(
        props.accountSelection
      )) {
        if (accountIds.length > 0) {
          const socialPlatform =
            platform === 'tumblr' ? 'tumblr_profiles' : platform
          let memberPlatformIds = card.permissions[socialPlatform] || []
          const getPinterestBoards = store.getters.getPinterestBoards ?? []
          const matchingBoards = getPinterestBoards
            .filter(
              (board) =>
                memberPlatformIds.includes(board.username) ||
                memberPlatformIds.includes(board.profile_id)
            )
            .map((board) => board.board_id)
          memberPlatformIds = memberPlatformIds.concat(matchingBoards)

          if (!accountIds.every((id) => memberPlatformIds.includes(id))) {
            return true
          }
        }
      }
      return false
    }

    // Api call for bulk approval
    const sendForApprovalBulk = async () => {

      try {
        const payload = {
         workspace_id: store.getters.getWorkspaces.activeWorkspace._id,
         plans: props.selectedPlanIds,
         approval: approvalData.data
        }

        const response  = await proxy.post(sendPlansForBulkApproversURL, payload)

        if(response?.data) {
           store.dispatch('toastNotification', {
            message: response.data?.message,
            type: response.data?.status ? 'success' : 'error',
          })
        }
        // Close the modal upon success
        if(response?.data?.status) {
          root.$bvModal.hide('approval-modal')
        }
      } catch (error) {
        console.error('sendForApprovalBulk', error)
      }

    }

    return {
      type,
      selectedCount,
      memberSearch,
      membersWorkspace,
      filteredMembers,
      selectedCards,
      closeModal,
      approvalData,
      sendForApproval,
      message,
      selectedApprover,
      profile,
      selectedData,
      isDisabled,
      showErrorForMember,
    }
  },
}
</script>
