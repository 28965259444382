<template>
  <div :class="['w-full', 'flex', flexClass, 'px-8', 'items-center', 'bg-white']">
    <img
      src="@src/assets/img/captionGenerationBot.gif"
      alt="ContentStudio logo"
      class="w-3/5 h-4/6 mt-16 mb-16 px-8"
    />
    <div class="w-2/5">
      <p class="mb-6 text-left text-base font-medium">
        {{ text }}
      </p>
      <div class="text-zinc-300 flex flex-col">
        <template v-for="(step, si) in steps" :key="si">
          <p class="mb-3">
            <span
              class="w-7 h-7 mr-2 rounded-full inline-flex justify-center items-center bg-cs-white drop-shadow-lg"
              :class="{
                'bg-green-400': step.status === true,
                'bg-cs-white': step.status === false,
              }"
            >
              <i v-if="step.status === true" class="fa fa-check text-white"></i>
              <i v-else class="fa fa-spinner fa-pulse fa-fw text-blue-400"></i>
            </span>
            <span :class="{ 'text-green-600': step.status }">
              {{ step.message }}
            </span>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// libraries
import { defineComponent, ref, watch, computed } from 'vue'
const clone = require('rfdc/default')

export default defineComponent({
  name: 'AiGenerationLoader',
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    loadingCompleted: {
      type: Function,
      default: () => {},
    },
    orientation: {
      type: String,
      default: 'vertical',
    },
    loadingSteps: {
      type: Array,
      default: () => [
        { message: 'Initializing', status: true },
        { message: 'Refining content', status: true },
        { message: `Generating Images`, status: false },
        { message: `Your AI Images are ready!`, status: false },
      ],
    },
  },
  setup(props) {
    const loader = ref(false)
    const steps = ref(props.loadingSteps)
    const flexClass = computed(() => {
      return props.orientation === 'horizontal' ? 'flex-row' : 'flex-col'
    })

    steps.value.slice(0, 3).forEach((step, index) => {
      const delay = (index + 1) * 2000
      setTimeout(() => {
        step.status = true
      }, delay)
    })

    watch(
      () => props.loading,
      (value, oldValue) => {
        steps.value = clone(props.loadingSteps)
        if (!value) {
          setTimeout(function () {
            steps.value[3].status = true
          }, 1000)
          setTimeout(function () {
            props.loadingCompleted()
            steps.value = clone(props.loadingSteps)
          }, 2000)
        }
      }
    )

    return { steps, loader, flexClass }
  },
})
</script>
