import { integrationsBaseURL } from '@src/config/api-utils'

export const blogIntegrationsNames = [
  'wordpress',
  'tumblr',
  'medium',
  'shopify',
  'webflow',
]
export const socialIntegrations = [
  'facebook',
  'threads',
  'twitter',
  'linkedin',
  'pinterest',
  'tumblr',
  'instagram',
  'gmb',
  'youtube',
  'tiktok',
  'bluesky'
]
export const blogIntegrationsConfigrations = {
  wordpress: {
    heading_classes: ['wordpress_social_icon wordpress_icon_color'],
    icon_classes: ['cs-wordpress wp'],
    background_classes: ['cs-wordpress wp_bg'],
    connection_text: 'Connect a new WordPress blog/website',
    authorization_type: 'basic',

    blog_details: {
      platform_identifier: '',
      url: '',
      username: '',
      password: '',
      connection_type: 'official_plugin',
    },
    posting_options: {
      post_tags: [],
      selection: false,
      authors: [],
      categories: [],
      site_status: false,
    },
    posting_selection: {
      website: '',
      author: '',
      categories: [],
      status: 'publish',
      post_tags: [],
    },
  },
  tumblr: {
    heading_classes: ['tumblr_social_icon tumblr_icon_color'],
    icon_classes: ['cs-tumblr tum'],
    background_classes: ['cs-tumblr tum_bg'],
    connection_text: 'Connect a new Tumblr blog',
    authorization_type: 'oauth2',
    posting_options: false,
    posting_selection: {
      website: '',
    },
  },
  medium: {
    heading_classes: ['medium_social_icon medium_icon_color'],
    icon_classes: ['cs-medium medium'],
    background_classes: ['cs-medium medium_bg'],
    connection_text: 'Connect a new Medium blog',
    authorization_type: 'oauth2',
    posting_options: false,
    posting_selection: {
      website: '',
      status: 'public',
      repurpose: true,
    },
  },
  shopify: {
    heading_classes: ['shopify_social_icon shopify_icon_color'],
    icon_classes: ['shopify_icon'],
    background_classes: ['shopify_icon'],
    connection_text: 'Connect a new Shopify store',
    authorization_type: 'oauth2',
    posting_options: {
      selection: false,
      authors: [],
      tags: [],
      site_status: false,
    },
    posting_selection: {
      website: '',
      author: '',
      tags: [],
      published: 'Visible',
    },
  },
  webflow: {
    // heading_classes: ['tumblr_social_icon tumblr_icon_color'],
    heading_classes: ['webflow_social_icon webflow_icon_color'],
    icon_classes: ['webflow_icon'],
    background_classes: ['webflow_icon'],
    connection_text: 'Connect a new Webflow blog',
    authorization_type: 'oauth2',
    posting_options: false,
    posting_selection: {
      collection: null,
      website: '',
      status: 'draft',
      domain: '',
    },
  },
}
export const socialIntegrationsConfigurations = {
  bluesky: {
    validations: {
      characters: 300,
      image: {
        max_size: 1000000, // 1MB (reduced from 1MB)
        max_size_single_image_error:
            'Max. file size for Bluesky is 1MB. Please try a smaller file.',
        max_size_multiple_images_error:
            'Max. file size for Bluesky is 1MB. One of your images has more than 1MB.',
        min_width: 200,  // Reduced minimum width for better mobile support
        max_width: 2048, // Increased max width for high-res displays
        min_dimension_single_image_error:
            'The Bluesky image width must be between 200 and 2048 pixels.',
        min_dimension_multiple_images_error:
            'One of your images has invalid dimensions.',
        image_dimension_error:
            'The Bluesky image width must be between 200 and 2048 pixels.',
        max_dimension_single_image_error:
            'Max. dimension for Bluesky is 2000*1000. Please try a different file.',
        max_dimension_multiple_images_error:
            'Max. dimension for Bluesky is 2048*1536. One of your images has invalid dimensions.',
      },
      gif: {
        size: 15728640, // 15MB (increased from 8MB for better GIF support)
        size_error:
            'Max. GIF file size for Bluesky is 15MB. Please try a smaller file.',
      },
      video: {
        size: 52428800, // 50MB
        size_error:
            'Max. file size for Bluesky video is 50MB. Please try a smaller file.',
        max_duration: 60, // 60 seconds
        max_duration_error:
            'Max. file duration for Bluesky video is 60 seconds.',
        video_codec_h264: 'h264',
        video_codec_hevc: 'hevc', // corrected spelling from 'hecv'
        video_codec_av1: 'av1',   // added modern codec support
        audio_codec: ['aac', 'mp3'], // expanded audio codec support
        frame_rate_min: 24,  // slightly increased for better quality
        frame_rate_max: 120, // increased for high refresh rate content
        audio_hertz_max: 48000, // specified in Hz for clarity
        max_aspect_ratio: 2.39,  // increased to support cinematic ratio
        min_aspect_ratio: 0.5,   // adjusted for portrait videos
        aspect_ratio_error:
            'Video has unsupported aspect ratio for Bluesky post.',
      },
    },
    warnings: {
      image: {
        max_images: 4, // reduced from 10 to encourage focused content
        max_images_warning:
            'Bluesky only allows up to 4 media items (images or videos) per post, so only the first 4 will be selected automatically.',
      },
    },
  },
  threads: {
    validations: {
      characters: 500,
      image: {
        max_size: 8388608, // 8MB
        max_size_single_image_error:
          'Max. file size for Threads is 8MB. Please try a smaller file.',
        max_size_multiple_images_error:
          'Max. file size for Threads is 8MB. One of your images has more than 8MB.',
        min_width: 320,
        max_width: 1440,
        min_dimension_single_image_error:
          'The Threads Image width must be between 320 and 1440 pixels.',
        min_dimension_multiple_images_error:
          'One of your images has invalid dimension.',
        image_dimension_error:
          'The Threads Image width must be between 320 and 1440 pixels.',
        max_dimension_single_image_error:
          'Max. dimension for Threads is 1440*1080. Please try a different file.',
        max_dimension_multiple_images_error:
          'Max. dimension for Threads is 1440*1080. One of your images has invalid dimension.',
      },
      gif: {
        size: 8388608, // 8MB
        size_error:
          'Max. GIF file size for Threads is 8MB. Please try a smaller file.',
      },
      video: {
        size: 1073741824, // 1GB
        size_error:
          'Max. file size for Threads video is 1GB. Please try a smaller file.',
        max_duration: 300, // seconds
        max_duration_error:
          'Max. file duration for Threads video is 300 seconds.',
        video_codec_h264: 'h264',
        video_codec_hecv: 'hecv',
        audio_codec: 'aac',
        frame_rate_min: 23,
        frame_rate_max: 60,
        audio_hertz_max: 48,
        max_aspect_ratio: 1.78,
        min_aspect_ratio: 0.8,
        aspect_ratio_error:
          'Video has unsupported aspect ratio for Threads post.',
      },
    },
    warnings: {
      image: {
        max_images: 10,
        max_images_warning:
          'Threads only allow up to 10 media items (images or videos) per post, so only the first 10 will be selected automatically.',
      },
    },
  },
  facebook: {
    validations: {
      characters: 5000,
      image: {
        max_size: 4194304, // 4MB
        max_size_single_image_error:
          'Max. file size for Facebook is 4MB. Please try a smaller file.',
        max_size_multiple_images_error:
          'Max. file size for Facebook is 4MB. One of your images has more than 4MB.',
        story_max_images_allowed: 1,
        story_max_images_error:
          'More than one image cannot be scheduled as a Facebook story. Please reduce it to one.',
      },
      gif: {
        size: 10485760, // 10MB
        size_error:
          'Max. GIF file size for Facebook is 10MB. Please try a smaller file.',
      },
      video: {
        size: 1073741824, // 1GB
        size_error:
          'Max. file size for Facebook video is 1GB. Please try a smaller file.',
        max_duration: 1200, // seconds
        max_duration_error:
          'Max. file duration for Facebook video is 1200 seconds.',

        // reel error cases
        reel_aspect_ratio: 0.56,
        reel_aspect_ratio_error:
          'Video has unsupported aspect ratio for Facebook reel.',
        reel_min_height: 960,
        reel_min_height_error:
          'Video has unsupported height for Facebook reel.',
        reel_min_width: 540,
        reel_min_width_error: 'Video has unsupported width for Facebook reel.',
        reel_max_duration: 90, // seconds
        reel_max_duration_error:
          'Max. file duration for Facebook reel is 90 seconds.',
        reel_min_duration: 4, // seconds
        reel_min_duration_error:
          'Min. file duration for Facebook reel is 4 seconds.',

        // story error cases
        story_max_duration: 90,
        story_max_duration_error:
          'Max. file duration for Facebook story is 90 seconds.',
        story_min_duration: 4,
        story_min_duration_error:
          'Min. file duration for Facebook story is 4 seconds.',
        story_aspect_ratio: 0.56,
        story_aspect_ratio_error:
          'Video has unsupported aspect ratio for Facebook story.',
        story_min_height: 960,
        story_min_height_error:
          'Video has unsupported height for Facebook story.',
        story_min_width: 540,
        story_min_width_error:
          'Video has unsupported width for Facebook story.',
      },
      story_posting_as_group:
        'Stories can only be published on Facebook Pages.',
      reel_posting_as_group: 'Reels can only be published on Facebook Pages.',
    },
    warnings: {
      posting_as_feed:
        'For selected Facebook Group(s), the system will publish the post as a feed post.',
      template_limit: 130,
      template_limit_message:
        'Facebook allows only 130 characters in a template. If exceeds it will be publish as simple text post.',
      facebook_page_template_message:
        'In case of a Facebook group selection, only the text content will be published. Facebook template posts are permitted.',
      image: {
        max_images: 10,
        max_images_warning: `Facebook allows 10 images in a carousel. So, we'll use the first 10 images; if a link is added, 1 image will be from the link and 9 from your upload.`,
      },
    },
  },
  twitter: {
    validations: {
      characters: 280,
      image: {
        max_size: 5242880, // 5MB
        max_size_single_image_error:
          'Max. file size for X (Twitter) is 5MB. Please try a smaller file.',
        max_size_multiple_images_error:
          'Max. file size for X (Twitter) is 5MB. One of your images has more than 5MB.',
        // max_height: 1080,
        // max_width: 1280,
        // max_dimension_single_image_error: 'Max. dimension for Twitter is 1280*1080. Please try a different file.',
        // max_dimension_multiple_images_error: 'Max. dimension for Twitter is 1280*1080. One of your images has invalid dimension.'
      },
      gif: {
        size: 15728640, // 15MB
        size_error:
          'Max. GIF file size for X (Twitter) is 15MB. Please try a smaller file.',
        max_height: 2048,
        max_width: 2048,
        max_dimension_error:
          'Max. dimension for X (Twitter) is 2048*2048. Please try a different file.',
      },
      video: {
        size: 536870912, // 512MB
        size_error:
          'Max. file size for X (Twitter) video is 512MB. Please try a smaller file.',
        min_duration: 0.5, // seconds
        min_duration_error:
          'Min. file duration for X (Twitter) video is 0.5 seconds. Please try a different file.',
        max_duration: 140, // seconds
        max_duration_error:
          'Max. file duration for X (Twitter) video is 140 seconds.',
      },
    },
    warnings: {
      repeat_warning: true,
      image: {
        max_images: 4,
        max_images_warning:
          'X (Twitter) doesn’t allow publishing more than 4 images. So, we’ll use the first 4 images for the X (Twitter) post.',
        max_height: 1080,
        max_width: 1280,
        max_dimension_single_image_warning:
          'Dimension for X (Twitter) should be less than 1280*1080. Please try a different file.',
        max_dimension_multiple_images_warning:
          'Dimension for X (Twitter) should be less than 1280*1080. One of your images has invalid dimension.',
      },
    },
  },
  tiktok: {
    validations: {
      characters: 150,
      image: {
        max_size: 20971520, // 20MB
        min_size: 1024, // 1KB
        max_images: 35,
        min_images: 2,
        allowed_formats: ['image/jpg', 'image/jpeg', 'image/webp'],
        format_error: 'Only JPG format is allowed for TikTok.',
        max_height: 1920,
        max_width: 1080,
        min_height: 0,
        min_width: 0,
        max_images_warning: 'Max 30 images are allowed for TikTok.',
        max_size_single_image_error:
          'Max. file size for TikTok is 20MB. Please try a smaller file.',
        max_size_multiple_images_error:
          'Max. file size for TikTok is 20MB. Please try a smaller file.',
        min_size_single_image_error:
          'Min. file size for TikTok is 1KB. Please try a greater file.',
        min_size_multiple_images_error:
          'Min. file size for TikTok is 1KB. Please try a greater file.',
        max_dimension_single_image_error:
          'Max dimension for TikTok is 1080 x 1920. Please try a different file.',
        max_dimension_multiple_images_error:
          'Max dimension for TikTok is 1080 x 1920. One of your images has invalid dimension.',
      },
      gif: {
        max_size: 0, // not allowed
        size: 0, // not allowed
        size_error: 'GIF file is not allowed for TikTok.',
        error: 'GIF file is not allowed for TikTok.',
      },
      video: {
        size: 4294967296, // 4Gb in bytes
        size_error:
          'Max. file size for TikTok video is 4GB. Please try a smaller file.',
        min_duration: 3, // seconds
        min_duration_error:
          'Min. file duration for TikTok video is 3 seconds. Please try a different file.',
        max_duration: 600, // seconds
        max_duration_error:
          'Max. file duration for TikTok video is 10 minutes.',
        frame_rate_min: 23,
        frame_rate_max: 60,
      },
    },
    /* warnings: {
      repeat_warning: true,
      image: {
        max_images: 4,
        max_images_warning:
            'Twitter doesn’t allow publishing more than 4 images. So, we’ll use the first 4 images for the Twitter post.',
        max_height: 1080,
        max_width: 1280,
        max_dimension_single_image_warning:
            'Dimension for Twitter should be less than 1280*1080. Please try a different file.',
        max_dimension_multiple_images_warning:
            'Dimension for Twitter should be less than 1280*1080. One of your images has invalid dimension.'
      }
    } */
  },
  linkedin: {
    validations: {
      characters: 1300,
      image: {
        max_size: 10485760, // 10MB
        max_size_single_image_error:
          'Max. file size for LinkedIn is 10MB. Please try a smaller file.',
        max_size_multiple_images_error:
          'Max. file size for LinkedIn is 10MB. One of your images has more than 10MB.',
        max_height: 6012,
        max_width: 6012,
        max_dimension_single_image_error:
          'Max. dimension for LinkedIn is 6012*6012. Please try a different file.',
        max_dimension_multiple_images_error:
          'Max. dimension for LinkedIn is 6012*6012. One of your images has invalid dimension.',
      },
      gif: {
        size: 10485760, // 10MB
        size_error:
          'Max. GIF file size for LinkedIn is 10MB. Please try a smaller file.',
      },
      video: {
        size: 209715200, // 200MB
        size_error:
          'Max. file size for LinkedIn video is 200MB. Please try a smaller file.',
        max_duration: 1800, // seconds
        max_duration_error:
          'Max. file duration for LinkedIn video is 1800 seconds.',
      },
    },
    warnings: {
      image: {
        max_images: 20,
        max_images_warning:
          'LinkedIn does’t allow publishing more than 20 images. So, we’ll use the first 20 images for the LinkedIn post.',
      },
      gif: {
        max_height: 470,
        max_width: 900,
        max_dimension_warning:
          'Dimension for LinkedIn should be less than 900*470. Please try a different file.',
      },
    },
  },
  pinterest: {
    validations: {
      characters: 500,
      image: {
        max_size: 10485760, // 10MB
        max_size_single_image_error:
          'Max. file size for Pinterest is 10MB. Please try a smaller file.',
        max_size_multiple_images_error:
          'Max. file size for Pinterest is 10MB. One of your images has more than 10MB.',
      },
      gif: {
        size: 10485760, // 10MB
        size_error:
          'Max. GIF file size for Pinterest is 10MB. Please try a smaller file.',
      },
      video: {
        size: 2147483648,
        size_error:
          'Max. file size for Pinterest video is 2GB. Please try a smaller file.',
        min_duration: 4, // seconds
        min_duration_error:
          'Min. file duration for Pinterest video is 4 seconds. Please try a different file.',
        max_duration: 900, // seconds ~ 900secs = 15Mins
        max_duration_error:
          'Max. file duration for Pinterest video is less than 15 mins.',
      },
    },
    warnings: {
      image: {
        max_images: 1,
        max_images_warning:
          'Pinterest doesn’t allow publishing more than 1 image. So, we’ll use the first image for the Pinterest post.',
      },
      gif: {
        max_height: 1440,
        max_width: 2560,
        max_dimension_warning:
          'Dimension for Pinterest should be less than 1200*628. Please try a different file.',
      },
    },
  },
  tumblr: {
    validations: {
      image: {
        max_size: 10485760, // 10MB
        max_size_single_image_error:
          'Max. file size for Tumblr is 10MB. Please try a smaller file.',
        max_size_multiple_images_error:
          'Max. file size for Tumblr is 10MB. One of your images has more than 10MB.',
      },
      gif: {
        size: 10485760, // 10MB
        size_error:
          'Max. GIF file size for Tumblr is 10MB. Please try a smaller file.',
        max_height: 3072,
        max_width: 2048,
        max_dimension_error:
          'Max. dimension for Tumblr is 2048*3072. Please try a different file.',
      },
      video: {
        size: 104857600, // 100MB
        size_error:
          'Max. file size for Tumblr video is 100MB. Please try a smaller file.',
        max_duration: 300, // seconds
        max_duration_error:
          'Max. file duration for Tumblr video is 300 seconds.',
      },
    },
    warnings: {
      image: {
        max_images: 1,
        max_images_warning:
          'Tumblr does not allow publishing more than 1 image. So, we’ll use the first image for the Tumblr post.',
      },
    },
  },
  instagram: {
    validations: {
      characters: 2200,
      image: {
        max_size: 8388608, // 8MB
        max_size_single_image_error:
          'Max. file size for Instagram is 8MB. Please try a smaller file.',
        max_size_multiple_images_error:
          'Max. file size for Instagram is 8MB. One of your images has more than 8MB.',
        min_dimension_single_image_error:
          'Image has unsupported aspect ratio for Instagram. Please edit the image by hovering over the image thumbnail and clicking edit or try a different image. <a href="https://docs.contentstudio.io/article/831-changing-image-aspect-ratio-in-contentstudio" target="_blank" rel="noopener" >Learn More.</a>',
        min_dimension_single_image_error_auto_scale:
          "You can auto-scale the image by clicking: <a href='#'>Auto Scale Image</a>",
        min_dimension_multiple_images_error:
          'One of your images has unsupported aspect ratio for Instagram.',
        story_max_images_allowed: 1,
        story_max_images_error:
          'More than one image cannot be scheduled as an Instagram story. Please reduce it to one.',
      },
      gif: {
        size: 0, // not allowed
        size_error:
          'GIFs are not supported for Instagram. Please try a different file.',
        error:
          'GIFs are not supported for Instagram. Please try a different file.',
      },
      video: {
        size: 104857600, // 100MB
        size_error:
          'Max. file size for Instagram video is 100MB. Please try a smaller file.',
        reel_max_size_error: 'Max. file size for Instagram reel is 1GB.',
        max_duration: 60, // seconds
        min_duration: 3, // seconds
        reel_max_duration: 900, // seconds to 15 minutes
        reel_max_size: 1073741824, // 1GB
        max_duration_error:
          'Max. video file duration for Instagram carousel and story video is 60 seconds.',
        min_duration_error:
          'Min. video file duration for Instagram video is 3 seconds.',
        reel_max_duration_error:
          'Max. video file duration for Instagram reel is 15 minutes.',
        reel_max_width: 1920,
        reel_max_width_error: 'Max. width for Instagram video post is 1920.',
        reel_max_height: 3413, // https://app.shortcut.com/contentstudio-team/story/66304/reels
        reel_max_height_error: 'Max. height for Instagram reel is 3413.',
        video_codec_h264: 'h264',
        video_codec_hecv: 'hecv',
        audio_codec: 'aac',
        frame_rate_min: 23,
        frame_rate_max: 60,
        audio_hertz_max: 48,
        max_aspect_ratio: 1.78,
        min_aspect_ratio: 0.8,
        aspect_ratio_error:
          'Video has unsupported aspect ratio for Instagram carousel post.',
      },
      indirect_publishing: {
        status: true,
        integration_single_account_error:
          'Mobile Device needs to be connected in order to post on Instagram.',
        integration_multiple_accounts_error:
          'One of your accounts has not been connected to a Mobile device.',
      },
    },
    warnings: {
      image: {
        max_images: 10,
        max_images_warning:
          'Instagram doesn’t allow publishing more than 10 images. So, we’ll use the first 10 images for the Instagram post.',
      },
    },
  },
  youtube: {
    validations: {
      characters: 500,
      image: {
        max_size: 0, // no allowed
        max_size_single_image_error: 'Image file is not allowed for Youtube.',
        max_size_multiple_images_error:
          'Image file is not allowed for Youtube.',
      },
      gif: {
        size: 0, // not allowed
        size_error: 'GIF file is not allowed for Youtube.',
      },
      video: {
        size: 2147483648, // 2GB
        max_duration: 43200, // seconds to 12 hours
        size_error:
          'Max. file size for Youtube video is 2GB. Please try a smaller file.',
        shorts_max_duration: 180, // seconds
      },
    },
  },
  gmb: {
    validations: {
      characters: 1500,
      image: {
        min_size: 10240, // 10KB
        min_size_single_image_error:
          'Min. file size for Google My Business is 10KB. Please try a greater file.',
        min_size_multiple_images_error:
          'Min. file size for Google My Business is 10KB. One of your images has less than 10KB.',
        max_size: 5242880, // 5MB
        max_size_single_image_error:
          'Max. file size for Google My Business is 5MB. Please try a smaller file.',
        max_size_multiple_images_error:
          'Max. file size for Google My Business is 5MB. One of your imagse has more than 5MB.',
        min_height: 250,
        min_width: 250,
        min_dimension_single_image_error:
          'Min. dimension for Google My Business is 250*250. Please try a different file.',
        min_dimension_multiple_images_error:
          'Min. dimension for Google My Business is 250*250. One of your images has invalid dimension.',
      },
      gif: {
        size: 0, // Not Allowed
        size_error: 'GIF file is not allowed for Google My Business.',
      },
      video: {
        size: 26214400, // 25MB
        size_error: 'Max. file size for GMB video is 25MB. Please try a smaller file.',
      },
    },
    warnings: {
      image: {
        max_images: 1,
        max_images_warning:
          'Google My Business doesn’t allow publishing more than 1 image. So, we’ll use the first image for the Google My Business post.',
        max_height: 720,
        max_width: 720,
        max_dimension_single_image_warning:
          'Dimension for Google My Business should be less than 720*720. Please try a different file.',
        max_dimension_multiple_images_warning:
          'Dimension for Google My Business should be less than 720*720. One of your images has invalid dimension.',
      },
    },
  },
}

export const checkConnectorStateURL =
  integrationsBaseURL + 'checkConnectorState'
export const fetchIntegrationsAccountsURL =
  integrationsBaseURL + 'fetchIntegrationsAccounts'
export const removeIntegrationURL = integrationsBaseURL + 'removeIntegration'
export const bulkRemoveIntegrationURL =
  integrationsBaseURL + 'bulkRemoveIntegration'

// platforms endpoints
export const savePlatformsURL = integrationsBaseURL + 'savePlatforms'
export const saveIGBusinessPlatformURL =
  integrationsBaseURL + 'instagram/business/connect'
export const verifyGroupAppURL = integrationsBaseURL + 'facebook/verifyGroupApp'

// blogs endpoints
export const fetchBlogsURL = integrationsBaseURL + 'fetchBlogs'
export const saveBlogURL = integrationsBaseURL + 'saveBlog'
export const fetchBlogURL = integrationsBaseURL + 'fetchBlog'

// social endpoints
export const fetchAuthorizationURL = integrationsBaseURL + 'getAuthorizationUrl'
export const fetchSocialAccountsURL =
  integrationsBaseURL + 'fetchSocialAccounts'

export const searchSocialAccountsURL =
  integrationsBaseURL + 'searchSocialAccounts'

export const fetchPlatformsListURL = integrationsBaseURL + 'fetchPlatformsList'
export const changeGroupPostedAsURL =
  integrationsBaseURL + 'changeGroupPostedAs'
export const changeInstagramNotificationDevicesURL =
  integrationsBaseURL + 'changeInstagramNotificationDevices'
export const removeInstagramExternalIntegrationURL =
  integrationsBaseURL + 'removeInstagramExternalIntegration'
export const createBoardURL = integrationsBaseURL + 'createBoard'
export const createProfileAndBoardManuallyURL =
  integrationsBaseURL + 'pinterest/connect/manually'
export const fetchAccountQueueSlotsURL =
  integrationsBaseURL + 'fetchAccountQueueSlots'
export const updateAccountQueueSlotsURL =
  integrationsBaseURL + 'updateAccountQueueSlots'

// rewriter endpoints
export const saveRewriterAccountURL =
  integrationsBaseURL + 'saveRewriterAccount'

// shorteners
export const changeDefaultShortenerURL =
  integrationsBaseURL + 'changeDefaultShortener'
export const changeBitlyBaseURL = integrationsBaseURL + 'changeBitlyBase'

// Replug
export const saveReplugShortenerSettingsURL =
  integrationsBaseURL + 'saveReplugShortenerSettings'

// onboarding social
export const fetchOnboardingFacebookAccountsUrl =
  integrationsBaseURL + 'fetchOnboardingFacebookAccounts'
export const fetchOnboardingTwitterAccountsUrl =
  integrationsBaseURL + 'fetchOnboardingTwitterAccounts'
export const fetchOnboardingPinterestAccountsUrl =
  integrationsBaseURL + 'fetchOnboardingPinterestAccounts'
export const fetchOnboardingLinkedinAccountsUrl =
  integrationsBaseURL + 'fetchOnboardingLinkedinAccounts'
export const fetchOnboardingTumblrAccountsUrl =
  integrationsBaseURL + 'fetchOnboardingTumblrAccounts'
export const fetchOnboardingMediumAccountsUrl =
  integrationsBaseURL + 'fetchOnboardingMediumAccounts'
export const fetchOnboardingWordpressAccountsUrl =
  integrationsBaseURL + 'fetchOnboardingWordpressAccounts'
export const onboardingProcessUrl = integrationsBaseURL + 'onboardingProcess'
