<template>
  <div class="relative">
    <div
      v-if="showBackdrop"
      id="Backdrop"
      class="bg-black-900 bg-opacity-10 absolute w-full h-full z-[998] backdrop-blur-[2px] shadow-sm rounded-md"
    >
      <slot name="backdrop-content"></slot>
    </div>
    <b-tabs
      v-model="tabIndex"
      nav-class="analytics-tabs analytics-main-tabs"
      content-class="analytics-tabs-content"
    >
      <b-tab
        v-for="tab in tabs"
        :key="tab"
        class="p-5"
        :title="getTitle(tab)"
        :href="tab"
        lazy
      >
        <slot name="alert"></slot>
        <slot :name="getSlotName(tab)"></slot>
      </b-tab>
    </b-tabs>
    <div class="absolute right-4 top-0 gap-4 h-16 flex items-center">
      <div
        v-if="type === 'twitter'"
        class="flex items-center px-1 gap-2 mr-2 border border-gray-300 rounded-lg"
        @click="openJobSettingsModal"
      >
        <PlatformTooltip :type="type" :enable-peek="true" />
        <v-menu
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
          :delay="300"
        >
          <div
            class="text-xl cursor-pointer font-normal text-gray-900 bg-cs-light-gray hover:text-primary-variant-1 transition-colors"
          >
            <i class="fas fa-gear h-10 w-10 text-xl text-center pt-1.5"></i>
          </div>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
              Configure X (Twitter) auto sync schedule.
            </p>
          </template>
        </v-menu>
      </div>
      <!-- Manual Trigger -->
      <v-menu
        v-if="type === 'twitter'"
        :popper-triggers="['hover']"
        placement="top"
        popper-class="first-comment__info-popover"
        :delay="300"
      >
        <CstButton
          class="font-normal text-nowrap !rounded-lg !py-2.5"
          :text="syncButtonText"
          variant="secondary"
          :loading="isTwitterJobRunning"
          :disabled="isTwitterJobRunning"
          @click="handleManualJobTrigger"
        />
        <template v-slot:popper>
          <p class="text-sm text-gray-900">
            It will immediately sync data, using additional credits.
          </p>
        </template>
      </v-menu>

      <PlatformTooltip v-else :type="type" :enable-peek="true" />
    </div>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from '@state/base'
import { useRoute } from 'vue-router'
import { EventBus } from '@common/lib/event-bus'
import PlatformTooltip from '@src/modules/analytics/components/common/PlatformTooltip.vue'
import CstButton from '@ui/Button/CstButton.vue'
import useTwitterAnalytics from '@/src/modules/analytics/views/twitter/composables/useTwitterAnalytics'
import { capitalizeFirstLetter } from '@/src/modules/common/lib/helper'

// Declarations
const { platformSelected, triggerTwitterJob } = useTwitterAnalytics()
const root = inject('root')
const { $bvModal } = root
const { getters, dispatch } = useStore()

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  type: {
    type: String,
    default: 'default',
  },
  showBackdrop: {
    type: Boolean,
    default: false,
  },
})

const route = useRoute()
const tabIndex = ref(0)

// Computed
const isTwitterJobRunning = computed(() => {
  return getters?.getTwitterJobsTracker?.some(
    (job) => job === platformSelected.value.twitter_id
  )
})

const syncButtonText = computed(() => {
  return isTwitterJobRunning.value ? 'Syncing now...' : 'Sync Data Now'
})

const getSlotName = (str) => str.replace('#', '')
const getTitle = (str) => capitalizeFirstLetter(getSlotName(str))?.split('-')?.join(' ')

onMounted(
  () => (tabIndex.value = props.tabs.findIndex((tab) => tab === route.hash))
)

onUnmounted(() => EventBus.$off('tab-change'))

EventBus.$on(
  'tab-change',
  (tab) => (tabIndex.value = props.tabs.findIndex((t) => t === tab))
)

// Methods
const openJobSettingsModal = () => {
  $bvModal.show('twitter_job_settings')
}
const handleManualJobTrigger = async () => {
  await dispatch('addToTwitterJobsTracker', platformSelected.value?.twitter_id)
  await triggerTwitterJob()
}
</script>

<style scoped></style>
