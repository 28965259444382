<script setup>
import { watch, computed } from 'vue'

import debounce from 'lodash.debounce'
import CardsComponent from '@src/modules/analytics/views/performance-report/label-and-campaign/components/CardsComponent.vue'
import ImpressionsGraph from '@src/modules/analytics/views/performance-report/label-and-campaign/components/graphs/ImpressionsGraph.vue'
import EngagementsGraph from '@src/modules/analytics/views/performance-report/label-and-campaign/components/graphs/EngagementsGraph.vue'
import LableAndCampaignTable from '@src/modules/analytics/views/performance-report/label-and-campaign/components/LableAndCampaignTable.vue'
import TopPostsTable from '@src/modules/analytics/views/performance-report/label-and-campaign/components/TopPosts.vue'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'

// CONSTATS
import { PLATFORMS } from '@src/modules/analytics_v3/constants/constants'

const {
  routes,
  dateRange,
  selectedCampaignsAndLabels,
  selectedAccount,
  breakdownDataByLabel,
  breakdownDataByImpression,
  breakdownDataByEngagement,
  insightsGraphBreakDown,
  isNoLabelOrCampaignSelected,
  SAMPLE_BREAKDOWN_DATA,
  SAMPLE_BREAKDOWN_DATA_BY_ENGAGEMENT,
  SAMPLE_BREAKDOWN_DATA_BY_IMPRESSION,
  SAMPLE_CHART_DATA,
  fetchMedia,
  isLoadingStates,
  topAndLeastEngagingPosts,
} = useLabelAndCampaign()

watch(
  () => [dateRange, selectedCampaignsAndLabels, selectedAccount],
  debounce(async () => {
    if(isNoLabelOrCampaignSelected.value) return

    fetchMedia(routes.SUMMARY_ANALYTICS)
    fetchMedia(routes.BREAKDOWN_DATA)
    fetchMedia(routes.INSIGHTS_GRAPH_BREAKDOWN)
    fetchMedia(routes.TOP_POSTS)
  }, 500),
  { deep: true, immediate: true }
)

const getSampleOrChartData = computed(() => {
  return isNoLabelOrCampaignSelected.value
    ? SAMPLE_CHART_DATA
    : insightsGraphBreakDown.value
})

const getSampleOrBreakdownData = computed(() => {
  return isNoLabelOrCampaignSelected.value
    ? SAMPLE_BREAKDOWN_DATA
    : breakdownDataByLabel.value
})

const getSampleOrBreakdownDataByEngagement = computed(() => {
  return isNoLabelOrCampaignSelected.value
    ? SAMPLE_BREAKDOWN_DATA_BY_ENGAGEMENT
    : breakdownDataByEngagement.value
})

const getSampleOrBreakdownDataByImpression = computed(() => {
  return isNoLabelOrCampaignSelected.value
    ? SAMPLE_BREAKDOWN_DATA_BY_IMPRESSION
    : breakdownDataByImpression.value
})
</script>

<template>
  <div class="flex flex-col">
    <!-- Cards -->
    <div class="grid grid-cols-4 gap-4 mb-7">
      <CardsComponent />
    </div>
    <div class="flex flex-col gap-y-[1.75rem]">
      <!-- Graphs Here -->

      <LableAndCampaignTable
        :title="'Post Breakdown by Campaign & Label'"
        :data="getSampleOrBreakdownData"
        :is-all-data-fetched="false"
        :headers="['totalPost', 'rateOfChange']"
        :is-loading="isLoadingStates[routes?.BREAKDOWN_DATA]"
        :chart-data="getSampleOrChartData"
        :platform-name="PLATFORMS.LABELSANDCAMPAIGNS"
        :type="'breakdown'"
        :modal-key="'posts'"
      />

      <ImpressionsGraph
        :is-loading="isLoadingStates[routes?.INSIGHTS_GRAPH_BREAKDOWN]"
      />
      <LableAndCampaignTable
        :title="'Post Impressions by Campaign & Label'"
        :data="getSampleOrBreakdownDataByImpression"
        :is-all-data-fetched="false"
        :headers="['totalImpressions', 'rateOfChange']"
        :is-loading="isLoadingStates[routes?.BREAKDOWN_DATA]"
        :chart-data="getSampleOrChartData"
        :platform-name="PLATFORMS.LABELSANDCAMPAIGNS"
        :type="'impressions'"
        :is-small-card="false"
        :modal-key="'impressions'"
      />

      <EngagementsGraph
        :is-loading="isLoadingStates[routes?.INSIGHTS_GRAPH_BREAKDOWN]"
      />
      <LableAndCampaignTable
        :title="'Post Engagements by Campaign & Label'"
        :data="getSampleOrBreakdownDataByEngagement"
        :is-all-data-fetched="false"
        :headers="['totalEngagements', 'rateOfChange']"
        :is-loading="isLoadingStates[routes?.BREAKDOWN_DATA]"
        :chart-data="getSampleOrChartData"
        :platform-name="PLATFORMS.LABELSANDCAMPAIGNS"
        :type="'engagements'"
        :is-small-card="false"
        :modal-key="'engagements'"
      />

      <!-- Top Posts Table -->
      <TopPostsTable
        :title="'Top Posts'"
        :data="topAndLeastEngagingPosts"
        :is-loading="isLoadingStates[routes?.TOP_POSTS]"
        :platform-name="PLATFORMS.LABELSANDCAMPAIGNS"
      />
    </div>
  </div>
</template>
