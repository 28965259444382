<script setup>
import { watch } from 'vue'

import useAnalyticsUtils from '@src/modules/analytics/components/common/composables/useAnalyticsUtils.js'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox.vue'
import TopPostCard from '@/src/modules/analytics/views/performance-report/label-and-campaign/components/TopPostCard.vue'
import EngagementIcon from '@/src/assets/img/icons/analytic/plain/total-engagement.svg'
import ImpressionsIcon from '@/src/assets/img/icons/analytic/plain/post-impressions.svg'
import ReachIcon from '@/src/assets/img/icons/analytic/plain/reach.svg'
import PostClickIcon from '@/src/assets/img/icons/analytic/plain/post-clicks.svg'
import PostTypeIcon from '@/src/assets/img/icons/analytic/plain/post-type.svg'

const {
  routes,
  selectedAccount,
  topAndLeastEngagingPosts,
  isReportView,
  isLoadingStates,
  labelCampaignByKeys
} = useLabelAndCampaign();

const { getAccountId } = useAnalyticsUtils()

const rows = [
  {
    icon: ReachIcon,
    title: 'Platform',
    api_key: 'platform',
    iconClass: '',
  },
  {
    icon: EngagementIcon,
    title: 'Engagement',
    api_key: 'total_engagement',
    iconClass: 'scale-[1.2]',
  },
  {
    icon: ImpressionsIcon,
    title: 'Impressions',
    api_key: 'total_impressions',
    iconClass: '',
  },
  {
    icon: PostTypeIcon,
    title: 'Post Type',
    api_key: 'media_type',
    iconClass: '',
  },
  {
    icon: PostClickIcon,
    title: 'Campaign/Label',
    api_key: 'campaignLabelName',
    iconClass: '',
  }
]

const mediaTypeMapper = (mediaType) => {
  switch (mediaType) {
    case 'image':
      return 'Image'
    case 'videos':
      return 'Video'
    case 'text':
      return 'Text'
    case 'link':
      return 'Link'
    case 'carousel_album':
      return 'Carousel'
    default:
      return mediaType
  }
}

const getMediaType = (mediaType) => mediaTypeMapper(mediaType)

watch(
  [() => topAndLeastEngagingPosts.value, () => labelCampaignByKeys.value],
  ([posts, campaignLabels]) => {
    if (posts && posts.length) {
      posts.forEach((post) => {
        post.media_type = getMediaType(post?.media_type?.toLowerCase())
        const campaignLabel = campaignLabels[post.campaign_label_id]
        post.campaignLabelName = campaignLabel?.name
        post.campaignLabelColor = campaignLabel?.color
      })
    }
  },
  {
    immediate: true,
    deep: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper type="fb-top-posts">
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none"> Top Posts </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  The top performing posts of the page by Engagement, published
                  during the selected time period.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="">
      <template
        v-if="
          !isLoadingStates[routes?.TOP_POSTS] &&
          topAndLeastEngagingPosts?.length === 0
        "
      >
        <div class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </template>
      <template v-else>
        <div
          class="grid gap-5"
          :class="{
            'grid-cols-3': isReportView,
            'grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 h-[535px] overflow-hidden px-8':
              !isReportView,
          }"
        >
          <template v-if="isLoadingStates[routes?.TOP_POSTS]">
            <template v-for="i in [1, 2, 3, 4, 5]" :key="i">
              <SkeletonBox :height="'500px'" width="'100%'" />
            </template>
          </template>
          <template v-else>
            <template v-for="(post, i) in topAndLeastEngagingPosts" :key="i">
              <TopPostCard
                :selected-account="selectedAccount.find((account) => post.account_id === getAccountId(account))"
                :post="post"
                :metrics-rows="rows"
              />
            </template>
          </template>
        </div>
      </template>
    </template>
  </AnalyticsCardWrapper>
</template>
