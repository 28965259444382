<template>
  <main class="bg-[#F4F7FA] flex flex-1 flex-col relative">
    <div
      v-if="accountSelection"
      class="
        flex
        items-center
        justify-between
        px-6
        h-16
        max-h-16
        min-h-16
        bg-white
      "
    >
      <div class="flex flex-col gap-y-1">
        <span class="font-medium text-base">Post Composer</span>
        <span class="font-normal text-xs xl:font-0-75rem text-gray-700">
          <template v-if="lastUpdated && lastUpdated.date">
            Saved {{ $filters.relative(lastUpdated.date) }}
          </template>
        </span>
      </div>
      <div class="flex items-center mr-2.5">
        <div class="flex items-center gap-x-3.5">


          <LabelAttachment mode="composer" :plan-id="planId" :attached-labels="labels" v-bind="$attrs" v-on="$listeners" />

          <CampaignAttachment
              mode="composer"
              :plan-id="planId"
              :attached-campaign="folderId"
              v-bind="$attrs"
              v-on="$listeners"
          />
          <i
            v-tooltip="'Copy composer URL'"
            class="far fa-link text-gray-800 text-lg font-light cursor-pointer"
            @click="copyUrlToClipBoard"
          ></i>
        </div>
      </div>
    </div>
    <div
      id="post_composer"
      class="px-6 pt-px flex-1 overflow-y-auto pb-12 cs-hr-scroll"
    >
      <!--      common Box -->
      <div :class="{ hidden: !commonBoxStatus }">
        <EditorBox
          ref="common-editor-box-ref"
          :username="username"
          :toolbar="getEditorSettings.singleBox.toolbar"
          :max-limit="getEditorSettings.singleBox.maxLimit"
          :twitter-text-enable="getEditorSettings.singleBox.twitterTextEnable"
          :sharing-details="sharingDetails.common_sharing_details"
          :linkedin-options="linkedinOptions"
          :accounts-data="getAccountsData"
          type="common"
          :utm="utm"
          :plan-id="planId"
          :replug-loader="replugLoader"
          :insta-posting-method="instaPostingMethod"
          :instagram-post-type="instagramPostType"
          :facebook-post-type="facebookPostType"
          :facebook-background-id="facebookBackgroundId"
          :facebook-group-selected="isFacebookGroupsSelected"
          :facebook-page-selected="isFacebookPageSelected"
          :youtube-post-type="youtubePostType"
          :social-post-type="socialPostType"
          :apply-hashtag="handleHashtagsChanges"
          :account-selection="accountSelection"
          :uploading-ai-images="getUploadingState"
          :selected-tabs="selectedTabs"
          :instagram-share-to-story="instagramShareToStory"
          :active-tab="activeTab"
          :common-box-status="commonBoxStatus"
          :is-empty-account-selection="isEmptyAccountSelection"
          :is-twitter-premium-selected="isTwitterPremiumSelected"
          :has-selected-locations="hasLocations"
          v-bind="$attrs"
          @set-facebook-background-id="
            $emit('set-facebook-background-id', $event)
          "
          v-on="$listeners"
          @profile-tagging="handleProfileTagging"
          @toggleFirstComment="toggleFirstComment"
          @toggleThreadedTweets="toggleThreadedTweets"
          @toggleMultiThreads="toggleMultiThreads"
          @toggleMultiBluesky="toggleMultiBluesky"
          @toggleCarouselPost="toggleCarouselPost"
          @onEditorBoxTextChange="onEditorBoxTextChange"
          @removeLinkPreview="removeLinkPreview"
          @handleSharingLink="handleSharingLink"
          @setLinkPreview="setLinkPreview"
          @initializeVideoUpload="initializeVideoUpload"
          @addMedia="addMedia"
          @handle-sort-media="handleSortMedia"
          @add-document="addDocument"
          @removeMedia="handleRemoveMedia"
          @add-location="handleLocationChanges"
          @handle-utm="handleUtmChanges"
          @handle-replug="handleReplugChanges"
          @handle-hashtags="handleHashtagsChanges"
          @handle-change="onChangeSharingDetails"
          @add-single-image="addSingleImage"
          @split-tweet-content="handleSplitTweetContent"
          @show-location-modal="showLocationModal"
        />

      </div>



      <!--      separate boxes-->
      <div :class="{ hidden: commonBoxStatus }" class="relative">
        <div
          class="
            absolute
            flex-row
            text-right
            right-0
            flex
            items-center
            justify-center
            my-auto
            h-14
            p-2
          "
        >
          <a class="clear_content_link" @click="composerClearContent"
            >Clear all content
            <i
              v-tooltip="{
                content:
                  'By clearing content, your content from all the social media message box will be removed.',
              }"
              class="far fa-question-circle"
            ></i>
          </a>
        </div>
        <!-- Customize Boxes -->

        <CstTabs
          ref="custom_box_tabs"
          active-tab-class="border-0 composer-tab-active"
          content-class="mt-2.5"
          @active-tab="handleActiveTabChange"
        >
          <template
            v-for="(value, propertyName, index) in getEditorSettings"
            :key="index"
          >
            <CstTab
              v-if="propertyName !== 'singleBox'"
              ref="social_tab_ref"
              :hidden="!isAccountSelected(propertyName)"
              :title-class="`px-1 mr-1 py-2 border-0 bg-transparent rounded-lg composer-tab-${propertyName}`"
              :name="propertyName"
            >
              <template v-slot:title>
                <div
                  :class="
                    'inline-flex justify-center items-center w-8 h-8 rounded-lg ' +
                    getSocialBackground(propertyName)
                  "
                >
                  <img
                    :src="
                      require('@assets/img/integration/' +
                        getSocialIcon(propertyName))
                    "
                    alt=""
                    class="h-[20px]"
                  />
                </div>
              </template>
              <EditorBox
                :ref="propertyName + '-editor-box-ref'"
                :username="username"
                :toolbar="value.toolbar"
                :max-limit="value.maxLimit"
                :twitter-text-enable="value.twitterTextEnable"
                :sharing-details="
                  sharingDetails[propertyName + '_sharing_details']
                "
                :linkedin-options="linkedinOptions"
                :accounts-data="getAccountsData"
                :type="propertyName"
                :utm="utm"
                :plan-id="planId"
                :replug-loader="replugLoader"
                :social-post-type="socialPostType"
                :insta-posting-method="instaPostingMethod"
                :instagram-post-type="instagramPostType"
                :facebook-post-type="facebookPostType"
                :youtube-post-type="youtubePostType"
                :facebook-background-id="facebookBackgroundId"
                :facebook-group-selected="isFacebookGroupsSelected"
                :facebook-page-selected="isFacebookPageSelected"
                :apply-hashtag="handleHashtagsChanges"
                :account-selection="accountSelection"
                :uploading-ai-images="getUploadingState"
                :selected-tabs="selectedTabs"
                :active-tab="activeTab"
                :common-box-status="commonBoxStatus"
                :is-twitter-premium-selected="isTwitterPremiumSelected"
                :has-selected-locations="hasLocations"
                v-bind="$attrs"
                v-on="$listeners"
                @toggleFirstComment="toggleFirstComment"
                @toggleThreadedTweets="toggleThreadedTweets"
                @toggleMultiThreads="toggleMultiThreads"
                @toggleMultiBluesky="toggleMultiBluesky"
                @toggleCarouselPost="toggleCarouselPost"
                @onEditorBoxTextChange="onEditorBoxTextChange"
                @removeLinkPreview="removeLinkPreview"
                @handleSharingLink="handleSharingLink"
                @setLinkPreview="setLinkPreview"
                @initializeVideoUpload="initializeVideoUpload"
                @addMedia="addMedia"
                @handle-sort-media="handleSortMedia"
                @add-document="addDocument"
                @removeMedia="handleRemoveMedia"
                @add-location="handleLocationChanges"
                @handle-utm="handleUtmChanges"
                @handle-replug="handleReplugChanges"
                @handle-hashtags="handleHashtagsChanges"
                @handle-change="onChangeSharingDetails"
                @add-single-image="addSingleImage"
                @profile-tagging="handleProfileTagging"
                @split-tweet-content="handleSplitTweetContent"
                @show-location-modal="showLocationModal"
              />
            </CstTab>
          </template>
        </CstTabs>
      </div>


      <!-- Threaded Tweets Box -->
      <div v-if="twitterAccounts.length" :class="{ hidden: !showTwitterThreads }">
        <div
          class="grid grid-flow-col auto-cols-max items-center my-5 ml-3.5"
          :class="{ hidden: isThreadedTweetsAdded }"
        >
          <div class="w-48 flex items-center">
            <div
              class="
                inline-flex
                justify-center
                items-center
                w-8
                h-8
                rounded-lg
                text-lg
                bg-gray-400
              "
            >
              <img
                src="@assets/img/integration/twitter-x-rounded.svg"
                alt=""
                class="w-5 h-5"
              />
            </div>
            <span class="mx-2 font-normal text-sm text-black-300"
              >Threaded Tweet</span
            >
            <v-menu
              :popper-triggers="['hover']"
              placement="bottom-end"
              popper-class="threaded-tweets__info-popover"
            >
              <a href="#"
                ><img
                  src="@assets/img/composer/help-icon.svg"
                  alt=""
                  class="w-5 h-5"
              /></a>
              <template v-slot:popper>
                <div> Threaded Tweets are available for:</div>
                <ul>
                  <li><i class="fa fa-twitter !bg-black-900"></i> Profiles</li>
                </ul>
                <p class="threaded-tweets__message-box"
                  >How to add threaded tweets?
                  <a
                    href="#"
                    class="beacon"
                    data-beacon-article-modal="61826fd112c07c18afde3ed5"
                    >Click here</a
                  ></p
                >
              </template>
            </v-menu>
          </div>
          <div>
            <CstSwitch
              v-model="twitterOptions.has_threaded_tweets"
              class="mx-2"
              :disabled="twitterAccounts.length < 1"
              size="small"
            />
          </div>
        </div>
        <div v-if="isThreadedTweetsAdded" class="mt-4">
          <EditorThreadedTweetsBox
            :twitter-options="twitterOptions"
            :accounts="twitterAccounts"
            :account-type="'twitter'"
            :accounts-data="getAccountsData"
            :apply-hashtag="handleHashtagsChanges"
            @onChangeThreadDetails="onChangeThreadDetails"
            @addThreadedTweet="addThreadedTweet"
            @removeThreadedTweet="removeThreadedTweet"
            @handle-hashtags="handleHashtagsChanges"
          />
        </div>
      </div>
      <!-- Multi Threads Box -->
      <div v-if="threadsAccounts.length" :class="{ hidden: !showThreads }">
        <div
          class="grid grid-flow-col auto-cols-max items-center my-5 ml-3.5"
          :class="{ hidden: isMultiThreadsAdded }"
        >
          <div class="w-48 flex items-center">
            <div
              class="
                inline-flex
                justify-center
                items-center
                w-8
                h-8
                rounded-lg
                text-lg
                bg-gray-400
              "
            >
              <img
                src="@assets/img/integration/threads-rounded.svg"
                alt="threads"
                class="w-[1.4rem] h-[1.4rem]"
              />
            </div>
            <span class="mx-2 font-normal text-sm text-black-300"
            >Multi Meta Threads</span
            >
            <v-menu
              :popper-triggers="['hover']"
              placement="bottom-end"
              popper-class="threaded-tweets__info-popover"
            >
              <a href="#"
                ><img
                  src="@assets/img/composer/help-icon.svg"
                  alt=""
                  class="w-5 h-5"
              /></a>
              <template v-slot:popper>
                <div>Multi Meta Threads are available for:</div>
                <ul>
                  <li>
                    <img
                      src="@assets/img/integration/threads-rounded.svg"
                      alt="threads"
                      class="w-[1.5rem] h-[1.5rem] mr-2"
                    />
                    Profiles
                  </li>
                </ul>
                <p class="threaded-tweets__message-box"
                  >How to add multi meta threads?
                  <a
                    href="#"
                    class="beacon"
                    data-beacon-article-modal="66a1de2f16e6a80d1e1d0760"
                    >Click here</a
                  ></p
                >
              </template>
            </v-menu>
          </div>
          <div>
            <CstSwitch
              v-model="threadsOptions.has_multi_threads"
              class="mx-2"
              :disabled="threadsAccounts.length < 1"
              size="small"
            />
          </div>
        </div>
        <div v-if="isMultiThreadsAdded" class="mt-4">
          <EditorMultiThreadsBox
            :threads-options="threadsOptions"
            :accounts="threadsAccounts"
            :accounts-data="getAccountsData"
            :account-type="'threads'"
            :apply-hashtag="handleHashtagsChanges"
            @onChangeMultiThreadsDetails="onChangeMultiThreadsDetails"
            @addMultiThreads="addMultiThreads"
            @removeMultiThreads="removeMultiThreads"
            @handle-hashtags="handleHashtagsChanges"
          />
        </div>
      </div>
      <!-- Multi Bluesky Box -->
      <div :class="{ hidden: !showBluesky || !blueskyAccounts.length }">
        <div
            class="grid grid-flow-col auto-cols-max items-center my-5 ml-3.5"
            :class="{ hidden: isBlueskyTweetAdded  }"
        >
          <div class="w-48 flex items-center">
            <div
                class="
                inline-flex
                justify-center
                items-center
                w-8
                h-8
                rounded-lg
                text-lg
                bg-gray-400
              "
            >
              <img
                  src="@assets/img/integration/bluesky-rounded.svg"
                  alt="threads"
                  class="w-[1.4rem] h-[1.4rem]"
              />
            </div>
            <span class="mx-2 font-normal text-sm text-black-300"
            >Bluesky Threads</span
            >
            <v-menu
                :popper-triggers="['hover']"
                placement="bottom-end"
                popper-class="threaded-tweets__info-popover"
            >
              <a href="#"
              ><img
                  src="@assets/img/composer/help-icon.svg"
                  alt=""
                  class="w-5 h-5"
              /></a>
              <template v-slot:popper>
                <div>Bluesky Threads are available for:</div>
                <ul>
                  <li>
                    <img
                        src="@assets/img/integration/bluesky-rounded.svg"
                        alt="bluesky"
                        class="w-[1.5rem] h-[1.5rem] mr-2"
                    />
                    Profiles
                  </li>
                </ul>
                <p class="threaded-tweets__message-box"
                >How to add Bluesky threads?
                  <a
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="676154c4eb06695f64f33ccd"
                  >Click here</a
                  ></p
                >
              </template>
            </v-menu>
          </div>
          <div>
            <CstSwitch
                v-model="blueskyOptions.has_multi_bluesky"
                class="mx-2"
                :disabled="blueskyAccounts.length < 1"
                size="small"
            />
          </div>
        </div>
        <div v-if="isBlueskyTweetAdded" class="mt-4">
          <EditorBlueskyBox
              :bluesky-options="blueskyOptions"
              :accounts="blueskyAccounts"
              :account-type="'bluesky'"
              :accounts-data="getAccountsData"
              :apply-hashtag="handleHashtagsChanges"
              @onChangeBlueskyDetails="onChangeBlueskyDetails"
              @addBlueskyTweet="addBlueskyTweet"
              @removeBlueskyTweet="removeBlueskyTweet"
              @handle-hashtags="handleHashtagsChanges"
          />
        </div>
      </div>
      <!-- Multi Bluesky Box -->
      <div :class="{ hidden: !showBluesky }">
        <div
            class="grid grid-flow-col auto-cols-max items-center my-5 ml-3.5"
            :class="{ hidden: isBlueskyTweetAdded }"
        >
          <div class="w-48 flex items-center">
            <div
                class="
                inline-flex
                justify-center
                items-center
                w-8
                h-8
                rounded-lg
                text-lg
                bg-gray-400
              "
            >
              <img
                  src="@assets/img/integration/bluesky-rounded.svg"
                  alt="threads"
                  class="w-[1.4rem] h-[1.4rem]"
              />
            </div>
            <span class="mx-2 font-normal text-sm text-black-300"
            >Bluesky Threads</span
            >
            <v-menu
                :popper-triggers="['hover']"
                placement="bottom-end"
                popper-class="threaded-tweets__info-popover"
            >
              <a href="#"
              ><img
                  src="@assets/img/composer/help-icon.svg"
                  alt=""
                  class="w-5 h-5"
              /></a>
              <template v-slot:popper>
                <div>Bluesky Threads are available for:</div>
                <ul>
                  <li>
                    <img
                        src="@assets/img/integration/bluesky-rounded.svg"
                        alt="bluesky"
                        class="w-[1.5rem] h-[1.5rem] mr-2"
                    />
                    Profiles
                  </li>
                </ul>
                <p class="threaded-tweets__message-box"
                >How to add Bluesky threads?
                  <a
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="676154c4eb06695f64f33ccd"
                  >Click here</a
                  ></p
                >
              </template>
            </v-menu>
          </div>
          <div>
            <CstSwitch
                v-model="blueskyOptions.has_multi_bluesky"
                class="mx-2"
                :disabled="blueskyAccounts.length < 1"
                size="small"
            />
          </div>
        </div>
        <div v-if="isBlueskyTweetAdded" class="mt-4">
          <EditorBlueskyBox
              :bluesky-options="blueskyOptions"
              :accounts="blueskyAccounts"
              :account-type="'bluesky'"
              :accounts-data="getAccountsData"
              :apply-hashtag="handleHashtagsChanges"
              @onChangeBlueskyDetails="onChangeBlueskyDetails"
              @addBlueskyTweet="addBlueskyTweet"
              @removeBlueskyTweet="removeBlueskyTweet"
              @handle-hashtags="handleHashtagsChanges"
          />
        </div>
      </div>
      <!-- Facebook Carousel Box -->
      <div v-if="carouselAccounts.length || facebookPostType === 'story'" :class="{ hidden: !showCarousel }">
        <div
          class="grid grid-flow-col auto-cols-max items-center my-5 ml-4"
          :class="{ hidden: isCarouselPostAdded }"
        >
          <div class="w-48 flex items-center">
            <div
              class="
                inline-flex
                justify-center
                items-center
                w-8
                h-8
                rounded-lg
                text-lg
                bg-cs-secondary
              "
            >
              <img
                src="@assets/img/composer/facebook-carousel-icon.svg"
                alt=""
                class="w-5 h-5"
              />
            </div>
            <span class="mx-2 font-normal text-sm text-black-300"
              >Facebook Carousel</span
            >
            <v-menu
              :popper-triggers="['hover']"
              placement="bottom-end"
              popper-class="first-comment__info-popover"
            >
              <!-- This will be the popover target (for the events and position) -->
              <a href="#"
                ><img
                  src="@assets/img/composer/help-icon.svg"
                  alt=""
                  class="w-5 h-5"
              /></a>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div> Carousel Post is available for:</div>
                <ul>
                  <li><i class="fab fa-facebook-f"></i> Pages</li>
                </ul>

                <p class="first-comment__message-box"
                  >How to add carousel post?
                  <a
                    href="#"
                    class="beacon"
                    data-beacon-article-modal="6221f69bab585b230a89d34b"
                    >Click here</a
                  ></p
                >
              </template>
            </v-menu>
          </div>
          <div>
            <CstSwitch
              v-model="carouselOptions.is_carousel_post"
              v-tooltip="{
                content:
                  facebookPostType === 'story'
                    ? 'Carousel option is not available when Story is selected.'
                    : '',
              }"
              class="mx-2"
              :disabled="
                carouselAccounts.length < 1 || facebookPostType === 'story'
              "
              size="small"
            />
          </div>
        </div>
        <div v-if="isCarouselPostAdded" class="mt-4">
          <EditorCarouselBox
            :account-selection="accountSelection"
            :carousel-accounts="carouselAccounts"
            :carousel-options="carouselOptions"
            :errors="fbCarouselErrors"
            :carousel-images="getSharingDetailsForCarousel.image"
            :url="carouselUrl"
            v-bind="$attrs"
            v-on="$listeners"
          />
        </div>
      </div>

      <!-- Linkedin Carousel Box-->
      <div v-if="accountSelection.linkedin.length && (commonBoxStatus || activeTab === 'linkedin')">
        <div
          :class="{ hidden: linkedinOptions.is_carousel }"
          class="grid grid-flow-col auto-cols-max items-center my-5 ml-4"
        >
          <div class="w-48 flex items-center">
            <div
              class="
                inline-flex
                justify-center
                items-center
                w-8
                h-8
                rounded-lg
                text-lg
                bg-cs-secondary
              "
            >
              <img
                src="@assets/img/composer/linkedin_block.svg"
                alt=""
                class="w-5 h-5"
              />
            </div>
            <span class="mx-2 font-normal text-sm text-black-300"
              >Linkedin Carousel</span
            >
            <v-menu
              :popper-triggers="['hover']"
              placement="bottom-end"
              popper-class="first-comment__info-popover"
            >
              <!-- This will be the popover target (for the events and position) -->
              <a href="#"
                ><img
                  src="@assets/img/composer/help-icon.svg"
                  alt=""
                  class="w-5 h-5"
              /></a>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div> Carousel Post is available for:</div>
                <ul>
                  <li><i class="fab fa-linkedin-in"></i>Pages and Profiles</li>
                </ul>

                <p class="first-comment__message-box"
                  >How to add carousel post?
                  <a
                    href="#"
                    class="beacon"
                    data-beacon-article-modal="64c8899af3abbf47e505323d"
                    >Click here</a
                  ></p
                >
              </template>
            </v-menu>
          </div>
          <div>
            <span
              v-tooltip="{
                content:
                  accountSelection['linkedin'].length < 1 ||
                  sharingDetails[
                    commonBoxStatus
                      ? 'common_sharing_details'
                      : 'linkedin_sharing_details'
                  ].image.length <= 1
                    ? getTooltipContent
                    : '',
              }"
            >
              <CstSwitch
                v-model="linkedinOptions.is_carousel"
                class="mx-2"
                size="small"
                :disabled="isLinkedinCarouselDisabled"
              />
            </span>
          </div>
        </div>
        <div v-if="linkedinOptions.is_carousel" class="mt-4">
          <LinkedInCarouselBox
            :is-linkedin-carousel-disabled="isLinkedinCarouselDisabled"
            :linkedin-account-selection="getLinkedinCarouselAccounts"
            :linkedin-options="linkedinOptions"
            :common-box-status="commonBoxStatus"
            :sharing-details="sharingDetails"
            @close-linkedin-carousel="showLinkedinCarousel = false"
          />
        </div>
      </div>

      <!-- First Comment Box -->
      <div v-if="firstCommentAccounts.length" :class="{ hidden: !showFirstComment }">
        <div
          class="grid grid-flow-col auto-cols-max items-center my-5 ml-4"
          :class="{ hidden: firstComment.has_first_comment }"
        >
          <div class="w-48 flex items-center">
            <div
              class="
                inline-flex
                justify-center
                items-center
                w-8
                h-8
                rounded-lg
                text-lg
                bg-cs-secondary
              "
            >
              <img
                src="@assets/img/composer/first-comment.svg"
                alt=""
                class="w-5 h-5"
              />
            </div>
            <span class="mx-2 font-normal text-sm text-black-300"
              >First Comment</span
            >
            <v-menu
              :popper-triggers="['hover']"
              placement="bottom-end"
              popper-class="first-comment__info-popover"
            >
              <!-- This will be the popover target (for the events and position) -->
              <a href="#"
                ><img
                  src="@assets/img/composer/help-icon.svg"
                  alt=""
                  class="w-5 h-5"
              /></a>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div> First comment is available for:</div>
                <ul>
                  <li><i class="fab fa-facebook-f"></i> Pages</li>
                  <li><i class="fab fa-instagram"></i> API Posting Method</li>
                  <li>
                    <i class="fab fa-linkedin-in"></i> Profiles and Pages
                  </li>
                  <li>
                    <i class="fab fa-youtube"></i> All videos except 'Private'
                    and 'Made for Kids'
                  </li>
                </ul>

                <p class="first-comment__message-box"
                  >How to add first comment?
                  <a
                    v-close-popper
                    href="#"
                    class="beacon"
                    data-beacon-article-modal="60407b770a2dae5b58fb5bef"
                    >Click here</a
                  ></p
                >
              </template>
            </v-menu>
          </div>
          <div>
            <CstSwitch
              v-model="firstComment.has_first_comment"
              class="mx-2"
              :disabled="firstCommentAccounts.length < 1"
              size="small"
            />
          </div>
        </div>
        <div v-if="firstComment.has_first_comment" class="mt-4">
          <EditorFirstCommentBox
            :first-comment="firstComment"
            :accounts-list="firstCommentAccounts"
            :apply-hashtag="handleHashtagsChanges"
            :is-allow-first-comment="isAllowFirstComment"
            :utm="utm"
            @changeFirstComment="handleFirstCommentChange"
            @handle-hashtags="handleHashtagsChanges"
            v-bind="$attrs"
            v-on="$listeners"
          />
        </div>
      </div>

      <!-- gmb Settings-->
      <CstCollapsible
        id="gmb-settings"
        :class="{ hidden: !showGmbSettings }"
        class="box-hover mt-4"
        open
      >
        <template v-slot:header>
          <span
            class="
              font-medium
              text-sm text-black-300
              flex
              justify-center
              items-center
            "
          >
            <div
              class="
                'inline-flex
                justify-center
                items-center
                w-8
                h-8
                rounded-lg
                text-lg
                font-medium
                bg-cs-light-blue
                mx-1.5
              "
            >
              <img
                src="@assets/img/integration/gmb-icon.svg"
                alt=" "
                class="w-6 h-6"
              />
            </div>
            Google My Business
            <v-menu
              v-if="gmbErrors.length"
              :popper-triggers="['hover']"
              class="mx-1 mr-0.5"
              offset="10"
              placement="bottom-start"
              popper-class="tooltip-danger tooltip-danger--clickable"
            >
              <img
                id="gmb-options-tooltip"
                src="@assets/img/composer/error-icon.svg"
                alt=""
                class="w-6 h-6"
              />
              <template v-slot:popper>
                <div class="p-2">
                  <ul>
                    <li
                      v-for="(item, index) in gmbErrors"
                      :key="`gmb_options_${index}`"
                      >{{ item.text }}</li
                    >
                  </ul>
                </div>
              </template>
            </v-menu>
          </span>
        </template>
        <template v-slot>
          <GMBOptions
            :gmb-options="gmbOptions"
            :gmb-sharing-data="activeGmbSharingDetail"
            @setButtonLink="setButtonLink"
            @setGmbOptionsEdited="(val) => $emit('setGmbOptionsEdited', val)"
          ></GMBOptions>
        </template>
      </CstCollapsible>

      <!-- Facebook Settings -->
      <CstCollapsible
        id="facebook-settings"
        class="box-hover mt-4"
        :class="{ hidden: !showFacebookSettings }"
        open
      >
        <template v-slot:header>
          <span
            class="
              font-medium
              text-sm text-black-300
              flex
              justify-center
              items-center
            "
          >
            <span
              class="
                inline-flex
                justify-center
                items-center
                w-8
                h-8
                rounded-lg
                text-lg
                bg-cs-light-blue
                text-[#02b2ff]
                mx-1.5
              "
            >
              <img
                src="@assets/img/integration/facebook-icon.svg"
                alt=""
                class="w-5"
              />
            </span>
            Facebook Settings
          </span>
        </template>
        <template v-slot>
          <FacebookOptions
            :facebook-post-type="facebookPostType"
            :facebook-video-title="activeFacebookSharingDetail.video.title"
            :facebook-sharing-details="activeFacebookSharingDetail"
            :is-facebook-groups-selected="isFacebookGroupsSelected"
            :is-facebook-page-selected="isFacebookPageSelected"
            :is-facebook-carousel="carouselOptions.is_carousel_post"
            :facebook-background-id="facebookBackgroundId"
            :facebook-share-to-story="facebookShareToStory"
            :carousel-options="carouselOptions"
            @set-facebook-share-to-story="setFacebookShareToStory"
            v-bind="$attrs"
            v-on="$listeners"
          ></FacebookOptions>
        </template>
      </CstCollapsible>

      <!-- Instagram Settings -->
      <CstCollapsible
        id="instagram-settings"
        :class="{ hidden: !showInstagramSettings }"
        class="box-hover mt-4"
        open
      >
        <template v-slot:header>
          <span
            class="
              font-medium
              text-sm text-black-300
              flex
              justify-center
              items-center
            "
          >
            <i
              class="
                fab
                fa-instagram
                inline-flex
                justify-center
                items-center
                w-8
                h-8
                rounded-lg
                text-lg
                bg-cs-light-red
                text-cs-red
                mx-1.5
              "
            ></i>
            Instagram Settings
          </span>
        </template>
        <template v-slot>
          <InstagramOptions
            :instagram-post-type="instagramPostType"
            :is-video-post="isInstagramVideoPost"
            :is-single-image-post="isInstagramSingleImagePost"
            :is-carousel-post="isInstagramCarouselPost"
            :instagram-selection="instagramSelection"
            :insta-posting-method="instaPostingMethod"
            :instagram-collaborators="instagramCollaborators"
            :set-instagram-collaborators="setInstagramCollaborators"
            :instagram-share-to-story="instagramShareToStory"
            :set-instagram-share-to-story="setInstagramShareToStory"
            v-bind="$attrs"
            v-on="$listeners"
          />
        </template>
      </CstCollapsible>
      <!-- Tiktok Settings -->
      <CstCollapsible
        id="tiktok-settings"
        :class="{ hidden: !showTiktokSettings }"
        class="box-hover mt-4"
        open
      >
        <template v-slot:header>
          <span
            class="
              font-medium
              text-sm text-black-300
              flex
              justify-center
              items-center
            "
          >
            <span
              class="
                inline-flex
                justify-center
                items-center
                w-8
                h-8
                rounded-lg
                text-lg
                bg-cs-light-violet
                text-cs-red
                mx-1.5
              "
            >
              <img
                src="@assets/img/integration/tiktok-icon.svg"
                alt=" "
                class="w-5"
              />
            </span>
            TikTok Settings
            <v-menu
              v-if="tiktokErrors.length"
              class="mx-1 mr-0.5"
              offset="10"
              popover-class="tooltip-danger tooltip-danger--clickable"
              placement="bottom-start"
              :popper-triggers="['hover']"
            >
              <img
                src="@assets/img/composer/error-icon.svg"
                alt=""
                class="w-6 h-6"
              />
              <template v-slot:popper>
                <div class="p-2">
                  <ul class="list-disc">
                    <li
                      v-for="(item, index) in tiktokErrors"
                      :key="`tiktok_options_${index}`"
                      >{{ item.text }}</li
                    >
                  </ul>
                </div>
              </template>
            </v-menu>
          </span>
        </template>
        <template v-slot>
          <TiktokOptions :tiktok-options="tiktokOptions" />
        </template>
      </CstCollapsible>
      <!-- youtube Settings-->
      <CstCollapsible
        id="youtube-settings"
        :class="{ hidden: !showYoutubeSettings }"
        class="box-hover mt-4"
        open
      >
        <template v-slot:header>
          <span
            class="
              font-medium
              text-sm text-black-300
              flex
              justify-center
              items-center
            "
          >
            <i
              class="
                fab
                fa-youtube
                inline-flex
                justify-center
                items-center
                rounded-lg
                text-[20px]
                text-[#FF0000]
                mx-1.5
              "
            ></i>
            Youtube Settings
            <v-menu
              v-if="youtubeErrors.length"
              class="mx-1 mr-0.5"
              offset="10"
              popper-class="tooltip-danger tooltip-danger--clickable"
              placement="bottom-start"
              :popper-triggers="['hover']"
            >
              <img
                id="youtube-options-tooltip"
                src="@assets/img/composer/error-icon.svg"
                alt=""
                class="w-6 h-6"
              />
              <template v-slot:popper>
                <div class="p-2">
                  <ul class="list-disc">
                    <li
                      v-for="(item, index) in youtubeErrors"
                      :key="`gmb_options_${index}`"
                      >{{ item.text }}</li
                    >
                  </ul>
                </div>
              </template>
            </v-menu>
          </span>
        </template>
        <template v-slot>
          <YoutubeOptions
            :account-selection="accountSelection"
            :accounts-list="accountsList"
            :youtube-options="youtubeOptions"
            v-bind="$attrs"
            v-on="$listeners"
          />
        </template>
      </CstCollapsible>
      <!-- Posting Options-->
      <CstCollapsible
        id="posting-schedule"
        :class="{ hidden: isApprover }"
        class="box-hover mt-4"
        open
      >
        <template v-slot:header>
          <span
            class="
              font-medium
              text-sm text-black-300
              flex
              justify-center
              items-center
            "
          >
            <div
              class="
                inline-flex
                justify-center
                items-center
                w-8
                h-8
                rounded-lg
                text-lg
                font-medium
                bg-gray-400
                mx-1.5
              "
            >
              <img
                src="@assets/img/composer/posting-schedule.svg"
                alt=" "
                class="w-6 h-6"
              />
            </div>
            Posting Schedule
            <v-menu
              v-if="postingScheduleErrors.length"
              class="mx-1 mr-0.5"
              offset="10"
              popper-class="tooltip-danger tooltip-danger--clickable"
              placement="bottom-start"
              :popper-triggers="['hover']"
            >
              <img
                id="posting-schedule-tooltip"
                src="@assets/img/composer/error-icon.svg"
                alt=""
                class="w-6 h-6"
              />
              <template v-slot:popper>
                <div class="p-2">
                  <ul>
                    <li
                      v-for="(item, index) in postingScheduleErrors"
                      :key="`gmb_options_${index}`"
                      >{{ item.text }}
                    </li>
                  </ul>
                </div>
              </template>
            </v-menu>
          </span>
        </template>

        <template v-slot>
          <PostingSchedule
            :accounts-list="accountsList"
            :account-selection="accountSelection"
            :is-facebok-selected="false"
            :is-instagram-selected="
              (instagramSelection &&
                instagramSelection.no_access_accounts.length > 0) ||
              instagramSelection.all_access_accounts.length > 0
            "
            :instagram-selection="instagramSelection"
            :selected-content-category="selectedContentCategory"
            :publish-time-options="publishTimeOptions"
            :global-content-category="globalContentCategory"
            :next-content-category-slot="nextContentCategorySlot"
            :plan-id="planId"
            :queue-status="queueStatus"
            :post-status="postStatus"
            :social-post-type="socialPostType"
            v-bind="$attrs"
            v-on="$listeners"
            @disable-schedule-button="handleDisableScheduleButton"
          ></PostingSchedule>
        </template>
      </CstCollapsible>

      <div
        v-if="
          publishTimeOptions.time_type !== 'now' &&
          approval.approvers &&
          approval.approvers.length > 0 &&
          getUserRole !== 'Approver'
        "
        class="post_share_option"
      >
        <div class="schedule_detail_box mt-3">
          <div class="bottom_content mt-0">
            <h3>Approval Status </h3>
            <p
              v-if="approval.approvers.length > 1"
              class="p-2 text-center"
              v-html="getApprovalOptionText(approval.approve_option)"
            />

            <div class="approval_list">
              <FeedViewApprovalStatus
                :approval="approval"
              ></FeedViewApprovalStatus>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainComposerFooter
      :warnings="footerData.warnings"
      :errors="footerData.errors"
      :publish-time-options="footerData.publishTimeOptions"
      :approval-data="footerData.approvalData"
      :global-content-category="footerData.globalContentCategory"
      :post-has-errors="footerData.postHasErrors"
      :is-approver="footerData.isApprover"
      :next-content-category-slot="footerData.nextContentCategorySlot"
      :queue-status="queueStatus"
      :post-status="postStatus"
      :social-post-type="socialPostType"
      :process-plan-loader="processPlanLoader"
      :approver-modal-validation="approverModalValidation"
      :clear-state="clearState"
      :uploading-ai-images="getUploadingState"
      :disable-schedule-button="disableScheduleButton"
      :selected-accounts-length="selectedAccountsLength"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </main>
  <SetLocationModal
      :selected-accounts="getSelectedAccountsLocations"
      :selected-locations="locations"
      v-bind="$attrs"
      v-on="$listeners"
  />
</template>

<script>
import CstSwitch from '@ui/Switch/CstSwitch'
import CstTabs from '@ui/CstTabs'
import CstTab from '@ui/CstTab'
import CstCollapsible from '@ui/Collapse/CstCollapsible'
import editorInitialValue from '@src/modules/composer_v2/components/EditorBox/editorInitialValue'
import PostingSchedule from '@src/modules/composer_v2/components/PostingSchedule/PostingSchedule'
import { commonMethods } from '@common/store/common-methods'
import {
  defaultCommonSharingDetails,
  defaultFirstComment,
  defaultSharingDetails,
  defaultTwitterOptions,
  defaultThreadsOptions,
  defaultBlueskyOptions,
  defaultTwitterSharingDetails,
  firstCommentChannels,
  videoDefaultSharingDetails,
  defaultThreadsSharingDetails,
  defaultCarouselOptions,
  defaultBlueskySharingDetails,
} from '@src/modules/composer_v2/views/composerInitialState'
import EditorFirstCommentBox from '@src/modules/composer_v2/components/EditorBox/EditorFirstCommentBox'
import EditorThreadedTweetsBox from '@src/modules/composer_v2/components/EditorBox/EditorThreadedTweetsBox'
import EditorMultiThreadsBox from '@src/modules/composer_v2/components/EditorBox/EditorMultiThreadsBox'
import EditorCarouselBox from '@src/modules/composer_v2/components/EditorBox/EditorCarouselBox'
import EditorBlueskyBox from '@src/modules/composer_v2/components/EditorBox/EditorBlueskyBox'
import LinkedInCarouselBox from '@src/modules/composer_v2/components/EditorBox/LinkedInCarouselBox.vue'
import SetLocationModal from "@modules/composer_v2/components/SetLocationModal.vue";

import {
  facebookPostLimit,
  fetchReplugLinksUrl,
  gmbPostLimit,
  instagramPostLimit,
  linkedinPostLimit,
  pinterestPostLimit,
  twitterPostLimit,
  twitterPremiumPostLimit,
  youtubePostLimit,
  tiktokPostLimit,
  threadsPostLimit,
  blueskyPostLimit,
} from '@src/modules/publish/config/api-utils'
import GMBOptions from '@src/modules/composer_v2/components/ChannelOptions/GmbOptions'
import YoutubeOptions from '@src/modules/composer_v2/components/ChannelOptions/YoutubeOptions'
import proxy from '@common/lib/http-common'
import { mapGetters } from 'vuex'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import { swalAttributes } from '@common/constants/common-attributes'
import FeedViewApprovalStatus from '@src/modules/planner/components/view/feed-view/FeedViewApprovalStatus'
import {
  HASHTAG_SAVE_SUCCESS,
  INVALID_COUNT,
  INVALID_NAME_HASHTAG,
  NO_HASHTAG,
} from '@common/constants/messages'
import hashtag from '@common/lib/hashtag'
import { saveHashTagUrl } from '@src/modules/setting/config/api-utils'
import InstagramOptions from '@src/modules/composer_v2/components/ChannelOptions/InstagramOptions'
import TiktokOptions from '@src/modules/composer_v2/components/ChannelOptions/TiktokOptions.vue'
import FacebookOptions from '@src/modules/composer_v2/components/ChannelOptions/FacebookOptions'
import MainComposerFooter from '@src/modules/composer_v2/components/MainComposerFooter'
import { EventBus } from '@common/lib/event-bus'
import { toRaw } from 'vue'
import CampaignAttachment from '@src/components/common/CampaignAttachment.vue'
import LabelAttachment from "@src/components/common/LabelAttachment.vue";
const clone = require('rfdc/default')
export default {
  name: 'MainComposer',
  components: {
    CampaignAttachment,
    LabelAttachment,
    InstagramOptions,
    FacebookOptions,
    TiktokOptions,
    FeedViewApprovalStatus,
    YoutubeOptions,
    GMBOptions,
    PostingSchedule,
    CstSwitch,
    CstCollapsible,
    EditorFirstCommentBox,
    EditorThreadedTweetsBox,
    EditorMultiThreadsBox,
    EditorCarouselBox,
    MainComposerFooter,
    CstTabs,
    CstTab,
    LinkedInCarouselBox,
    SetLocationModal,
    EditorBlueskyBox
  },
  mixins: [ComposerHelper],
  props: {
    username: {
      type: String,
      default: '',
      required: true,
    },
    commonBoxStatus: {
      type: Boolean,
      default: true,
      required: true,
    },
    sharingDetails: {
      type: Object,
      default: () => defaultSharingDetails,
      required: true,
    },
    platforms: {
      type: Array,
      default: () => [],
    },
    accountsList: {
      type: Array,
      default: () => [],
    },
    accountSelection: {
      type: Object,
      default: () => {},
    },
    firstCommentAccountsList: {
      type: Array,
      default: () => [],
    },
    firstComment: {
      type: Object,
      default: () => defaultFirstComment,
    },
    selectedContentCategory: {
      type: Object,
      default: () => {},
    },
    nextContentCategorySlot: {
      type: Object,
      default: () => {},
    },
    publishTimeOptions: {
      type: Object,
      default: () => {},
    },
    globalContentCategory: {
      type: Boolean,
      default: false,
    },
    planId: {
      type: String,
      default: null,
    },
    utm: {
      type: Object,
      default: () => {},
    },
    gmbOptions: {
      type: Object,
      default: () => {},
    },
    youtubeOptions: {
      type: Object,
      default: () => {},
    },
    twitterOptions: {
      type: Object,
      default: () => {
        return { ...defaultTwitterOptions }
      },
    },
    threadsOptions: {
      type: Object,
      default: () => {
        return { ...defaultThreadsOptions }
      },
    },
    blueskyOptions: {
      type: Object,
      default: () => {
        return { ...defaultBlueskyOptions }
      },
    },
    carouselOptions: {
      type: Object,
      default: () => {},
    },
    carouselUrl: {
      type: Object,
      default: () => {},
    },
    carouselAccounts: {
      type: Array,
      default: () => [],
    },
    isAllowFirstComment: {
      type: Function,
      default: () => {},
    },
    gmbErrors: {
      type: Array,
      default: () => [],
    },
    youtubeErrors: {
      type: Array,
      default: () => [],
    },
    fbCarouselErrors: {
      type: Array,
      default: () => [],
    },
    postingScheduleErrors: {
      type: Array,
      default: () => [],
    },
    socialErrors: {
      type: Array,
      default: () => [],
    },
    approval: {
      type: Object,
      default: () => {},
    },
    isApprover: {
      type: Boolean,
    },
    instaPostingMethod: {
      type: String,
      default: '',
    },
    instagramPostType: {
      type: String,
      default: '',
    },
    instagramCollaborators: {
      type: Array,
      default: [],
    },
    instagramShareToStory: {
      type: Boolean,
      default: false,
    },
    facebookPostType: {
      type: String,
      default: 'feed',
    },
    facebookBackgroundId: {
      type: String,
      default: '',
    },
    facebookShareToStory: {
      type: Boolean,
      default: false,
    },
    lastUpdated: {
      type: [Object, String],
      default: () => {},
    },
    queueStatus: {
      type: [Boolean, Number],
      default: false,
    },
    footerData: {
      type: Object,
      default: () => {},
    },
    socialPostType: {
      type: String,
      default: 'new',
    },
    processPlanLoader: {
      type: Boolean,
      default: false,
    },
    approverModalValidation: {
      type: Function,
      default: () => {},
    },
    postStatus: {
      type: String,
      default: 'draft',
    },
    uploadingAiImages: {
      type: Boolean,
      default: false,
    },
    clearState: {
      type: Function,
      default: () => {},
    },
    selectedTabs: {
      type: String,
      default: '',
    },
    activeTab: {
      type: String,
      default: '',
    },
    tiktokOptions: {
      type: Object,
      default: () => {},
    },
    tiktokErrors: {
      type: Array,
      default: () => [],
    },
    selectedAccountsLength: {
      type: Number,
      default: 0,
    },
    linkedinOptions: {
      type: Object,
      default: () => {},
    },
    folderId: {
      type: String,
      default: '',
    },
    labels: {
      type: Array,
      default: () => [],
    },
    locations: {
      type: Object,
      default: () => {}
    },
  },
  emits: [
    'onChangeSharingDetails',
    'set-facebook-background-id',
    'set-facebook-share-to-story',
'set-facebook-post-type',
    'onChangeSharingOptions',
    'reset-facebook-carousel',
    'profile-tagging',
    'setInstagramPostType',
    'setInstagramCollaborators',
    'setInstagramShareToStory',
    'facebook-page-selected',
    'facebook-group-selected',
    'active-tab',
  ],
  data() {
    return {
      isSeparateBoxes: false,
      instagramSelection: {
        no_access_accounts: [],
        all_access_accounts: [],
      },
      characterCount: 0,
      editorsCharacterCount: {
        common: 0,
        facebook: 0,
        twitter: 0,
        instagram: 0,
        linkedin: 0,
        youtube: 0,
        pinterest: 0,
        gmb: 0,
        tumblr: 0,
        tiktok: 0,
        threads: 0,
      },
      activeTabIndex: 0,
      replugLoader: false,
      disableScheduleButton: false,
      showLinkedinCarousel: false,
      selectedToolbarOption: '',
      isInstagramVideoPost: false,
      isInstagramSingleImagePost: false,
      isInstagramCarouselPost: false,
      isToastDisplayedForFacebookBackground: false,
      isFirstTimeFacebookToastShow: false,
      composerType: 'common',
    }
  },
  computed: {
    defaultCommonSharingDetails() {
      return defaultCommonSharingDetails
    },
    ...mapGetters(['getWorkspaces']),
    /**
     * get state of image uploading
     * @returns {boolean}
     */
    getUploadingState() {
      return this.uploadingAiImages
    },
    getEditorSettings() {
      const twitterLimit = this.isTwitterPremiumSelected ? twitterPremiumPostLimit : twitterPostLimit;
      return {
        singleBox: {
          toolbar: {
            ...editorInitialValue.toolbar,
            location: this.getLocationStatus(),
            splitContent: this.accountSelection.twitter.length > 0,
          },
          twitterTextEnable: this.accountSelection.twitter.length > 0,
          maxLimit: this.getCharacterLimit('common'),
        },
        facebook: {
          toolbar: {
            ...editorInitialValue.toolbar,
            location: true,
          },
          maxLimit: facebookPostLimit,
        },
        instagram: {
          toolbar: {
            ...editorInitialValue.toolbar,
            location: true,
          },
          maxLimit: instagramPostLimit,
        },
        threads: {
          maxLimit: threadsPostLimit,
        },
        twitter: {
          toolbar: {
            ...editorInitialValue.toolbar,
            splitContent: true,
          },
          twitterTextEnable: true,
          maxLimit: twitterLimit,
        },
        linkedin: {
          toolbar: {
            ...editorInitialValue.toolbar,
          },
          maxLimit: linkedinPostLimit,
        },
        pinterest: {
          maxLimit: pinterestPostLimit,
        },
        gmb: {
          maxLimit: gmbPostLimit,
        },
        youtube: {
          toolbar: {
            ...editorInitialValue.toolbar,
          },
          maxLimit: youtubePostLimit,
        },
        tiktok: {
          maxLimit: tiktokPostLimit,
        },

        tumblr: {
          maxLimit: 0,
        },
        bluesky: {
          toolbar: {
            ...editorInitialValue.toolbar
          },
          maxLimit: blueskyPostLimit,
        },
      }
    },
    getAccountsData() {
      return {
        accountSelection: this.accountSelection,
        accountsList: this.accountsList,
        firstCommentAccounts: this.firstCommentAccountsList,
      }
    },
    isEmptyAccountSelection() {
      return (
        Object.entries(this.accountSelection).find(
          ([key, value]) => value.length > 0
        ) === undefined
      )
    },
    isThreadedTweetsAdded() {
      return (
        this.twitterOptions.has_threaded_tweets &&
        this.accountSelection.twitter.length > 0
      )
    },
    isMultiThreadsAdded() {
      return (
        this.threadsOptions.has_multi_threads &&
        this.accountSelection.threads.length > 0
      )
    },
    isBlueskyTweetAdded() {
      return (
          this.blueskyOptions.has_multi_bluesky &&
          this.accountSelection.bluesky.length > 0
      )
    },
    isLinkedinCarouselDisabled() {
      return (
        (this.linkedinOptions.document_added &&
          this.accountSelection?.linkedin?.length) ||
        this.accountSelection?.linkedin?.length < 1 ||
        (this.sharingDetails[
          this.commonBoxStatus
            ? 'common_sharing_details'
            : 'linkedin_sharing_details'
        ].image.length <= 1 &&
          !this.linkedinOptions.document_added)
      )
    },
    getSharingDetailsForCarousel() {
      return this.commonBoxStatus
        ? this.sharingDetails.common_sharing_details
        : this.sharingDetails.facebook_sharing_details
    },
    isCarouselPostAdded() {
      return (
        this.carouselAccounts.length > 0 &&
        this.carouselOptions.is_carousel_post
      )
    },
    firstCommentAccounts() {
      return this.accountsList.filter(
        (account) =>
          !commonMethods.isFacebookGroup(account) &&
          account.checked &&
          firstCommentChannels.includes(account.channel_type)
      )
    },
    twitterAccounts() {
      return this.accountsList.filter(
        (account) => account.channel_type === 'twitter' && account.checked
      )
    },
    isTwitterPremiumSelected() {
      if (!this.accountSelection || !Array.isArray(this.accountSelection.twitter) || this.accountSelection.twitter.length === 0) {
        return false;
      }
      return this.accountsList.filter(
        (account) => account.channel_type === 'twitter' && account.checked
      ).every(account =>
        ['premium', 'premiumplus', 'basic'].includes(account?.subscription_type?.toLowerCase())
      );
    },
    threadsAccounts() {
      return this.accountsList.filter(
        (account) => account.channel_type === 'threads' && account.checked
      )
    },
    blueskyAccounts() {
      return this.accountsList.filter(
          (account) => account.channel_type === 'bluesky' && account.checked
      )
    },
    isFacebookPageSelected() {
      let isFbGroupSelected = false
      this.getFacebookAccounts.items.forEach((account) => {
        if (
          account.type === 'Page' &&
          this.accountSelection.facebook.includes(account.facebook_id)
        ) {
          isFbGroupSelected = true
        }
      })
      EventBus.$emit('facebook-page-selected', isFbGroupSelected)
      return isFbGroupSelected
    },
    isFacebookGroupsSelected() {
      let isFbGroupSelected = false
      this.getFacebookAccounts?.items?.forEach((account) => {
        if (
          account.type === 'Group' &&
          this.accountSelection.facebook.includes(account.facebook_id)
        ) {
          isFbGroupSelected = true
        }
      })
      EventBus.$emit('facebook-group-selected', isFbGroupSelected)
      return isFbGroupSelected
    },
    showGmbSettings() {
      if (this.commonBoxStatus) {
        return this.accountSelection.gmb.length
      }
      return (
        this.accountSelection.gmb.length &&
        this.activeTabIndex === this.getTabIndexByName('gmb')
      )
    },
    showYoutubeSettings() {
      if (this.commonBoxStatus) {
        return this.accountSelection.youtube.length
      }
      return (
        this.accountSelection.youtube.length &&
        this.activeTabIndex === this.getTabIndexByName('youtube')
      )
    },
    showFacebookSettings() {
      return this.commonBoxStatus
        ? this.accountSelection.facebook?.length
        : this.accountSelection.facebook?.length &&
            this.activeTabIndex === this.getTabIndexByName('facebook')
    },
    showInstagramSettings() {
      if (this.commonBoxStatus) {
        return this.accountSelection.instagram.length
      }
      return (
        this.accountSelection.instagram.length &&
        this.activeTabIndex === this.getTabIndexByName('instagram')
      )
    },
    showTwitterThreads() {
      return (
        this.commonBoxStatus ||
        this.activeTabIndex === this.getTabIndexByName('twitter')
      )
    },
    showThreads() {
      return (
        this.commonBoxStatus ||
        this.activeTabIndex === this.getTabIndexByName('threads')
      )
    },
    showBluesky() {
      return (
          this.commonBoxStatus ||
          this.activeTabIndex === this.getTabIndexByName('bluesky')
      )
    },
    showTiktokSettings() {
      if (this.commonBoxStatus) {
        return this.accountSelection.tiktok.length
      }
      return (
        this.accountSelection.tiktok.length &&
        this.activeTabIndex === this.getTabIndexByName('tiktok')
      )
    },
    showCarousel() {
      return (
        this.commonBoxStatus ||
        this.activeTabIndex === this.getTabIndexByName('facebook')
      )
    },
    showFirstComment() {
      return (
        this.commonBoxStatus ||
        firstCommentChannels.includes(
          this.getTabNameByIndex(this.activeTabIndex)
        )
      )
    },
    activeGmbSharingDetail() {
      return this.commonBoxStatus
        ? this.sharingDetails.common_sharing_details
        : this.sharingDetails.gmb_sharing_details
    },
    activeFacebookSharingDetail() {
      return this.commonBoxStatus
        ? this.sharingDetails.common_sharing_details
        : this.sharingDetails.facebook_sharing_details
    },
    youtubePostType() {
      return this.youtubeOptions.post_type || 'video'
    },
    getLinkedinCarouselAccounts() {
      return this.accountsList?.filter(
        (account) => account.channel_type === 'linkedin' && account.checked
      )
    },
    getSelectedAccountsLocations() {
      return this.accountsList.filter(account => {
        if (this.composerType === 'common') {
          return this.accountSelection[account.channel_type]?.includes(account.instagram_id || account.facebook_id);
        } else if (this.composerType === 'facebook' || this.composerType === 'instagram') {
          // Assuming composerType would directly correlate with page_id for facebook and instagram
          const idToCheck = this.composerType === 'facebook' ? account.facebook_id : account.instagram_id;
          return this.accountSelection[this.composerType]?.includes(idToCheck);
        }
        return false;
      });
    },
    hasLocations() {

      if (this.activeTab === 'common') {
        return this.locations && Object.values(this.locations).some(location => location?.name);
      } else if (this.activeTab === 'facebook' || this.activeTab === 'instagram') {
        return this.locations && Object.values(this.locations).some(location =>
          location &&
          location.platform === this.activeTab &&
          location.name
        )
      }
      return false;
    }
  },
  watch: {
    isSeparateBoxes: function (val) {
      console.log('watch isSeparateBoxes', val)
      if (val === this.commonBoxStatus) {
        this.$emit('isSeparateBoxes', val)
      }
    },
    isEmptyAccountSelection: function (val) {
      console.debug('isEmptyAccountSelection', val)
      if (val) {
        this.isSeparateBoxes = false
        this.$emit('isSeparateBoxes', false)
      }
    },
    firstCommentAccounts: function (val) {
      if (val.length === 0 && this.firstComment.has_first_comment)
        this.toggleFirstComment()
    },
    twitterAccounts: function (val) {
      if (val.length === 0 && this.twitterOptions.has_threaded_tweets)
        this.toggleThreadedTweets()
    },
    threadsAccounts: function (val) {
      if (val.length === 0 && this.threadsOptions.has_multi_threads)
        this.toggleMultiThreads()
    },
    blueskyAccounts: function (val) {
      if (val.length === 0 && this.blueskyOptions.has_multi_bluesky)
        this.toggleMultiBluesky()
    },
    'twitterOptions.has_threaded_tweets': function (val) {
      if (!val) {
        const twitterOptions = clone(defaultTwitterOptions)
        this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
      }
    },
    'threadsOptions.has_multi_threads': function (val) {
        this.$emit('onChangeSharingOptions', 'threads', this.threadsOptions)
    },
    'blueskyOptions.has_multi_bluesky': function (val) {
      console.log( 'blueskyOptions.has_multi_bluesky', 'bluesky', this.blueskyOptions)
      this.$emit('onChangeSharingOptions', 'bluesky', this.blueskyOptions)
    },
    carouselAccounts: function (val) {
      if (val.length === 0 && this.carouselOptions.is_carousel_post)
        this.toggleCarouselPost()
    },
    'accountSelection.facebook': function (val) {
      if (this.accountSelection.facebook.length) {
        const details = this.commonBoxStatus ? this.sharingDetails.common_sharing_details : this.sharingDetails.facebook_sharing_details
        this.handleFacebookPostType(details)
      }
    },
  },
  mounted() {
    console.debug('MainComposer mounted ->  ', this.accountsList)
    this.initializeCrello()
    this.isSeparateBoxes = !this.commonBoxStatus
    this.instagramSelection = commonMethods.linkedInstagramAccountExist(
      this.accountSelection,
      this.accountsList
    )

    EventBus.$on(
      'add-media-from-media-library',
      ({ type, media, mediaType }) => {
        console.debug('add-media-from-media-library', type, media, mediaType)
        this.addMedia(type, media, mediaType)
      }
    )

    EventBus.$on('insert-chat-content', (aiContent) => {
      this.insertContentToComposer(aiContent)
    })

    EventBus.$on('replace-chat-content', (aiContent) => {
      this.insertContentToComposer(aiContent, 'replace')
    })

    EventBus.$on('sidebar-tab-change', () => {
      this.selectedToolbarOption = ''
    })

    // handing the instagram post type when we are on common or instagram tab
    this.$emit('setInstagramPostType', 'feed')
    if (this.accountSelection.instagram.length) {
      const details = this.commonBoxStatus
        ? this.sharingDetails.common_sharing_details
        : this.sharingDetails.instagram_sharing_details
      this.handleInstagramPostType(details)
    }

    if (this.accountSelection.facebook.length) {
      const details = this.commonBoxStatus ? this.sharingDetails.common_sharing_details : this.sharingDetails.facebook_sharing_details
      this.handleFacebookPostType(details)
    }

  },
  beforeUnmount() {
    EventBus.$off('add-media-from-media-library')
    EventBus.$off('insert-chat-content')
    EventBus.$off('sidebar-tab-change')
  },
  methods: {
    /**
     * handle the facebook background Id resetting
     */
    resetFacebookBackgroundId() {
      if (this.facebookBackgroundId) {
        this.$emit('set-facebook-background-id', '')
      }
    },
    handleProfileTagging(link, type) {
      this.$emit('profile-tagging', link, type)
    },
    /**
     * This method is used to handle the facebook post type
     * @param details
     */
    handleFacebookPostType(details) {
      if(this.isFacebookGroupsSelected && !this.isFacebookPageSelected) {
        this.$emit('set-facebook-post-type', 'feed')
        return
      }

      if(details?.message?.length > 0 && details?.multimedia?.length === 0) {
        this.$emit('set-facebook-post-type', 'feed')
        return
      }

      if(!details?.multimedia?.length ) {
        this.$emit('set-facebook-post-type', '')
        return
      }

      if(this.facebookPostType === 'story' && details.multimedia.length) {
        this.$emit('reset-facebook-carousel')
        this.$emit('set-facebook-post-type', 'story')
        return
      }

      if(details.multimedia.every(item => typeof item === 'string') ||
          (details?.multimedia?.length === 1 && typeof details.multimedia[0] === 'object')) {
          if(this.facebookPostType === 'reel') {
            return
          }
        this.$emit('set-facebook-post-type', 'feed')
        return
      }

      if(details?.multimedia?.length > 1) {
        this.$emit('reset-facebook-carousel')
        this.$emit('set-facebook-post-type', 'story')
        return
      }

    },
    handleInstagramPostType(details) {
      this.isInstagramVideoPost = false
      this.isInstagramSingleImagePost = false
      this.isInstagramCarouselPost = false

      if (!details?.multimedia?.length) {
        if(details?.image?.length || details?.video?.link){
          this.$emit('setInstagramPostType',this.instagramPostType)
          return
        }
        this.$emit('setInstagramPostType', '')
        return
      }

      if (details?.multimedia?.length) {
        if (this.instagramPostType === 'story') {
          this.isInstagramVideoPost =
            details.multimedia.length === 1 &&
            typeof details.multimedia[0] === 'object'
          this.isInstagramSingleImagePost =
            details.multimedia.length === 1 &&
            typeof details.multimedia[0] === 'string'
          this.isInstagramCarouselPost = details.multimedia.length >= 2
          this.$emit('setInstagramPostType', 'story')
          return
        }

        if (details.multimedia.length > 1) {
          this.isInstagramCarouselPost = true
          this.$emit('setInstagramPostType', 'carousel')
          return
        }

        if (typeof details.multimedia[0] === 'string') {
          this.$emit('setInstagramPostType', 'feed')
          this.isInstagramSingleImagePost = true
          return
        }

        if (
          details.multimedia.length === 1 &&
          typeof details.multimedia[0] === 'object' &&
          ['reel', 'feed_reel'].includes(this.instagramPostType)
        ) {
          this.$emit('setInstagramPostType', this.instagramPostType)
          this.isInstagramVideoPost = true
          return
        }

        if (
          details.multimedia.length === 1 &&
          typeof details.multimedia[0] === 'object'
        ) {
          this.$emit('setInstagramPostType', 'feed_reel')
          this.isInstagramVideoPost = true
        }
      } else {
        this.$emit('setInstagramPostType', 'feed')
      }
    },

    getTabIndexByName(name) {
      switch (name) {
        case 'facebook':
          return 0
        case 'instagram':
          return 1
        case 'threads':
          return 2
        case 'twitter':
          return 3
        case 'linkedin':
          return 4
        case 'pinterest':
          return 5
        case 'gmb':
          return 6
        case 'youtube':
          return 7
        case 'tiktok':
          return 8
        case 'tumblr':
          return 9
        case 'bluesky':
          return 10
        default:
          return 0
      }
    },

    getTabNameByIndex(index) {
      switch (index) {
        case 0:
          return 'facebook'
        case 1:
          return 'instagram'
        case 2:
          return 'threads'
        case 3:
          return 'twitter'
        case 4:
          return 'linkedin'
        case 5:
          return 'pinterest'
        case 6:
          return 'gmb'
        case 7:
          return 'youtube'
        case 8:
          return 'tiktok'
        case 9:
          return 'tumblr'
        case 10:
          return 'bluesky'
        default:
          return 'facebook'
      }
    },

    setActiveTab(name = 'twitter') {
      this.$refs.custom_box_tabs.setActiveTab(this.getTabIndexByName(name))
    },

    addSingleImage(type, imageUrl) {
      console.debug('addSingleImage', type, imageUrl)
      const sharingDetails = clone(
        this.sharingDetails[type + '_sharing_details']
      )
      if (this.isImagesHasGif(sharingDetails.image)) {
        this.alertMessage('You can only add one type of file.', 'error')
        return
      }
      if (sharingDetails.image.includes(imageUrl) === false) {
        switch (type) {
          case 'common':
            sharingDetails.image.push(imageUrl)
            sharingDetails.multimedia.push(imageUrl)
            break
          case 'facebook':
            sharingDetails.image.push(imageUrl)
            sharingDetails.multimedia.push(imageUrl)
            break
          case 'twitter':
            if (sharingDetails.image.length > 3) sharingDetails.image.pop()
            sharingDetails.image.push(imageUrl)
            break
          case 'instagram':
          case 'threads':
            if (sharingDetails.image.length > 9) sharingDetails.image.pop()
            sharingDetails.image.push(imageUrl)
            sharingDetails.multimedia.push(imageUrl)
            break
          case 'bluesky':
            if (sharingDetails.image.length > 4) sharingDetails.image.pop()
            sharingDetails.image.push(imageUrl)
            sharingDetails.multimedia.push(imageUrl)
            break
          case 'linkedin':
            if (sharingDetails.image.length > 8) sharingDetails.image.pop()
            sharingDetails.image.push(imageUrl)
            break
        }
        this.onChangeSharingDetails(sharingDetails, type)
      }
    },

    async composerClearContent() {
      // asking for confirmation
      const res = await this.$bvModal
        .msgBoxConfirm('Are you sure you want to clear all content?', {
          title: 'Clear all content',
          ...swalAttributes(),
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          console.error('clearContent', err)
          return false
        })

      if (res) {
        // resting box states
        this.$emit('composerResetSharingDetails')
      }
    },

    getCharacterLimit(type) {
      const limits = {
        twitter: twitterPostLimit,
        twitterPremium: twitterPremiumPostLimit,
        pinterest: pinterestPostLimit,
        threads: threadsPostLimit,
        gmb: gmbPostLimit,
        instagram: instagramPostLimit,
        linkedin: linkedinPostLimit,
        youtube: youtubePostLimit,
        tiktok: tiktokPostLimit,
        facebook: facebookPostLimit,
        bluesky: blueskyPostLimit
      };

      if (type === 'common') {

        const selectedPlatforms = Object.keys(this.accountSelection).filter(
          platform => this.accountSelection[platform].length > 0
        );

        if (selectedPlatforms.length === 0) return 0; // default if no platform selected

        return Math.min(
          ...selectedPlatforms.map(platform => {
            if (platform === 'twitter' && this.isTwitterPremiumSelected) {
              return limits.twitterPremium;
            }
            return limits[platform] || 5000; // default to 5000 if limit not found
          })
        );
      } else {
        // For specific platforms
        if (type === 'twitter' && this.isTwitterPremiumSelected) {
          return limits.twitterPremium;
        }
        return limits[type] || 5000; // default to 5000 if limit not found
      }
    },

    getLocationStatus() {
      return (
        this.accountSelection.facebook.length > 0 ||
        this.accountSelection.instagram.length > 0
      )
    },
    async handleUtmChanges(flag, type, data) {
      console.log('handleUtmChanges', flag, type, data)
      if (flag === 'fetch') {
        this.$emit('fetch-utms')
      }
      if (flag === 'apply') {
        const sharingDetails = {
          ...this.sharingDetails[type + '_sharing_details'],
        }

        if (sharingDetails.url && sharingDetails.url.trim().length > 0) {
          sharingDetails.message = sharingDetails.message.replace(
            sharingDetails.url,
            data[type]
          )
          sharingDetails.url = data[type]
        } else {
          const urls = this.getLinksFromText(sharingDetails.message)
          if (urls && urls.length > 0) {
            sharingDetails.message = sharingDetails.message.replace(
              urls[0],
              data[type]
            )
          } else {
            sharingDetails.message = sharingDetails.message + ' ' + data[type]
          }
          sharingDetails.url = data[type]
        }
        const urls = [data[type]]
        // for common
        if (type === 'common') {
          Object.entries(data).forEach((value) => {
            if (
              value[0] !== 'auto_add_social_channel' &&
              value[0] !== 'common'
            ) {
              sharingDetails[value[0] + '_url'] = value[1]
              if (data.auto_add_social_channel) urls.push(value[1])
            }
          })
        }
        await this.$emit('onChangeSharingDetails', type, sharingDetails)
        if (this.$el) {
          // When using refs with v-for, the component / DOM nodes are stored as an array, so we need to use the first element of the array.
          // https://github.com/vuejs/vue/issues/4952
          const editor =
            type === 'common'
              ? this.$refs[type + '-editor-box-ref']
              : this.$refs[type + '-editor-box-ref'] &&
                this.$refs[type + '-editor-box-ref'][0]
              ? this.$refs[type + '-editor-box-ref'][0]
              : null
          if (editor && editor.processEditorLinks) {
            editor.processEditorLinks(urls)
          }
        }
      }
    },

    async handleHashtagsChanges(type, flag, data, threadIndex = 0) {
      console.debug('handleHashtagsChanges', type, flag, data, threadIndex)
      const sharingDetails = clone(
        this.sharingDetails[type + '_sharing_details']
      )
      if (this.validateHashtag(data)) {
        // to save new hashtag
        if (flag === 'save') {
          data.workspace_id = this.getWorkspaces.activeWorkspace._id
          proxy
            .post(saveHashTagUrl, data)

            .then((res) => {
              if (res.data.status) {
                this.$store.commit('ADD_WORKSPACE_HASHTAG', res.data.hashtag)
                this.$store.dispatch('toastNotification', {
                  message: HASHTAG_SAVE_SUCCESS,
                  type: 'success',
                })
              } else {
                this.$store.dispatch('toastNotification', {
                  message: res.data.message,
                  type: 'error',
                })
              }
            })
            .catch((e) => {
              console.error('Save Hashtags =>', e)
              return false
            })
        }
        // to apply hashtag
        // check where to apply

        if (type === 'threaded-tweet') {
          if (
            this.twitterOptions.threaded_tweets &&
            this.twitterOptions.threaded_tweets[threadIndex]
          ) {
            const thread = clone(
              this.twitterOptions.threaded_tweets[threadIndex]
            )
            if (data.position === 'Append')
              thread.message = hashtag.appendHashTag(thread.message, data)
            else thread.message = hashtag.replaceHashTag(thread.message, data)
            this.onChangeThreadDetails(thread, threadIndex)
            return true
          }
        } else if (type === 'bluesky-tweet') {
          if (
              this.blueskyOptions.multi_bluesky &&
              this.blueskyOptions.multi_bluesky[threadIndex]
          ) {
            const bluesky = clone(
                this.blueskyOptions.multi_bluesky[threadIndex]
            )
            if (data.position === 'Append')
              bluesky.message = hashtag.appendHashTag(bluesky.message, data)
            else bluesky.message = hashtag.replaceHashTag(bluesky.message, data)
            this.onChangeBlueskyDetails(bluesky, threadIndex)
            return true
          }
        } else if (type === 'multi-threads') {
          if (
            this.threadsOptions.multi_threads &&
            this.threadsOptions.multi_threads[threadIndex]
          ) {
            const thread = clone(this.threadsOptions.multi_threads[threadIndex])
            if (data.position === 'Append')
              thread.message = hashtag.appendHashTag(thread.message, data)
            else thread.message = hashtag.replaceHashTag(thread.message, data)
            this.onChangeMultiThreadsDetails(thread, threadIndex)
            return true
          }
        } else if (type === 'threads') {
          if (
            this.threadsOptions.multi_threads &&
            this.threadsOptions.multi_threads[threadIndex]
          ) {
            const threads = clone(
              this.threadsOptions.multi_threads[threadIndex]
            )
            if (data.position === 'Append')
              threads.message = hashtag.appendHashTag(threads.message, data)
            else threads.message = hashtag.replaceHashTag(threads.message, data)
            this.onChangeMultiThreadsDetails(threads, threadIndex)
            return true
          }
        }
        else if (type === 'bluesky') {
        if (
            this.blueskyOptions.multi_bluesky &&
            this.blueskyOptions.multi_bluesky[threadIndex]
        ) {
          const threads = clone(
              this.blueskyOptions.multi_bluesky[threadIndex]
          )
          if (data.position === 'Append')
            threads.message = hashtag.appendHashTag(threads.message, data)
          else threads.message = hashtag.replaceHashTag(threads.message, data)
          this.onChangeBlueskyDetails(threads, threadIndex)
          return true
        }
      } else if (type === 'first-comment') {
          const firstComment = clone(this.firstComment)
          if (data.position === 'Append')
            firstComment.first_comment_message = hashtag.appendHashTag(
              firstComment.first_comment_message,
              data
            )
          else
            firstComment.first_comment_message = hashtag.replaceHashTag(
              firstComment.first_comment_message,
              data
            )
          this.handleFirstCommentChange(firstComment)
          return true
        } else {
          if (data.position === 'Append') {
            sharingDetails.message = hashtag.appendHashTag(
              sharingDetails.message,
              data
            )
          } else {
            sharingDetails.message = hashtag.replaceHashTag(
              sharingDetails.message,
              data
            )
          }
          this.onChangeSharingDetails(sharingDetails, type)
          return true
        }
      } else {
        return false
      }
    },

    validateHashtag(data) {
      if (data.name.trim() === '') {
        this.$store.dispatch('toastNotification', {
          message: INVALID_NAME_HASHTAG,
          type: 'error',
        })
        return false
      } else if (data.items.length < 1) {
        this.$store.dispatch('toastNotification', {
          message: NO_HASHTAG,
          type: 'error',
        })
        return false
      } else if (data.count < 1) {
        this.$store.dispatch('toastNotification', {
          message: INVALID_COUNT + ' ' + data.items.length,
          type: 'error',
        })
        return false
      } else if (data.items.length > 0 && data.count > data.items.length) {
        this.$store.dispatch('toastNotification', {
          message: INVALID_COUNT + ' ' + data.items.length,
          type: 'error',
        })
        return false
      }
      return true
    },

    async handleReplugChanges(type, data) {
      // accountSelection keys used just to iterate over platform names
      const sharingDetails = {
        ...this.sharingDetails[type + '_sharing_details'],
      }
      const links = [data.link]
      let replugLinks = []
      if (type === 'common') {
        Object.keys(this.accountSelection).forEach((key) => {
          if (key !== 'tiktok') links.push(sharingDetails[key + '_url'])
        })
        // ! tiktok_url is not in common details
      }
      this.replugLoader = true
      await proxy
        .post(fetchReplugLinksUrl, {
          links,
          campaign: data.campaign,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        })
        .then((response) => {
          if (response.data.status) {
            replugLinks = response.data.links
            this.replugLoader = false
          } else {
            this.alertMessage(response.data.message, 'error')
            this.replugLoader = false
          }
        })
        .catch((error) => {
          console.error(error)
          this.replugLoader = false
        })

      // this  block will find and replace the link in message and url both for common and individual platforms
      if (replugLinks && replugLinks.length > 0) {
        replugLinks.forEach((link) => {
          if (
            link.original_link &&
            sharingDetails.message.indexOf(link.original_link) > -1
          ) {
            sharingDetails.message = sharingDetails.message.replace(
              link.original_link,
              link.replug_link
            )
          }
          if (link.original_link === sharingDetails.url) {
            sharingDetails.url = link.replug_link
          }

          if (type === 'common') {
            Object.keys(this.accountSelection).forEach((key) => {
              if (key !== 'tiktok') {
                if (link.original_link === sharingDetails[key + '_url']) {
                  sharingDetails[key + '_url'] = link.replug_link
                }
              }
            })
            // ! tiktok_url is not in common details
          }
        })
      }

      this.replugLoader = false

      this.onChangeSharingDetails(sharingDetails, type)
    },

    disabledBeforeTodayAndAfterAWeek: function (date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return (
        date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000)
      )
    },

    /** Handle First Comment Change
     * @param {object} firstComment - first comment object
     */
    handleFirstCommentChange: function (firstComment) {
      console.debug('firstCommentChange', firstComment)
      this.$emit('onChangeFirstComment', clone(firstComment))
    },

    toggleFirstComment: function () {
      const firstComment = { ...this.firstComment }
      firstComment.has_first_comment = !this.firstComment.has_first_comment
      this.$emit('onChangeFirstComment', firstComment)
    },
    toggleThreadedTweets: function () {
      const twitterOptions = clone(this.twitterOptions)
      twitterOptions.has_threaded_tweets =
        !this.twitterOptions.has_threaded_tweets
      this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
    },
    toggleMultiThreads: function () {
      const threadsOptions = clone(this.threadsOptions)
      threadsOptions.has_multi_threads = !this.threadsOptions.has_multi_threads
      this.$emit('onChangeSharingOptions', 'threads', threadsOptions)
    },
    toggleMultiBluesky: function () {
      const blueskyOptions = clone(this.blueskyOptions)
      blueskyOptions.has_multi_bluesky = !this.blueskyOptions.has_multi_bluesky
      this.$emit('onChangeSharingOptions', 'bluesky', blueskyOptions)
    },
    async handleSplitTweetContent() {
      const boxType = this.activeTab
      const content = this.sharingDetails[boxType + '_sharing_details'].message
      const sharingDetails = clone(this.sharingDetails.twitter_sharing_details)
      let res = false
      const tweets = []
      let thread = content
      // eslint-disable-next-line no-useless-escape
      const urlPattern =
        /(http(s)?:\/\/)?(([a-zA-Z0-9])([-\w]*\.)+([^\s\.]+[^\s]*)+[^,.\s])/u
      const twitterOptions = clone(defaultTwitterOptions)
      if (boxType === 'common') {
        res = await this.$root.$bvModal.msgBoxConfirm(
          'In order to automatically split long content into multiple threaded tweets, we would need to enable “customize content” for each social network. Would you like to continue?',
          {
            title: 'Close Composer',
            ...swalAttributes(),
            cancelTitle: 'Cancel',
            okTitle: 'Yes Split',
          }
        )
        if (!res) return
      }
      // replace URL with 23 char and then split by full-stop if found
      while (thread.length > 0) {
        if (
          thread.replace(urlPattern, 'http://8901234567890123').length <= 280
        ) {
          break
        }
        if (
          thread
            .replace(urlPattern, 'http://8901234567890123')
            .includes('. ', 180) &&
          thread
            .replace(urlPattern, 'http://8901234567890123')
            .lastIndexOf('. ', 280) !== -1
        ) {
          let length = 280
          if (thread.slice(0, 280).match(urlPattern)) {
            const temp = thread.slice(0, 280).match(urlPattern)[0].length
            if (temp > 23) {
              length += temp - 23
            }
          }
          const index = thread.lastIndexOf('. ', length)
          tweets.push(thread.slice(0, index) + '.')
          thread = thread.slice(index + 2)
        } else {
          const index = thread.lastIndexOf(' ', 278)
          tweets.push(thread.slice(0, index) + ' ')
          thread = thread.slice(index + 1)
        }
      }
      tweets.push(thread)
      // now add splited tweets to twitterOptions
      tweets.forEach((tweet, index) => {
        if (index === 0) sharingDetails.message = tweet
        else {
          if (!twitterOptions.threaded_tweets[index - 1])
            twitterOptions.threaded_tweets[index - 1] = clone(
              defaultTwitterSharingDetails
            )
          twitterOptions.threaded_tweets[index - 1].message = tweet
        }
      })
      // add previous tweet
      if (
        this.twitterOptions.threaded_tweets.length > 0 &&
        this.twitterOptions.threaded_tweets[0].message !== ''
      ) {
        this.twitterOptions.threaded_tweets.forEach((item) =>
          twitterOptions.threaded_tweets.push(item)
        )
      }
      twitterOptions.has_threaded_tweets = true
      await this.$emit('isSeparateBoxes', true, true)
      // remove url if first tweet doesn't include URl
      if (
        this.sharingDetails[boxType + '_sharing_details'].url &&
        this.getLinksFromText(sharingDetails.message) === null
      ) {
        sharingDetails.url = ''
        sharingDetails.image = []
      }
      this.isSeparateBoxes = true
      this.setActiveTab('twitter')
      if (boxType === 'common') {
        const images = this.sharingDetails.common_sharing_details.image
        sharingDetails.image = images.length > 3 ? images.slice(0, 4) : images
        sharingDetails.video = this.sharingDetails.common_sharing_details.video
      }
      this.onChangeSharingDetails(sharingDetails, 'twitter')
      this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
    },

    splitString(str) {
      const maxLength = 280
      const pattern = ' '
      const parts = []
      while (str.length > maxLength) {
        const index = str.lastIndexOf(pattern, maxLength)
        if (index === -1) {
          // If there are no spaces within the maxLength limit, split at the limit
          parts.push(str.slice(0, maxLength))
          str = str.slice(maxLength)
        } else {
          // Otherwise, split at the last space before the limit
          parts.push(str.slice(0, index))
          str = str.slice(index + 1)
        }
      }
      parts.push(str)
      return parts
    },

    toggleCarouselPost: function () {
      const carouselOptions = clone(this.carouselOptions)
      carouselOptions.is_carousel_post = !this.carouselOptions.is_carousel_post
      this.$emit('onChangeSharingOptions', 'carousel', carouselOptions)
    },

    /**
     * Invoked when an instagram account is selected. calculated the instagram warnings and api errors.
     */
    updateInstagramSelection() {
      console.log('METHOD::updateInstagramSelection')
      this.instagramSelection = commonMethods.linkedInstagramAccountExist(
        this.accountSelection,
        this.accountsList
      )

      // In case of changing instagram account we need to update its post type
      if (this.accountSelection.instagram.length) {
        const details = this.commonBoxStatus
            ? this.sharingDetails.common_sharing_details
            : this.sharingDetails.instagram_sharing_details
        this.handleInstagramPostType(details)
      }

    },

    isAccountSelected(type) {
      if (this.accountSelection[type]) {
        return this.accountSelection[type].length > 0
      }
      return false
    },

    /** Event handlers on Editor Box Text Change
     * @param type {string} type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param message {string} message
     * @param characterCount
     */
    onEditorBoxTextChange: function (type, message, characterCount) {
      console.debug('onEditorBoxTextChange', type, characterCount, message)
      this.characterCount = characterCount
      this.editorsCharacterCount[type] = characterCount
      const sharingDetails = {
        ...this.sharingDetails[type + '_sharing_details'],
      }
      sharingDetails.message = message
      this.handleFacebookBackgroundToast()
      console.log('sharing Details :: ', this.sharingDetails)
      this.onChangeSharingDetails(sharingDetails, type)
    },
    /**
     * Handling the facebook background post toast message
     */
    handleFacebookBackgroundToast() {
      // Cache reused values
      const {
        isFirstTimeToastShow,
        facebookPostType,
        activeFacebookSharingDetail,
        carouselOptions,
        isFacebookPageSelected,
        facebookBackgroundId,
        characterCount,
        isToastDisplayedForFacebookBackground,
      } = this

      // Check if it's the first time toast is shown
      if (!isFirstTimeToastShow) {
        this.isFirstTimeToastShow = true
        return
      }

      // Check if all conditions for displaying toast are met
      if (
        facebookPostType !== 'feed' ||
        activeFacebookSharingDetail.image.length ||
        activeFacebookSharingDetail.video.thumbnail.length ||
        carouselOptions.is_carousel_post ||
        !isFacebookPageSelected ||
        !facebookBackgroundId
      ) {
        return
      }

      // Check character count condition
      if (!isToastDisplayedForFacebookBackground && characterCount > 130) {
        // Display toast
        this.isToastDisplayedForFacebookBackground = true
        this.$store?.dispatch('toastNotification', {
          message:
            'Facebook Post Background can only be applied with a maximum character count of 130',
          type: 'warn',
        })
      } else {
        // Update toast display status
        this.isToastDisplayedForFacebookBackground = characterCount > 130
      }
    },
    /** Event handlers on change sharing details
     * @param value details
     * @param type platform type
     */
    onChangeSharingDetails: function (value, type) {
      console.debug('Method::onChangeSharingDetails MainComposer ', value, type)
      this.$emit('onChangeSharingDetails', type, value)
      if (this.accountSelection.instagram.length && ['common', 'instagram'].includes(type)) {

        const details = this.commonBoxStatus
            ? this.sharingDetails.common_sharing_details
            : this.sharingDetails.instagram_sharing_details

        this.handleInstagramPostType(details)
      }
      if (this.accountSelection.facebook.length && ['common', 'facebook'].includes(type)) {

        const details = this.commonBoxStatus
            ? this.sharingDetails.common_sharing_details
            : this.sharingDetails.facebook_sharing_details

        this.handleFacebookPostType(details)
      }
    },

    /**
     * Add Document to Editor
     * @param type type of editor (common, facebook etc.)
     * @param document document
     */

    addDocument(type, document) {
      console.debug('addDocument', type, document)
      if (!(type === 'linkedin' || type === 'common')) {
        return
      }
      const linkedinOptions = clone(this.linkedinOptions)
      linkedinOptions.is_carousel = true
      linkedinOptions.title = document.name || ''
      linkedinOptions.document_added = true
      linkedinOptions.document = document
      this.$emit('onChangeSharingOptions', 'linkedin', linkedinOptions)
    },

    /** Add media to editor
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param media media
     * @param mediaType media type (image, video, etc)
     */
    addMedia(type, media, mediaType) {
      let sharingDetails = clone(this.sharingDetails[type + '_sharing_details'])

      // check if media is not pdf
      if (mediaType !== 'pdf') {
        media = media?.map((item) => {
          if (item.converted_mime_type === 'video/mp4') {
            return {
              ...item,
              title: '',
            }
          }
          return item
        })
      }
      console.debug('addMedia', type, media, mediaType, sharingDetails)

      // In case of uploading media we will reset the facebook background ID
      this.resetFacebookBackgroundId()

      // remove link preview if it is added
      sharingDetails = this.removeLinkPreview(
        type,
        false,
        false,
        sharingDetails
      )
      // linkedin add pdf handling
      if (mediaType === 'pdf') {
        sharingDetails.video = videoDefaultSharingDetails
        sharingDetails.image = []
        sharingDetails.multimedia = []
        this.onChangeSharingDetails(sharingDetails, type)
        this.addDocument(type, media)
        return
      }

      if (
        this.linkedinOptions?.document_added &&
        (type === 'linkedin' || type === 'common')
      ) {
        const linkedinOptions = clone(this.linkedinOptions)
        linkedinOptions.is_carousel = false
        linkedinOptions.document_added = false
        linkedinOptions.document = null
        this.$emit('onChangeSharingOptions', 'linkedin', linkedinOptions)
      }
      if (mediaType === 'image') {
        this.addImages(type, sharingDetails, media)
      } else if (mediaType === 'video') {
        this.addVideo(type, sharingDetails, media)
      } else if (mediaType === 'multimedia') {
        this.addMultimedia(type, sharingDetails, media, 'multimedia')
      }
    },
    /**
     * handleing the media sorting
     * @param type
     * @param mediaType
     * @param media
     */
    handleSortMedia(type, mediaType, media) {
      const details = clone(this.sharingDetails[type + '_sharing_details'])
      details[mediaType] = media
      if (type === 'common') {
        this.handleOtherSocialChannelMedia(details)
      }
      this.onChangeSharingDetails(details, type)
    },
    /** Add images to editor
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param sharingDetails sharing details
     * @param images array
     */
    addImages(type, sharingDetails, images) {
      console.debug('addImages', type, sharingDetails, images)
      // if type other than common or instagram then we will reset the video
      if (!this.isTypeCommonOrInstagram(type)) {
        sharingDetails.video = videoDefaultSharingDetails
      }

      switch (type) {
        case 'common':
        case 'facebook':
        case 'linkedin':
        case 'tiktok':
          sharingDetails.image = images
          break
        case 'twitter':
        case 'threaded-tweet':
          sharingDetails.image = images.length > 3 ? images.slice(0, 4) : images
          break
        case 'instagram':
          sharingDetails.image =
            images.length > 10 ? images.slice(0, 10) : images
          break
        case 'pinterest':
        case 'tumblr':
        case 'gmb':
          sharingDetails.image = images.length > 1 ? images.slice(0, 1) : images
        case 'bluesky':
          sharingDetails.image = images.length > 1 ? images.slice(0, 4) : images
        case 'bluesky-tweet':
          sharingDetails.image = images.length > 1 ? images.slice(0, 4) : images
          break
      }
      this.onChangeSharingDetails(sharingDetails, type)
    },

    /** Add video to editor
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param sharingDetails sharing details
     * @param video video object
     */
    addVideo(type, sharingDetails, video) {
      console.log('addVideo', sharingDetails, video)

      if (this.isTypeCommonOrInstagram(type)) {
        this.addMultimedia(type, sharingDetails, video, 'video')
      } else {
        sharingDetails.image = []
      }
      // Add a condition: if the activeTab is 'common' and 'linkedin', then set false; otherwise, leave it unchanged
      if (this.activeTab === 'common' || this.activeTab === 'linkedin') {
      this.linkedinOptions.is_carousel = false // off linkedin carousel if video is added
      }
      sharingDetails.video = video[0]
      this.onChangeSharingDetails(sharingDetails, type)
    },
    /**
     * Add multimedia to editor
     * @param type
     * @param sharingDetails
     * @param media
     * @param mediaType
     */
    addMultimedia(type, sharingDetails, media, mediaType) {
      console.debug('addMultimedia before', type, sharingDetails, media)

      // If we are uploading from media library then we will directly push to multimedia array
      if (mediaType === 'multimedia' && sharingDetails?.multimedia) {
        sharingDetails.multimedia = sharingDetails.multimedia.concat(media)
        this.handleOtherSocialChannelMedia(sharingDetails)
        this.onChangeSharingDetails(sharingDetails, type)
        return
      }

      // for old plans we need to set the multimedia if user trying to upload multimedia
      if (
        this.isTypeCommonOrInstagram(type) &&
        !sharingDetails.multimedia &&
        sharingDetails?.image?.length > 0
      ) {
        sharingDetails.multimedia = []
        sharingDetails.multimedia = sharingDetails.image
        sharingDetails.multimedia.push(...media)
        this.onChangeSharingDetails(sharingDetails, type)
        return
      }

      // for old plans we need to set the multimedia if user trying to upload multimedia
      if (
        this.isTypeCommonOrInstagram(type) &&
        !sharingDetails.multimedia &&
        sharingDetails?.video?.link
      ) {
        sharingDetails.multimedia = []
        sharingDetails.multimedia.push(sharingDetails.video)
        sharingDetails.multimedia.push(...media)
        this.onChangeSharingDetails(sharingDetails, type)
        return
      }

      let list = sharingDetails?.multimedia?.length
        ? sharingDetails.multimedia
        : []
      list = list.concat(media)
      sharingDetails.multimedia = list
      this.handleOtherSocialChannelMedia(sharingDetails)
      this.onChangeSharingDetails(sharingDetails, type)
      console.log('addMultimedia after', sharingDetails.multimedia)
    },
    /**
     * This method is responsible for to handle media for other social channels
     * @param sharingDetails
     */
    handleOtherSocialChannelMedia(sharingDetails) {
      if (
        sharingDetails?.multimedia &&
        typeof sharingDetails.multimedia[0] === 'object'
      ) {
        sharingDetails.video = sharingDetails.multimedia[0]
        sharingDetails.image = []
      }

      if (
        sharingDetails?.multimedia &&
        typeof sharingDetails.multimedia[0] === 'string'
      ) {
        sharingDetails.image = sharingDetails.multimedia.filter(
          (media) => typeof media === 'string'
        )
        sharingDetails.video = videoDefaultSharingDetails
      }
    },
    /** handle Sharing Link event
     * @param type
     * @param link
     */
    handleSharingLink(type, link) {
      console.debug('handleSharingLink', type, link)
      const sharingDetails = clone(
        this.sharingDetails[type + '_sharing_details']
      )
      switch (type) {
        case 'common':
          sharingDetails.url =
            sharingDetails.facebook_url =
            sharingDetails.twitter_url =
            sharingDetails.linkedin_url =
              link
          sharingDetails.instagram_url =
            sharingDetails.pinterest_url =
            sharingDetails.tumblr_url =
            sharingDetails.gmb_url =
            sharingDetails.bluesky_url =
              link
          break
        case 'facebook':
        case 'twitter':
        case 'threadedTweets':
        case 'linkedin':
        case 'instagram':
        case 'pinterest':
        case 'tumblr':
        case 'gmb':
        case 'bluesky':
          sharingDetails.url = link
          break
      }
      this.onChangeSharingDetails(sharingDetails, type)
    },

    /** Set link preview
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param preview link preview object
     */
    setLinkPreview(type, preview) {
      console.debug('setLinkPreview', type, preview)

      // In case of uploading media we will reset the facebook background ID
      this.resetFacebookBackgroundId()

      const sharingDetails = clone(
        this.sharingDetails[type + '_sharing_details']
      )
      switch (type) {
        case 'common':
          // using same images if images are already added
          if (sharingDetails.image.length) {
            preview.image = sharingDetails.image
          }
          sharingDetails.title = preview.title
          sharingDetails.description = preview.description
          sharingDetails.image = preview.image
          sharingDetails.website = preview.website
          sharingDetails.image_suggestions = preview.image_suggestions
          sharingDetails.twitter_title = preview.twitter_title
          sharingDetails.twitter_description = preview.twitter_description
          sharingDetails.twitter_image = preview.twitter_image
          sharingDetails.twitter_website = preview.twitter_website
          sharingDetails.multimedia = preview.image
          break
        case 'twitter':
        case 'threadedTweets':
          // using same images if images are already added
          if (sharingDetails.image.length) {
            preview.twitter_image = sharingDetails.image
          }
          sharingDetails.title = preview.twitter_title
          sharingDetails.description = preview.twitter_description
          sharingDetails.image = preview.twitter_image
          sharingDetails.website = preview.twitter_website
          sharingDetails.image_suggestions = preview.image_suggestions
          break
        case 'facebook':
        case 'linkedin':
        case 'threads':
        case 'bluesky':
          // using same images if images are already added
          if (sharingDetails.image.length) {
            preview.image = sharingDetails.image
          }
          sharingDetails.title = preview.title
          sharingDetails.description = preview.description
          sharingDetails.image = preview.image
          sharingDetails.website = preview.website
          sharingDetails.image_suggestions = preview.image_suggestions
          break
        case 'instagram':
          if (sharingDetails.image.length === 0) {
            sharingDetails.image = preview.image
            sharingDetails.multimedia = preview.image
          }
          break
        case 'pinterest':
        case 'tumblr':
        case 'gmb':
          if (sharingDetails.image.length === 0) {
            sharingDetails.image = [].concat(preview.singleImage)
          }
          break
      }
      this.onChangeSharingDetails(sharingDetails, type)
    },

    /** Remove link preview
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param removeImage remove link preview image from details (true/false) default false
     * @param triggerEvent trigger event to parent component (true/false) default true
     * @param details sharing details object default null
     */
    removeLinkPreview: function (
      type,
      removeImage = false,
      triggerEvent = true,
      details = null
    ) {
      details = details || clone(this.sharingDetails[type + '_sharing_details'])
      switch (type) {
        case 'common':
          details.website =
            details.title =
            details.description =
            details.twitter_title =
            details.twitter_description =
            details.twitter_website =
              ''
          details.url =
            details.facebook_url =
            details.twitter_url =
            details.instagram_url =
            details.linkedin_url =
            details.youtube_url =
            details.pinterest_url =
            details.gmb_url =
            details.threads_url =
            details.tumblr_url =
            details.bluesky_url =
              ''
          details.twitter_image = []
          break
        case 'twitter':
        case 'threaded-tweet':
        case 'facebook':
        case 'linkedin':
        case 'instagram':
        case 'tumblr':
        case 'gmb':
        case 'pinterest':
        case 'youtube':
        case 'threads':
        case 'bluesky':
        case 'bluesky-tweet':
        case 'tiktok':
          details.title =
            details.description =
            details.website =
            details.url =
              ''
          break
      }
      if (removeImage) {
        details.image = []
        if (details?.multimedia) {
          details.multimedia = []
        }
      }
      if (triggerEvent) {
        this.onChangeSharingDetails(details, type)
      }
      return details
    },

    /**
     * handle remove media
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param mediaType mediaType string (image, video)
     * @param media media to remove object
     * @param removeAll remove all images (true/false) default false
     */
    handleRemoveMedia: function (
      type,
      mediaType,
      media,
      removeAll = false,
      index = -1
    ) {
      console.debug('Method:removeMediaAsset-v2', type, mediaType, media, index)
      if (mediaType === 'image') {
        this.removeImage(type, media, removeAll)
      } else if (mediaType === 'video') {
        this.removeVideo(type, media)
      } else if (mediaType === 'document') {
        const linkedinOptions = clone(this.linkedinOptions)
        linkedinOptions.is_carousel = false
        linkedinOptions.document_added = false
        linkedinOptions.document = null
        linkedinOptions.title = ''
        this.$emit('onChangeSharingOptions', 'linkedin', linkedinOptions)
      } else if (mediaType === 'multimedia') {
        this.removeMultimedia(type, media, index)
      }

      // For we will update the instagram post type on media removing
      if (['common', 'instagram'].includes(type)) {
        this.handleInstagramPostType({
          ...this.sharingDetails[type + '_sharing_details'],
        })
      }

      if(['common', 'facebook'].includes(type)) {
        this.handleFacebookPostType({ ...this.sharingDetails[type + '_sharing_details'] })
      }

    },
    /**
     * This method is responsible for removing the media from multimedia array
     * @param type
     * @param media
     * @param index
     */
    removeMultimedia(type, media, index) {
      const details = { ...this.sharingDetails[type + '_sharing_details'] }
      details.multimedia.splice(index, 1)
      this.handleOtherSocialChannelMedia(details)

      if (details.multimedia.length === 0) {
        details.video = videoDefaultSharingDetails
        details.image = []
      }

      if (typeof media === 'string' && details.image_tagging_details?.[media]) {
        delete details.image_tagging_details[media]
      }

      if (typeof media === 'string' && details.alt_texts?.length) {
        details.alt_texts = details.alt_texts.filter(
          (altText) => altText.image !== media
        )
      }

      this.onChangeSharingDetails(details, type)
    },
    /** Remove video from editor
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     */
    removeVideo: function (type) {
      const details = clone(this.sharingDetails[type + '_sharing_details'])
      details.video = videoDefaultSharingDetails
      this.onChangeSharingDetails(details, type)
    },
    /** Remove image from editor media box
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param image image to remove
     * @param removeAll remove all images (true/false) default false
     */
    removeImage: function (type, image, removeAll = false) {
      const details = clone(this.sharingDetails[type + '_sharing_details'])
      let index = -1
      if (details && details.image && details.image.length > 0 && !removeAll) {
        // setTimeout(function () {
        switch (type) {
          case 'common':
          case 'facebook':
          case 'instagram':
          case 'twitter':
          case 'threadedTweets':
          case 'linkedin':
          case 'tiktok':
          case 'bluesky':
            index = details.image.indexOf(image)
            if (index > -1) {
              details.image.splice(index, 1)
              if (
                details.image_tagging_details &&
                typeof details.image_tagging_details === 'object' &&
                details.image_tagging_details[image]
              ) {
                delete details.image_tagging_details[image]
              }
            }
            break
          case 'pinterest':
          case 'tumblr':
          case 'gmb':
          case 'youtube':
            details.image = []
            details.url = ''
            break
        }
      } else if (removeAll) {
        details.image = []
        details.image_tagging_details = {}
      }
      this.onChangeSharingDetails(details, type)

      if (
        (type === 'common' || type === 'linkedin') &&
        details.image.length < 2
      ) {
        this.linkedinOptions.is_carousel = false // off LinkedIn carousel if image are less than 2
      }
    },

    /** Initialize video upload from editor
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     */
    initializeVideoUpload: function (type) {
      let sharingDetails = clone(this.sharingDetails[type + '_sharing_details'])
      // remove link preview if it is added
      // For instagram carosuel post we will not remove the media
      const isRemoveImage = this.isTypeCommonOrInstagram(type)
      sharingDetails = this.removeLinkPreview(
        type,
        !isRemoveImage,
        false,
        sharingDetails
      )
      sharingDetails.video = videoDefaultSharingDetails
      sharingDetails.image_suggestions = []
      this.onChangeSharingDetails(sharingDetails, type)
    },

    onChangeThreadDetails(threadDetails, index) {
      console.debug(
        'Method:onChangeThreadDetails MainComposer',
        threadDetails,
        index
      )
      const twitterOptions = { ...this.twitterOptions }
      twitterOptions.threaded_tweets[index] = threadDetails
      this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
    },

    onChangeBlueskyDetails(details, index) {
      console.debug(
          'Method:onChangeBlueskyDetails MainComposer',
          details,
          index
      )
      const blueskyOptions = { ...this.blueskyOptions }
      blueskyOptions.multi_bluesky[index] = details
      this.$emit('onChangeSharingOptions', 'bluesky', blueskyOptions)
    },

    onChangeMultiThreadsDetails(threadsDetails, index) {
      console.debug('Method:onChangeMultiThreadsDetails MainComposer')
      const threadsOptions = { ...this.threadsOptions }
      threadsOptions.multi_threads[index] = threadsDetails
      this.$emit('onChangeSharingOptions', 'threads', threadsOptions)
    },

    addThreadedTweet() {
      console.debug(
        'Method:addThreadedTweet MainComposer',
        JSON.stringify(defaultTwitterSharingDetails)
      )
      const twitterOptions = { ...this.twitterOptions }
      twitterOptions.threaded_tweets.push({ ...defaultTwitterSharingDetails })
      this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
    },

    addBlueskyTweet() {
      console.debug(
          'Method:addBlueskyTweet MainComposer',
          JSON.stringify(defaultBlueskySharingDetails)
      )
      const blueskyOptions = { ...this.blueskyOptions }
      blueskyOptions.multi_bluesky.push({ ...defaultBlueskySharingDetails })
      this.$emit('onChangeSharingOptions', 'bluesky', blueskyOptions)
    },

    addMultiThreads() {
      const threadsOptions = { ...this.threadsOptions }
      threadsOptions.multi_threads.push({ ...defaultThreadsSharingDetails })
      this.$emit('onChangeSharingOptions', 'threads', threadsOptions)
    },

    removeThreadedTweet(index) {
      console.debug('Method:removeThreadedTweet MainComposer', index)
      const twitterOptions = { ...this.twitterOptions }
      if (twitterOptions.threaded_tweets.length === 1) {
        twitterOptions.has_threaded_tweets = false
        this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
        return
      }
      twitterOptions.threaded_tweets.splice(index, 1)
      this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
    },

    removeBlueskyTweet(index) {
      console.debug('Method:removeBlueskyTweet MainComposer', index)

      if(this.blueskyOptions.multi_bluesky.length === 1) {
        this.$emit('onChangeSharingOptions', 'bluesky', {
          has_multi_bluesky: false,
          multi_bluesky: [{ ...defaultBlueskySharingDetails }]
        })
        return
      }

      const blueskyOptions = { ...this.blueskyOptions }
      blueskyOptions.multi_bluesky.splice(index, 1)
      this.$emit('onChangeSharingOptions', 'bluesky', blueskyOptions)
    },

    removeMultiThreads(index) {
      console.debug('Method:removeMultiThreads MainComposer', index)

      if(this.threadsOptions.multi_threads.length === 1) {
        this.$emit('onChangeSharingOptions', 'threads', {
          has_multi_threads: false,
          multi_threads: [{ ...defaultThreadsSharingDetails }]
        })
        return
      }

      const threadsOptions = { ...this.threadsOptions }
      threadsOptions.multi_threads.splice(index, 1)
      this.$emit('onChangeSharingOptions', 'threads', threadsOptions)
    },

    /**
     * editor box validations calculations.
     * @returns {*[]}
     */
    editorBoxErrors() {
      console.log('EditorBoxErrors:', this.accountSelection)
      const errors = []

      if (this.commonBoxStatus) {
        if (
          this.getEditorSettings.singleBox.maxLimit !== 0 &&
          this.editorsCharacterCount.common >
            this.getEditorSettings.singleBox.maxLimit
        ) {
          errors.push({
            text: `Note: You have exceeded the characters limit, please reduce your content.`,
          })
        }
      } else {
        for (const channelName in this.accountSelection) {
          if (this.accountSelection[channelName].length) {
            console.debug(
              'EditorBoxErrors:',
              channelName,
              this.editorsCharacterCount[channelName]
            )
            if (
              this.editorsCharacterCount[channelName] >
                this.getEditorSettings[channelName].maxLimit &&
              channelName !== 'tumblr'
            ) {
              errors.push({
                text: `Note: You have exceeded the characters limit for ${channelName}, please reduce your content.`,
              })
            }
          }
        }
      }
      return errors
    },

    setButtonLink(link) {
      this.sharingDetails.common_sharing_details.cta_link =
        this.sharingDetails.gmb_sharing_details.cta_link = link
    },

    /**
     * Copy url from address bar to clipboard
     */
    copyUrlToClipBoard() {
      navigator.clipboard.writeText(window.location.href)
      this.$store?.dispatch('toastNotification', {
        message: 'Composer URL copied',
        type: 'success',
      })
    },

    handleDisableScheduleButton(value) {
      this.disableScheduleButton = value
      this.$emit('disable-schedule-button', this.disableScheduleButton)
    },

    getTooltipContent() {
      const hasLinkedInAccount = this.accountSelection.linkedin.length > 0
      const hasUploadedImages =
        this.sharingDetails[
          this.commonBoxStatus
            ? 'common_sharing_details'
            : 'linkedin_sharing_details'
        ].image.length > 1

      if (!hasLinkedInAccount && !hasUploadedImages) {
        return 'Please select a LinkedIn account and attach a PDF or multiple images to create a carousel post.'
      } else if (
        !hasLinkedInAccount &&
        (hasUploadedImages || this.linkedinOptions?.document_added)
      ) {
        return 'Please select a LinkedIn account to create a carousel post.'
      } else if (
        hasLinkedInAccount &&
        !(hasUploadedImages || this.linkedinOptions?.document_added)
      ) {
        return 'Kindly attach a PDF or multiple images to create a carousel post.'
      } else {
        return ''
      }
    },
    openSidebar(type = '') {
      this.selectedToolbarOption = type
      // hide chat modal if open
      EventBus.$emit('close-chat-modal')
      switch (type) {
        case 'campaigns':
          EventBus.$emit('open-composer-sidebar', type)
          break
        case 'labels':
          EventBus.$emit('open-composer-sidebar', type)
          break
        case 'members':
          EventBus.$emit('open-composer-sidebar', type)
          break
      }
    },

    insertContentToComposer(content, type = 'insert') {
      const editorBoxRef = this.commonBoxStatus
        ? 'common-editor-box-ref'
        : `${this.activeTab}-editor-box-ref`
      const el = this.commonBoxStatus
        ? this.$refs[editorBoxRef]
        : this.$refs[editorBoxRef][0]
      const editor = toRaw(el?.$refs?.editorElement.editor)

      if(type ==='replace'){
        if (this.commonBoxStatus) {
          this.sharingDetails.common_sharing_details.message = content;
        } else {
          this.sharingDetails[this.activeTab + '_sharing_details'].message = content;
        }
        return
      }

      editor?.insertText(content)
      editor?.view?.focus()
    },

    handleActiveTabChange(tabIndex) {
      this.activeTabIndex = tabIndex
      this.$emit('active-tab', this.getTabNameByIndex(tabIndex))
      const currentTab = this.getTabNameByIndex(tabIndex)
      const editorBoxRef = this.commonBoxStatus
        ? 'common-editor-box-ref'
        : `${currentTab}-editor-box-ref`
      const el = this.commonBoxStatus
        ? this.$refs[editorBoxRef]
        : this.$refs[editorBoxRef][0]

      EventBus.$emit('editor-character-count', {
        editorType: el?.type,
        characterCount: el?.getCharacterCount,
      })
    },
     handleFilesSelected(files) {
     console.log('Files selected:', files)
      // Handle the selected files
    },

    handleAddMedia(type, files, mediaType) {
      console.log('Media added:', type, files, mediaType)
      // Handle the added media
  },
    showLocationModal(type){
      this.composerType = type
    },
    },
     handleFilesSelected(files) {
     console.log('Files selected:', files)
      // Handle the selected files
    },

    handleAddMedia(type, files, mediaType) {
      console.log('Media added:', type, files, mediaType)
      // Handle the added media
  },

}
</script>

<style lang="scss">
.fa-gmb {
  position: relative;
  font-family: 'Font Awesome 5 Brands';
  font-weight: normal;
  line-height: 1;
  font-variant: normal;
  text-rendering: auto;

  &:after {
    content: '\f1a0';
  }
}

.box-hover {
  &:hover {
    border-color: #409aff;
    box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
  }
}
</style>
