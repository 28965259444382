<template>
  <div
    :class="{
      'h-[40vh]':
        isLoadingStates.ai_insights ||
        !aiInsightsData ||
        !aiInsightsData.length,
      'h-auto':
        !isLoadingStates.ai_insights && aiInsightsData && aiInsightsData.length,
    }"
    class="bg-gray-cs-50 rounded-lg border border-gray-200 relative"
  >
    <!-- Loading state -->
    <div
      v-if="isLoadingStates.ai_insights"
      class="flex items-center justify-center h-full"
    >
      <ClipLoader size="32px" color="#1F2937" />
    </div>

    <!-- Empty state -->
    <div
      v-else-if="!aiInsightsData || !aiInsightsData.length"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-500 text-center w-full px-4"
    >
      No insights available at the moment
    </div>

    <!-- Content when loaded -->
    <div v-else class="space-y-4 pb-4">
      <div
        v-for="sectionData in aiInsightsData"
        :key="sectionData.category"
        class="mt-6 ml-6 mr-6 flex flex-col gap-2 color-border rounded-xl bg-white shadow-[0px_3px_10px_0px_rgba(211,217,236,0.30)]"
      >
        <CstCollapsible
          :id="sectionData.category"
          :open="openCollapsible(sectionData.category)"
          @header-click="toggleSection(sectionData.category)"
        >
          <template v-slot:header>
            <div class="flex items-center gap-3 ml-3">
              <img
                :src="getCategoryIcon(sectionData.category)"
                alt="Followers Growth"
                class="w-5 h-5"
              />
              <h3 class="font-medium" style="font-size: 16px">
                {{ sectionData.category }}
              </h3>
            </div>
          </template>
          <div
            v-for="insight in sectionData.insights"
            :key="insight.title"
            class="pl-8 pt-16 pb-16 ml-6"
          >
            <h5 class="font-medium" style="font-size: 14px">{{
              insight.title
            }}</h5>
            <p class="text-gray-900 pt-1" style="font-size: 13px">
              {{ insight.description }}
            </p>
            <ul v-if="insight.recommendations" class="list-disc pl-5 mt-2">
              <li
                v-for="(recommendation, index) in insight.recommendations"
                :key="index"
                class="text-gray-900"
              >
                {{ recommendation }}
              </li>
            </ul>
            <div
              v-if="insight?.data && insight?.data?.buckets"
              class="mt-4 chart-wrapper"
            >
              <ForecastLineChart :data="insight.data" />
            </div>
          </div>
        </CstCollapsible>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, watch } from 'vue'
import CstCollapsible from '@ui/Collapse/CstCollapsible'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import ClipLoader from '@src/modules/common/components/spinner/ClipLoader'
import ForecastLineChart from '@/src/modules/analytics/views/common/ForecastLineChart.vue'
import ForecastingOptimizingIcon from '@/src/assets/img/icons/analytic/colored/forecasting-optimizing-ai-insights.svg'
import AudienceGrowthIcon from '@/src/assets/img/icons/analytic/colored/fb-audience-growth-ai-insights.svg'
import ForecastingGrowthIcon from '@/src/assets/img/icons/analytic/colored/forecasting-growth-ai-insights.svg'
import PostTypeIcon from '@/src/assets/img/icons/analytic/colored/post-type-ai-insights.svg'
import ReachTypeIcon from '@/src/assets/img/icons/analytic/colored/reach-type-ai-insights.svg'
import ViewTimeIcon from '@/src/assets/img/icons/analytic/colored/view-time-ai-insights.svg'
import WatchTimeIcon from '@/src/assets/img/icons/analytic/colored/watch-time-ai-insights.svg'
import OptimizationsAndRecommendationsIcon from '@/src/assets/img/icons/analytic/colored/optimization-recommendations-ai-insights.svg'
import OverallPageImpressionsIcon from '@/src/assets/img/icons/analytic/colored/page-impressions-ai-insights.svg'
import OverallReachPerformanceIcon from '@/src/assets/img/icons/analytic/colored/reach-performance-ai-insights.svg'
import OverallVideoViewsPerformanceIcon from '@/src/assets/img/icons/analytic/colored/video-views-ai-insights.svg'
import OverallVideoWatchTimePerformanceIcon from '@/src/assets/img/icons/analytic/colored/video-watch-time-ai-insights.svg'
import OverallPageEngagementPerformanceIcon from '@/src/assets/img/icons/analytic/colored/engagement-performance-ai-insights.svg'
import ReelsPerformanceIcon from '@/src/assets/img/icons/analytic/colored/reels-performance-ai-insights.svg'
import OverallReelsPerformanceIcon from '@/src/assets/img/icons/analytic/colored/overall-reels-ai-insights.svg'
import OverallPostPerformanceIcon from '@/src/assets/img/icons/analytic/colored/content-type-ai-insights.svg'

const props = defineProps({
  section: {
    type: String,
    required: true,
  },
  selectedDropdownOption: {
    type: String,
    required: false,
    default: '',
  },
})

const { fetchMedia, routes, isLoadingStates, aiInsightsData } =
  useFacebookAnalytics()

const activeSection = ref(null)

const sectionMapping = {
  'impressions-chart-fb': 'page_impressions',
  'engagements-chart-fb': 'page_engagement',
  'Publishing Behaviour by Impressions': 'publishing_behaviour_impressions',
  'Publishing Behaviour by Engagements': 'publishing_behaviour_engagements',
  'Publishing Behaviour by Reach': 'publishing_behaviour_reach',
  'audience-growth-fb': 'audience_growth',
  'Video Insights by Views': 'video_views',
  'Video Insights by Watch Time': 'video_watch_time',
  'Video Insights by Engagements': 'video_engagements',
  'Reels Performance by Initial Plays': 'reels_initial_plays',
  'Reels Performance by Watch Time (sec)': 'reels_watch_time',
  'Reels Performance by Engagement': 'reels_engagement',
  'fb-top-posts': 'top_posts',
}

const fetchInsights = async () => {
  await fetchMedia(routes.AI_INSIGHTS, {
    type:
      sectionMapping[props.section] ??
      sectionMapping[props.selectedDropdownOption],
  })
  if (aiInsightsData.value && aiInsightsData.value.success) {
    aiInsightsData.value = aiInsightsData.value.insights.key_insights
    activeSection.value = aiInsightsData.value[0]?.category
  }
}

const getCategoryIcon = (category) => {
  const iconMapper = {
    'Overall Audience Growth Performance': AudienceGrowthIcon,
    'Forecasting and Future Growth Potential': ForecastingGrowthIcon,
    'Forecasting and Optimization Opportunities': ForecastingOptimizingIcon,
    'Daily Insights by Post Type': PostTypeIcon,
    'Monthly Insights by Post Type': PostTypeIcon,
    'Insights by Post Type': PostTypeIcon,
    'Insights by Reach Type': ReachTypeIcon,
    'Insights by View Type': ViewTimeIcon,
    'Insights by Watch Time Type': WatchTimeIcon,
    'Optimization and Recommendations': OptimizationsAndRecommendationsIcon,
    'Overall Page Impressions Performance': OverallPageImpressionsIcon,
    'Overall Reach Performance': OverallReachPerformanceIcon,
    'Overall Video Views Performance': OverallVideoViewsPerformanceIcon,
    'Overall Video Watch Time Performance': OverallVideoWatchTimePerformanceIcon,
    'Overall Page Engagement Performance': OverallPageEngagementPerformanceIcon,
    'Overall Engagement Performance': OverallPageEngagementPerformanceIcon,
    'Insights by Engagement Metric': OverallPageEngagementPerformanceIcon,
    'Insights by Engagement Type': OverallPostPerformanceIcon,
    'Insights for Top-Performing Reels': ReelsPerformanceIcon,
    'Overall Reels Performance': OverallReelsPerformanceIcon,
    'Overall Post Performance': OverallPostPerformanceIcon
  }
  return iconMapper[category] ?? AudienceGrowthIcon
}

onMounted(fetchInsights)

// Watch for changes in selectedDropdownOption
watch(
  () => props.selectedDropdownOption,
  (newValue) => {
    if (newValue) {
      fetchInsights()
    }
  }
)

const openCollapsible = (section) => {
  return activeSection.value === section
}

const toggleSection = (section) => {
  if (section === activeSection.value) {
    activeSection.value = null
    return
  }
  activeSection.value = section
}
</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
}
.chart-wrapper {
  height: 300px;
}
.list-disc {
  list-style-type: none;
}
.list-disc li::before {
  content: '\2022';
  color: #2563eb; /* Custom bullet color */
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
h5 {
  padding-bottom: 2px;
  margin-bottom: 4px;
}
.pl-8 {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-right: 16px;
}
.pl-8:not(:last-child) {
  border-bottom: 1px solid #70707029;
  margin-bottom: 0;
}
</style>
