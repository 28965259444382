<template>
  <div>
    <FacebookBackgroundModal
      :facebook-background-id="facebookOptions.facebook_background_id"
      @set-facebook-background-id="setFacebookBackgroundId"
    />
    <b-modal
      id="composer-modal"
      ref="composer-modal"
      hide-header
      hide-footer
      dialog-class="cst-modal"
      body-class="cst-modal__body"
      ignore-enforce-focus-selector=".PinturaInputDimension, .PinturaFieldsetInner, .PinturaFormInner, .PinturaContentEditable, .chatInput"
      @show="handleModalOpen"
      @hide="onCloseConfirmation"
      @hidden="resetComponentData"
    >
      <div
        v-if="loader"
        class="p-3 flex flex-col items-center justify-center min-h-100 h-96"
      >
        <img
            :src="(shouldShowWhiteLabelData && appData.brandLogo) || loadingImage"
            :class="{
              'animate-bounce': shouldShowWhiteLabelData && appData?.brandLogo,
            }"
            width="55"
            alt="logo-animation"
        />
        <span class="mt-2.5 text-gray-800">Loading Social Composer...</span>
      </div>
      <div v-else class="cst-modal__wrapper relative">
        <!-- eslint-disable -->
        <div class="z-20">
          <AccountSelectionAside
            v-show="showAccountSelection"
            :account-selection="account_selection"
            :accounts-list="accountsList"
            :carousel-options="carouselOptions"
            :content-category-list="contentCategoryList"
            :first-comment="firstComment"
            :global-content-category="globalContentCategory"
            :gmb-options="gmbOptions"
            :gmb-options-edited="gmbOptionsEdited"
            :is-allow-first-comment="isAllowFirstComment"
            :is-approver="isApprover"
            :mobile-devices="mobileDevices"
            :publish-as="facebookOptions.posted_as"
            :selected-content-category="selectedContentCategory"
            :social-post-type="socialPostType"
            :youtube-options="youtubeOptions"
            :linkedin-options="linkedin_options"
            :is-twitter-allowed="isTwitterAllowed"
            :locations="locations"
            @update-location="updateLocation"
            @closeAccountSelection="showAccountSelection = false"
            @handleAccountsUpdate="handleAccountsUpdate"
            @onChangeContentCategory="onChangeContentCategory"
            @onChangePostedAs="onChangePostedAs"
            @setFirstCommentAccountsList="setFirstCommentAccountsList"
            @setYoutubeGmbOptions="setYoutubeGmbOptions"
            @updateInstagramSelection="updateInstagramSelection"
          />
        </div>

        <MainComposer
          ref="main-composer"
          :account-selection="account_selection"
          :accounts-list="accountsList"
          :approval="approval"
          :carousel-accounts="getCarouselValidAccounts"
          :carousel-options="carouselOptions"
          :carousel-url="getCarouselUrl"
          :common-box-status="common_box_status"
          :fb-carousel-errors="carouselPostErrors.errors"
          :first-comment="firstComment"
          :first-comment-accounts-list="firstCommentAccountsList"
          :footer-data="getFooterData"
          :global-content-category="globalContentCategory"
          :gmb-errors="gmbErrors"
          :gmb-options="gmbOptions"
          :insta-posting-method="instagramPostingMethod"
          :instagram-post-type="instagramPostType"
          :instagram-collaborators="instagramCollaborators"
          :instagram-share-to-story="instagramShareToStory"
          :facebook-post-type="facebookOptions.post_type"
          :facebook-background-id="facebookOptions.facebook_background_id"
          :facebook-share-to-story="facebookOptions.facebook_share_to_story"
          :is-allow-first-comment="isAllowFirstComment"
          :is-approver="isApprover"
          :last-updated="lastUpdated"
          :next-content-category-slot="nextContentCategorySlot"
          :plan-id="planId"
          :posting-schedule-errors="postingScheduleErrors"
          :process-plan-loader="processPlanLoader"
          :publish-time-options="publishTimeOptions"
          :queue-status="queueStatus"
          :selected-content-category="selectedContentCategory"
          :sharing-details="sharing_details"
          :social-errors="socialPostErrors"
          :social-post-type="socialPostType"
          :twitter-options="twitterOptions"
          :threads-options="threadsOptions"
          :bluesky-options="blueskyOptions"
          :username="username"
          :utm="utmData"
          :youtube-errors="youtubeErrors"
          :youtube-options="youtubeOptions"
          :post-status="postStatus"
          :tiktok-options="tiktokOptions"
          :tiktok-errors="tiktokErrors"
          :selected-accounts-length="selectedAccountsLength"
          :linkedin-options="linkedin_options"
          :uploading-ai-images="$refs['image-generator'].mediaUploading"
          :clearState="$refs['image-generator'].clearState"
          :selected-tabs="selectedTab"
          :active-tab="common_box_status ? 'common' : activeTab"
          :approver-modal-validation="approverModalValidation"
          :folder-id="folderId"
          :labels="labels"
          :locations="locations"
          @composerResetSharingDetails="composerResetSharingDetails"
          @editQueuedPost="editQueuedPost"
          @duplicatePost="duplicatePost"
          @editorErrors="editorErrors"
          @isSeparateBoxes="isSeparateBoxes"
          @onChangeFirstComment="onChangeFirstComment"
          @onChangeSharingDetails="onChangeSharingDetails"
          @onChangeSharingOptions="onChangeSharingOptions"
          @reset-facebook-carousel="resetFacebookCarousel"
          @processSocialShare="processSocialShareNew"
          @resetCarouselCards="clearCarouselCards"
          @setGmbOptionsEdited="(val) => (gmbOptionsEdited = val)"
          @setInstaPostingApi="setInstaPostingApi"
          @setInstagramPostType="setInstagramPostType"
          @setInstagramCollaborators="setInstagramCollaborators"
          @setInstagramShareToStory="setInstagramShareToStory"
          @setFacebookPostType="setFacebookPostType"
          @setFacebookVideoTitle="setFacebookVideoTitle"
          @setYoutubePostType="setYoutubePostType"
          @setYoutubeOptionsEdited="(val) => (youtubeOptionsEdited = val)"
          @fetch-utms="fetchUtms"
          @active-tab="setActiveTab"
          @auto-save-plan="handleAutoSave"
          @set-category-slot="setCategorySlot"
          @set-approval="handleApprover"
          @profile-tagging="onImageTagClick"
          @save-editor-url="saveEditorUrl"
          @disable-schedule-button="(value) => (disableScheduleButton = value)"
          @update-carousel-cards="updateCarouselCards"
          @set-facebook-background-id="setFacebookBackgroundId"
          @set-facebook-share-to-story="setFacebookShareToStory"
          @repeat-post-error="handleRepeatPostIntervalError"
          @handle-campaigns-changes="handleCampaignsChanges"
          @update-labels="handleLabelChanges"
          @minimize-composer="minimizeComposer"
          @toggle-custom-content="(val) => common_box_status = val"
          @update-locations="updateLocation"
          v-bind="$attrs"
          v-on="$listeners"
        />

        <!-- Composer Close Starts -->
        <div
          class="
            group
            absolute
            w-12
            hover:w-16
            bg-white
            right-0
            top-0
            z-[99999]
            flex
            items-center
            justify-around
            h-8
            my-3
            mr-1
            rounded-md
            shadow
            transition-all
            duration-200
            ease-in-out
            hover:scale-105
            hover:shadow-md
          "
          style="border: 1px solid #b4d9ff"
        >
          <img
            v-tooltip="'Minimize'"
            alt="close"
            class="
              cursor-pointer
              p-0.5
              group-hover:p-2 group-hover:h-full group-hover:w-8
              hover:bg-gray-100
              rounded-md
            "
            src="@src/assets/img/composer/minimize-composer.svg"
            @click.prevent="minimizeComposer"
          />
          <img
            v-tooltip="'Close'"
            alt="close"
            class="
              cursor-pointer
              p-0.5
              group-hover:w-full group-hover:h-full group-hover:p-2
              rounded-md
            "
            src="@src/assets/img/composer/close-composer.svg"
            @click.prevent="onCloseConfirmation"
          />
        </div>
        <!-- Composer Close Section Ends -->

        <ComposerSidebar
          :data="{
            status: common_box_status,
            details: sharing_details,
            accounts: account_selection,
            accountList: accountsList,
            carousel: carouselOptions,
            carouselAccounts: getCarouselValidAccounts,
            youtubeTitle: youtubeOptions.title,
            youtubePostType: youtubeOptions.post_type,
            gmbOptions,
            campaigns: getPublicationFolders,
            firstComment,
            twitterOptions,
            threadsOptions,
            blueskyOptions,
            tasks,
            comments,
            members,
            labels,
            activities,
            folderId,
            planId,
            csvPlanId,
            activeTab,
            publishAs: facebookOptions.posted_as,
            instagramPostType,
            facebookPostType: facebookOptions.post_type,
            facebookBackgroundId: facebookOptions.facebook_background_id,
            facebookShareToStory: facebookOptions.facebook_share_to_story,
            linkedinOptions: linkedin_options,
            tiktokOptions,
            instagramCollaborators,
            facebookGroupSelected,
            facebookPageSelected,
            instagramShareToStory,
            locations,
          }"
          :is-approver="isApprover"
          @handle-campaigns-changes="handleCampaignsChanges"
          @update-members="handleMemberChanges"
          @update-labels="handleLabelChanges"
          @auto-save-plan="handleAutoSave"
          @handle-comment-changes="handleCommentChanges"
          @handle-task-changes="handleTaskChanges"
        />
        <!-- eslint-enable -->
        <UploadMediaModal type="social"></UploadMediaModal>
        <ApprovalModal
        module="social"
          :account-selection="account_selection"
          @approval-data="handleApprover"
        ></ApprovalModal>
      </div>
    </b-modal>
    <AiCaptionModal @caption-output="handleAiCaptionOutput" />
    <CustomThumbnailModal :account-selection="account_selection" />
    <ProfileTaggingModal />
    <ImageGeneratorModal
      ref="image-generator"
      :upload-images-from-link="uploadImagesFromLink"
    />
    <SaveCaptionModal
      ref="save-caption-modal"
      :handle-ai-caption-output="handleAiCaptionOutput"
    />
  </div>
</template>

<script>
import { merge } from 'lodash'
import ComposerHelperMixin from '@src/modules/composer_v2/mixins/ComposerHelper'
import ComposerValidationMixin from '@src/modules/composer_v2/mixins/ComposerValidation'
import MainComposer from '@src/modules/composer_v2/components/MainComposer'
import FacebookBackgroundModal from '@modules/composer_v2/components/EditorBox/FacebookBackground/FacebookBackgroundModal.vue'
import {
  socialChannelsNameArray,
  swalAttributes,
} from '@common/constants/common-attributes'
import { mapGetters } from 'vuex'
import UploadMediaModal from '@src/modules/publish/components/media-library/components/UploadMediaModal'
import CustomThumbnailModal from '@src/modules/composer_v2/components/CustomThumbnail/CustomThumbnailModal'
import ProfileTaggingModal from '@src/modules/composer_v2/components/ProfileTaggingModal'
import ApprovalModal from '@src/modules/composer_v2/components/SendForApprovalModal/ApprovalModal'
import AiCaptionModal from '@src/modules/composer_v2/components/AiCaptionModal.vue'
import ImageGeneratorModal from '@src/modules/composer_v2/components/EditorBox/ImageGenerator/ImageGeneratorModal.vue'
import SaveCaptionModal from '@src/modules/composer_v2/components/EditorBox/SaveCaptions/SaveCaptionModal.vue'
import {
  defaultCarouselOptions,
  defaultFirstComment,
  defaultGmbOptions,
  defaultPinterestOptions,
  defaultPublishTimeOptions,
  defaultPublishTimeOptionsForSchedulePost,
  defaultSharingDetails,
  defaultTwitterOptions,
  defaultYoutubeOptions,
  firstCommentChannels,
  videoDefaultSharingDetails,
  defaultTiktokOptions,
  defaultLinkedinOptions,
  defaultThreadsOptions, defaultBlueskyOptions,
} from '@src/modules/composer_v2/views/composerInitialState'
import proxy from '@src/modules/common/lib/http-common'
import { socialIntegrationsConfigurations } from '@src/modules/integration/config/api-utils'
import { socialPlatformNames } from '@common/lib/integrations'
import he from 'he'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { commonMethods } from '@common/store/common-methods'
import {
  processSocialContentCategoryPostURL,
  processSocialShareUrl,
 twitterPostLimit, twitterPremiumPostLimit } from '@src/modules/publish/config/api-utils'
import twitterText from 'twitter-text'
import {
  approvalStages,
  commonTypes,
  sharingTypes,
} from '@src/modules/publish/store/states/mutation-types'
import * as linkify from 'linkifyjs'
import isEmpty from 'is-empty'
import { getLinksFromText, memoizeCallback } from '@common/lib/helper'
import { MediaHelperMixin } from '@src/modules/publish/components/media-library/utils/MediaHelpers'
import { EventBus } from '@common/lib/event-bus'
import { defineAsyncComponent } from 'vue'
import useInstagramGridView from '@modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
import { MULTIMEDIA_ALLOWED_PLATFORMS } from '@common/constants/composer'
import CampaignAttachment from '@src/components/common/CampaignAttachment.vue'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

const clone = require('rfdc/default')

const moment = require('moment-timezone/builds/moment-timezone-with-data.min')

const memoizedGetLinksFromText = memoizeCallback(getLinksFromText, true)
const initialDataState = () => ({
  loadingImage: require('@assets/img/logo/logo-animation-white-bg.gif'),
  loader: true,
  planId: null,
  common_box_status: true,
  accountsList: [],
  firstCommentAccountsList: [],
  accountsSelectedByDefault: false,
  sharing_details: clone(defaultSharingDetails),
  firstComment: clone(defaultFirstComment),
  account_selection: {},
  activeTab: 'facebook',
  selectedContentCategory: null,
  nextContentCategorySlot: null,
  /* warnings: [
    {
      id: 123,
      text: 'Some Warning'
    }
  ], */
  errors: [],
  info: [],
  publishTimeOptions: clone(defaultPublishTimeOptions),
  execution_time: null,
  facebookOptions: {
    posted_as: {},
    post_type: 'feed',
    facebook_background_id: '',
    facebook_share_to_story: false
  },
  gmbOptions: defaultGmbOptions,
  youtubeOptions: clone(defaultYoutubeOptions),
  twitterOptions: clone(defaultTwitterOptions),
  threadsOptions: clone(defaultThreadsOptions),
  blueskyOptions: clone(defaultBlueskyOptions),
  pinterest_options: {
    postDescription: '',
  },
  linkedin_options: clone(defaultLinkedinOptions),
  carouselOptions: clone(defaultCarouselOptions),
  carouselUrl: {
    url: '',
    image: '',
    title: '',
    description: '',
  },
  utmData: {
    fetchUtms: false,
    items: [],
    currentUtm: [],
    loader: false,
  },
  tasks: [],
  comments: [],
  labels: [],
  members: [],
  campaigns: [],
  mobileDevices: [],
  instagramPostType: '',
  instagramCollaborators: [],
  instagramShareToStory: false,
  postStatus: 'draft',
  queueStatus: false,
  activities: {
    hits: [],
  },
  repost: false,
  approval: {},
  folderId: null,
  publish_source: '',
  draftCancel: false,
  socialPostType: 'new-post',
  isApprover: false,
  csvPlanId: null,
  reopen: false,
  lastUpdated: {},
  processPlanLoader: false,
  gmbOptionsEdited: false,
  youtubeOptionsEdited: false,
  isDirectUrl: false,
  isDuplicatePost: false,
  tiktokOptions: clone(defaultTiktokOptions),
  isForceDraftSave: false,
  locations:null
})
export default {
  name: 'SocialModal',
  components: {
    CampaignAttachment,
    FacebookBackgroundModal,
    UploadMediaModal,
    AccountSelectionAside: defineAsyncComponent(() =>
      import('../components/AccountSelectionAside')
    ),
    ComposerSidebar: defineAsyncComponent(() =>
      import('../components/ActionsAside')
    ),
    MainComposer,
    CustomThumbnailModal,
    ProfileTaggingModal,
    ApprovalModal,
    ImageGeneratorModal,
    AiCaptionModal,
    SaveCaptionModal,
  },
  mixins: [ComposerHelperMixin, ComposerValidationMixin, MediaHelperMixin],
  emits: ['add-media-from-media-library'],
  setup() {
    const { isComposerModalOpen, isDraftComposer, draftPlanId , showNewChat } =
      useComposerHelper()
    const { isGridView } = useInstagramGridView()
    const {appData, shouldShowWhiteLabelData} = useWhiteLabelApplication()

    return {
      isComposerModalOpen,
      isDraftComposer,
      isGridView,
      draftPlanId,
      appData,
      shouldShowWhiteLabelData,
      showNewChat
    }
  },
  data() {
    return {
      ...initialDataState(),
      intervalId: null,
      showAccountSelection: true,
      modalClosable: false,
      previousRoute: '',
      selectedTab: '',
      disableScheduleButton: false,
      selectedAccountsLength: null,
      forceDisableButton: false,
      gridViewPostData: {},
      facebookGroupSelected: false,
      facebookPageSelected: false,
      isModalMinimized: false,
    }
  },
  computed: {
    ...mapGetters([
      'getContentCategoryList',
      'getActiveWorkspace',
      'getProfile',
      'getReplug',
      'getUtms',
      'getSocialSharingMediaDetails',
      'getPublicationFolders',
      'getMobileDevices',
      'getSocialAccountsList',
      'getCommonSharingDetails',
      'getPlan',
    ]),
    instagramHashtagCount() {
      console.log('COMPUTE::instagramHashtagCount')

      const errors = []
      const instagramMessage = this.common_box_status
        ? this.sharing_details.common_sharing_details.message
        : this.sharing_details.instagram_sharing_details.message

      let commentMessage = ''
      let accountSelected = false

      // No need to go further if there is no text
      if (
        isEmpty(this.firstComment.first_comment_message) &&
        isEmpty(instagramMessage)
      ) {
        return errors
      }

      // to count only message box hashtags
      if (instagramMessage) {
        let tags = linkify.find(instagramMessage, 'hashtag')
        tags = tags.map((tag) => tag.value)
        tags = [...new Set(tags)]
        console.log('tags', tags.length, tags)
        if (tags && tags.length > 30) {
          errors.push({
            text:
              'The composer box contains ' +
              tags.length +
              ' hashtags, Instagram allows for upto 30. Please reduce the amount of hashtags used in Composer to avoid the post getting failed.',
          })
          return errors
        }
      }

      if (
        this.account_selection.instagram.length > 0 &&
        this.firstComment.has_first_comment &&
        this.firstComment.first_comment_accounts.length > 0
      ) {
        this.account_selection.instagram.forEach((account) => {
          if (this.firstComment.first_comment_accounts.includes(account)) {
            commentMessage = this.firstComment.first_comment_message
            accountSelected = true
          }
        })
      }

      // to count only first comment hashtags
      if (accountSelected && commentMessage) {
        let tags = linkify.find(commentMessage, 'hashtag')
        tags = tags.map((tag) => tag.value)
        tags = [...new Set(tags)]
        if (tags && tags.length > 30) {
          errors.push({
            text:
              'The first comment box contains ' +
              tags.length +
              ' hashtags, Instagram allows for upto 30. Please reduce the amount of hashtags used in the First Comment so that the first comment goes with the post. The post will not fail in this case.',
          })
          return errors
        }
      }

      if (accountSelected && instagramMessage && commentMessage) {
        let tags = linkify.find(instagramMessage + commentMessage, 'hashtag')
        tags = tags.map((tag) => tag.value)
        tags = [...new Set(tags)]
        if (tags && tags.length > 30) {
          errors.push({
            text:
              'The post and first comment accumulated contain ' +
              tags.length +
              ' hashtags, Instagram allows for a total of 30. Please reduce the amount of hashtags used in Composer or First Comment to avoid the post getting failed.',
          })
          return errors
        }
      }

      return errors
    },

    carouselPostErrors() {
      console.log('COMPUTE::carouselPostErrors')

      const warnings = []
      const errors = []
      const errorsIndexes = []
      if (
        this.carouselOptions &&
        this.carouselOptions.is_carousel_post &&
        this.carouselOptions.cards.length
      ) {
        if (this.carouselOptions.cards.length < 2) {
          errors.push({
            text: 'You need to have at least 2 cards in a Facebook Carousel post excluding end card.',
          })
        }
        if (this.carouselOptions.cards.length > 10) {
          errors.push({
            text: 'You can have a maximum of 10 cards in a Facebook Carousel post.',
          })
        }
        if (this.carouselOptions.cards.length > 1) {
          this.carouselOptions.cards.forEach((card, index) => {
            if (!card.url) {
              errors.push({
                text: `Facebook Carousel: Card ${
                  index + 1
                } has no URL (URL is required).`,
              })
              errorsIndexes.push(index)
            } else if (!this.isValidURL(card.url)) {
              errors.push({
                text: `Facebook Carousel: Card ${index + 1} has invalid URL.`,
              })
              errorsIndexes.push(index)
            }
            if (!card.name) {
              warnings.push({
                text: `Facebook Carousel: Card ${index + 1} has no title.`,
              })
            }
          })
        }
        if (this.carouselOptions.end_card) {
          if (!this.carouselOptions.end_card_url) {
            errors.push({
              text: `End card requires a URL in Facebook Carousel, this is usually the Facebook page link the post is going out to.`,
            })
          } else if (!this.isValidURL(this.carouselOptions.end_card_url)) {
            errors.push({
              text: `Facebook Carousel: End card has invalid URL.`,
            })
          }
        }
      }
      return {
        warnings,
        errors,
        errorsIndexes,
      }
    },
    twitterAccountMap () {
      return new Map(
        this.accountsList
          .filter(acc => acc.channel_type === 'twitter')
          .map(acc => [acc[acc.channel_identifier], acc]) // Map by the channel_identifier
      );
    },
    twitterLinkDetails(){
      if (this.common_box_status) {
        const detail = this.sharing_details.common_sharing_details
        return {
          url: detail.twitter_website,
          title: detail.twitter_title,
          description: detail.twitter_description,
          image: detail.twitter_image,
          message: detail.message,
        }
      }
      const detail = this.sharing_details.twitter_sharing_details
      return {
        url: detail.website,
        title: detail.title,
        description: detail.description,
        image: detail.image,
        message: detail.message,
      }
    },
    threadedTweetsErrors() {
      console.log('COMPUTE::threadedTweetsErrors')

      const warnings = []
      const errors = []
      // Now check if every selected twitterId is a premium account
      const allVerified = this.account_selection.twitter?.every(twitterId => {
        const account = this.twitterAccountMap.get(twitterId);
        return ['premium', 'premiumplus', 'basic'].includes(account?.subscription_type?.toLowerCase());
      });

      const limit = allVerified ? twitterPremiumPostLimit : twitterPostLimit

      if (this.twitterOptions.has_threaded_tweets) {
        const tweets = this.twitterOptions.threaded_tweets
        for (const i in tweets) {
          const remaining =
          limit -
            twitterText.parseTweet(tweets[i].message.trim()).weightedLength
          if (remaining < 0)
            errors.push({
              text: `Threaded Tweet: You have exceeded the characters limit, please reduce your content.`,
            })
        }
      }
      return {
        warnings,
        errors,
      }
    },

    multiThreadsErrors() {
      console.log('COMPUTE::multiThreadsErrors')

      const warnings = []
      const errors = []

      if (
        this.threadsOptions?.has_multi_threads &&
        this.threadsOptions?.multi_threads?.length
      ) {
        const characterLimitExceeded = this.threadsOptions.multi_threads.some(
          (thread) =>
            thread.message.length >
            socialIntegrationsConfigurations.threads.validations.characters
        )

        if (characterLimitExceeded) {
          errors.push({
            text: `Note: You have exceeded the characters limit for Multi Meta Threads, please reduce your content.`,
          })
        }
      }

      return {
        warnings,
        errors,
      }
    },
    multiBlueskyErrors() {
      console.log('COMPUTE::multiBlueskyErrors')

      const warnings = []
      const errors = []

      if (
          this.blueskyOptions?.has_multi_bluesky &&
          this.blueskyOptions?.multi_bluesky?.length
      ) {
        const characterLimitExceeded = this.blueskyOptions.multi_bluesky.some(
            (bluesky) =>
                bluesky.message.length >
                socialIntegrationsConfigurations.bluesky.validations.characters
        )

        if (characterLimitExceeded) {
          errors.push({
            text: `Note: You have exceeded the characters limit for Multi Bluesky, please reduce your content.`,
          })
        }
      }

      return {
        warnings,
        errors,
      }
    },

    /**
     * Pinterest post errors
     */
    pinterestErrors() {
      const errors = []
      const sharingDetails = this.common_box_status
        ? this.sharing_details.common_sharing_details
        : this.sharing_details.pinterest_sharing_details
      if (
        sharingDetails.source_url &&
        !this.isValidPinterestSourceUrl(sharingDetails.source_url)
      ) {
        errors.push({
          text: 'Pinterest: Invalid Source URL (e.g. https://www.example.com/xyz)',
        })
      }
      return errors
    },

    /**
     * gmb options errors validations calculations
     * @returns {*[]}
     */
    gmbErrors() {
      console.log('COMPUTE::gmbErrors')

      if (
        !this.account_selection.gmb ||
        this.account_selection.gmb.length === 0
      ) {
        return []
      }
      const errors = []
      const gmbSharingDetails = this.common_box_status
        ? this.sharing_details.common_sharing_details
        : this.sharing_details.gmb_sharing_details
      if (Object.keys(this.account_selection).length === 0) return errors
      if (
        this.account_selection.gmb.length &&
        gmbSharingDetails.video &&
        gmbSharingDetails.video.link === ''
      ) {
        if (
          (this.gmbOptions.topic_type === 'STANDARD' ||
            this.gmbOptions.topic_type === 'EVENT') &&
          this.gmbOptions.action_type !== '' &&
          this.gmbOptions.action_type !== 'CALL' &&
          gmbSharingDetails.cta_link === ''
        ) {
          errors.push({
            text: 'Button link cannot be empty in GMB Options',
          })
        }

        if (
          (this.gmbOptions.topic_type === 'STANDARD' ||
            this.gmbOptions.topic_type === 'EVENT') &&
          this.gmbOptions.action_type !== '' &&
          this.gmbOptions.action_type !== 'CALL' &&
          gmbSharingDetails.cta_link
        ) {
          if (!this.isValidURL(gmbSharingDetails.cta_link))
            errors.push({
              text: 'Button link is not valid in GMB Options',
            })
        }

        if (this.gmbOptions.topic_type !== 'STANDARD') {
          if (!this.gmbOptions.title || this.gmbOptions.title === '') {
            errors.push({ text: 'Title cannot be empty in GMB Options.' })
          }
          if (
            !this.gmbOptions.start_date ||
            this.gmbOptions.start_date === '' ||
            this.gmbOptions.start_date === 'Invalid date'
          ) {
            errors.push({ text: 'Date cannot be empty in GMB Options.' })
          } else if (
            !this.gmbOptions.end_date ||
            this.gmbOptions.end_date === '' ||
            this.gmbOptions.end_date === 'Invalid date'
          ) {
            errors.push({ text: 'Date cannot be empty in GMB Options.' })
          }
        }
      }

      return errors
    },

    /**
     * Social post errors calculation/validation
     * @returns {*[]}
     */
    socialPostErrors() {
      console.log('COMPUTE::socialPostErrors')

      let errors = []

      if (this.$el && this.$refs['main-composer']) {
        const editorErrors = this.$refs['main-composer'].editorBoxErrors()
        if (editorErrors && editorErrors.length) {
          errors = errors.concat(editorErrors)
        }
      }

      if (
        (this.forceDisableButton &&
          this.disableScheduleButton &&
          this.publishTimeOptions.time_type === 'schedule') ||
        (this.disableScheduleButton &&
          this.publishTimeOptions.time_type === 'schedule' &&
          !this.publishTimeOptions?.is_custom_schedule)
      ) {
        errors.push({
          text: this.selectedAccountsLength
            ? 'Please select time for newly selected accounts(s) in order to schedule your posts.'
            : 'Please select an account to schedule your post.',
        })
      }

      for (const channelName in this.account_selection) {
        if (
          channelName !== 'linkedin' &&
          this.account_selection[channelName].length &&
          this.common_box_status &&
          this.linkedin_options?.document_added
        ) {
          errors.push({
            text: 'PDF document is only supported for Linkedin',
          })
          return errors
        }
        if (this.account_selection[channelName].length) {
          // Checking YouTube options for errors
          if (channelName === 'youtube') {
            errors = errors.concat(this.youtubeErrors)
          }
          // Checking Pinterest errors
          if (channelName === 'pinterest') {
            errors = errors.concat(this.pinterestErrors)
          }

          // Checking GMB Options for errors
          if (channelName === 'gmb') {
            errors = errors.concat(this.gmbErrors)
          }

          if (channelName === 'instagram') {
            errors = errors.concat(this.instagramHashtagCount)
          }

          if (channelName === 'tiktok') {
            errors = errors.concat(this.tiktokErrors)
          }

          if (channelName === 'facebook') {
            errors = errors.concat(this.facebookErrors)
          }

          if (channelName === 'linkedin') {
            errors = errors.concat(this.linkedinErrors)
          }

          if (channelName === 'instagram') {
            errors = errors.concat(this.instagramErrors)
          }
        }
      }

      errors = errors.concat(this.postingScheduleErrors)

      return errors
    },

    /**
     * youtube options errors validations and calculations.
     * @returns {*[]}
     */
    youtubeErrors() {
      console.log('COMPUTE::youtubeErrors')

      const errors = []
      if (Object.keys(this.account_selection).length === 0) return errors
      if (
        this.account_selection.youtube &&
        this.account_selection.youtube.length
      ) {
        if (
          !this.youtubeOptions.title ||
          this.youtubeOptions.title.trim().length === 0
        ) {
          errors.push({
            text: 'Video Title cannot be empty in Youtube Settings.',
          })
        } else if (
          this.youtubeOptions.title &&
          this.youtubeOptions.title.length > 100
        ) {
          errors.push({
            text: 'You cannot add more than 100 characters for Youtube video title',
          })
        }
      }
      return errors
    },
    /**
     * Tiktok options and video errors validations.
     * @returns {*[]}
     */
    tiktokErrors() {
      const errors = []
      if (Object.keys(this.account_selection).length === 0) return errors
      if (
        this.account_selection.tiktok &&
        this.account_selection.tiktok.length
      ) {
        if (
          this.tiktokOptions.privacy_level === '' &&
          this.tiktokOptions.publishing_method === 'direct'
        ) {
          errors.push({
            text: 'Please select who can view this post in Tiktok Settings.',
          })
        }

        const sharingDetails = this.common_box_status
          ? this.sharing_details.common_sharing_details
          : this.sharing_details.tiktok_sharing_details

        const postType = this.tiktokOptions?.post_type || 'video'
        const validations = socialIntegrationsConfigurations.tiktok.validations

        switch (postType) {
          case 'video':
            if (!sharingDetails.video?.link) {
              errors.push({
                text: 'Please add a video for Tiktok video post.',
              })
            }
            break
          case 'carousel':
            if (
              sharingDetails.image &&
              sharingDetails.image?.length < validations.image.min_images
            ) {
              errors.push({
                text: 'Please add at least 2 images for Tiktok carousel post.',
              })
            } else if (
              sharingDetails.image &&
              sharingDetails.image?.length > validations.image.max_images
            ) {
              errors.push({
                text: 'You can add maximum 35 images for Tiktok carousel post.',
              })
            }
            break
        }
      }
      return errors
    },

    facebookErrors() {
      const errors = []
      if (Object.keys(this.account_selection).length === 0) return errors

      const detail =
        this.common_box_status && this.sharing_details.common_sharing_details
          ? this.sharing_details.common_sharing_details
          : this.sharing_details.facebook_sharing_details

      if (
        this.facebookPageSelected &&
        this.facebookOptions?.facebook_background_id?.length &&
        !this.carousel?.is_carousel_post &&
        !detail.url &&
        !detail.image.length &&
        !detail?.multimedia?.length &&
        !detail.video.thumbnail &&
        !detail?.message?.length
      ) {
        errors.push({
          text: 'A text message is required to create a Facebook background text post.',
        })
      }

      if (
        this.facebookGroupSelected &&
        !this.facebookPageSelected &&
        this.publishTimeOptions.time_type !== 'draft'
      ) {
        switch (this.facebookOptions.post_type) {
          case 'reel':
            errors.push({
              text: socialIntegrationsConfigurations.facebook?.validations
                ?.reel_posting_as_group,
            })
            break
          case 'story':
            errors.push({
              text: socialIntegrationsConfigurations.facebook?.validations
                ?.story_posting_as_group,
            })
            break
          default:
            break
        }
      }

      return errors
    },

    linkedinErrors() {
      const errors = []
      if (this.linkedin_options?.is_carousel)
        if (!this.linkedin_options?.accounts?.length) {
          errors.push({
            text: 'Please select at least one LinkedIn account for carousel post.',
          })
        }
      return errors
    },

    /**
     * posting schedule options validations calculations
     * @returns {*[]}
     */
    postingScheduleErrors() {
      console.log('COMPUTE::postingScheduleErrors')

      const errors = []
      if (
        this.publishTimeOptions.time_type === 'content_category' &&
        this.selectedContentCategory === null
      ) {
        errors.push({
          text: 'Please select content category, it cannot be empty if you like to add content to category.',
        })
      }

      return errors
    },
    /**
     * computing the name of instagram post type
     * @returns {string}
     */
    computeInstagramPostType() {
      return this.instagramPostType === 'feed_reel'
        ? 'Feed + Reel'
        : this.instagramPostType
    },
    /**
     * Editor media box instagram post errors
     * @returns {[{text: string}]|*[]}
     */
    instagramErrors() {
      if (this.instagramPostingMethod === 'mobile') return []

      const errors = []
      if (Object.keys(this.account_selection).length === 0) return errors

      if (!this.account_selection.instagram.length) {
        return errors
      }

      const detail =
        this.common_box_status && this.sharing_details.common_sharing_details
          ? this.sharing_details.common_sharing_details
          : this.sharing_details.instagram_sharing_details

      if (detail?.multimedia?.length) {
        const hasMultimedia =
          detail.multimedia.some((item) => typeof item === 'object') &&
          detail.multimedia.some((item) => typeof item === 'string')

        if (this.instagramPostType === 'feed') {
          if (
            hasMultimedia ||
            detail.multimedia.length > 1 ||
            typeof detail.multimedia[0] === 'object'
          ) {
            errors.push({
              text: `Only single image should be included in Instagram ${this.computeInstagramPostType} post.`,
            })
          }
        }

        if (['reel', 'feed_reel'].includes(this.instagramPostType)) {
          if (
            hasMultimedia ||
            detail?.multimedia?.length > 1 ||
            typeof detail.multimedia[0] === 'string'
          ) {
            errors.push({
              text: `Only single video should be included in Instagram ${this.computeInstagramPostType} post.`,
            })
          }
        }

        if (
          this.instagramPostType === 'carousel' &&
          detail?.multimedia?.length <= 1
        ) {
          errors.push({
            text: `Multiple images, videos, or a mix content is required for Instagram ${this.computeInstagramPostType} post.`,
          })
        }
      }

      return errors
    },
    /**
     * Editor media box video validation calculations
     * @returns {*[]}
     */
    videoErrors() {
      console.log('COMPUTE::videoErrors')

      const errors = []

      const accountSelection = this.account_selection

      for (const channelName in accountSelection) {
        const detail = this.common_box_status
          ? this.sharing_details.common_sharing_details
          : this.sharing_details[`${channelName}_sharing_details`]

        let videos = []
        if (
          MULTIMEDIA_ALLOWED_PLATFORMS.includes(channelName) &&
          detail?.multimedia?.length
        ) {
          videos = detail.multimedia.filter(
            (media) => typeof media === 'object'
          )
        } else {
          videos = [detail?.video]
        }

        // Handle videos from threads
        if (this.threadsOptions.has_multi_threads && videos) {
          const threads = this.threadsOptions.multi_threads
          threads.forEach((thread) => {
            if (thread?.multimedia?.length) {
              const threadVideos = thread.multimedia.filter(
                (media) => typeof media === 'object'
              )
              videos = videos.concat(threadVideos || [])
            }
          })
        }

        // Handle videos from bluesky
        if (this.blueskyOptions.has_multi_bluesky && videos) {
          const blueskys = this.blueskyOptions.multi_bluesky
          blueskys.forEach((bluesky) => {
            if (bluesky?.video?.link) {
              videos = videos.concat(bluesky.video || {})
            }
          })
        }

        if (accountSelection[channelName].length) {
          videos.forEach((video) => {
            if (
              video &&
              video.link &&
              socialIntegrationsConfigurations[channelName] &&
              socialIntegrationsConfigurations[channelName].validations.video
            ) {
              // Instagram video validations
              if (channelName === 'instagram') {
                if (this.instagramPostingMethod === 'mobile') return

                // Width is common for all instagram post type
                if (
                  video?.width >
                  socialIntegrationsConfigurations.instagram.validations.video
                    .reel_max_width
                ) {
                  errors.push({
                    text: socialIntegrationsConfigurations.instagram.validations
                      .video.reel_max_width_error,
                  })
                }

                if (
                  this.instagramPostType === 'reel' ||
                  this.instagramPostType === 'feed_reel'
                ) {
                  if (
                    video.size >
                    socialIntegrationsConfigurations.instagram.validations.video
                      .reel_max_size
                  ) {
                    errors.push({
                      text: socialIntegrationsConfigurations.instagram
                        .validations.video.reel_max_size_error,
                    })
                  }
                  if (
                    video.duration_seconds >=
                    socialIntegrationsConfigurations.instagram.validations.video
                      .reel_max_duration
                  ) {
                    const currentDuration = video.duration_seconds
                    const totalSeconds = Math.floor(currentDuration)

                    const hours = Math.floor(totalSeconds / 3600)
                    const seconds = totalSeconds % 60

                    const formatMessage = `${
                      hours > 0 ? 'HH [hours] ' : ''
                    } mm [minutes]${seconds > 0 ? ' [and] ss [seconds]' : '.'}`
                    errors.push({
                      text: `${
                        socialIntegrationsConfigurations.instagram.validations
                          .video.reel_max_duration_error
                      } Your video is ${moment
                        .utc(currentDuration * 1000)
                        .format(formatMessage)}`,
                    })
                  }

                  if (
                    video?.height >
                    socialIntegrationsConfigurations.instagram.validations.video
                      .reel_max_height
                  ) {
                    errors.push({
                      text: socialIntegrationsConfigurations.instagram
                        .validations.video.reel_max_height_error,
                    })
                  }
                }

                if (
                  this.instagramShareToStory ||
                  ['carousel', 'story'].includes(this.instagramPostType)
                ) {
                  if (
                    video.size >
                    socialIntegrationsConfigurations.instagram.validations.video
                      .size
                  ) {
                    errors.push({
                      text: socialIntegrationsConfigurations.instagram
                        .validations.video.size_error,
                    })
                  }
                  if (
                    video.duration_seconds >=
                    socialIntegrationsConfigurations.instagram.validations.video
                      .max_duration
                  ) {
                    const currentDuration = video.duration_seconds
                    const formatMessage = `mm [minutes]${
                      moment.utc(currentDuration * 1000).seconds() > 0
                        ? ' [and] ss [seconds]'
                        : '.'
                    }`
                    errors.push({
                      text: `${
                        socialIntegrationsConfigurations.instagram.validations
                          .video.max_duration_error
                      } Your video is ${moment
                        .utc(currentDuration * 1000)
                        .format(formatMessage)}`,
                    })
                  }
                  if (
                    video.width &&
                    video.height &&
                    this.instagramPostType === 'carousel'
                  ) {
                    const aspectRatio = (video.width / video.height).toFixed(2)
                    if (
                      aspectRatio <
                        socialIntegrationsConfigurations[channelName]
                          .validations.video.min_aspect_ratio ||
                      aspectRatio >
                        socialIntegrationsConfigurations[channelName]
                          .validations.video.max_aspect_ratio
                    ) {
                      errors.push({
                        text: socialIntegrationsConfigurations[channelName]
                          .validations.video.aspect_ratio_error,
                      })
                    }
                  }
                }
                // min duration same for all post types
                if (
                  video.duration_seconds <
                  socialIntegrationsConfigurations.instagram.validations.video
                    .min_duration
                ) {
                  errors.push({
                    text: `${socialIntegrationsConfigurations.instagram.validations.video.min_duration_error} Your video is ${video.duration_seconds} seconds.`,
                  })
                }

                const validations =
                  socialIntegrationsConfigurations.instagram.validations.video

                // should be AAC
                if (
                  video.audio_codec &&
                  video.audio_codec.toLowerCase() !== validations.audio_codec
                ) {
                  errors.push({
                    text:
                      'For Instagram API, audio codec for the video needs to be AAC, ' +
                      video.audio_codec +
                      ' provided.',
                  })
                }
                // should be less than or equal to 48khz
                if (
                  video.audio_hertz &&
                  video.audio_hertz > validations.audio_hertz_max
                ) {
                  errors.push({
                    text: `For Instagram API, audio codec for the video needs to be max ${validations.audio_hertz_max}khz, ${video.audio_hertz}khz provided.`,
                  })
                }

                // can either be h264 or hevc
                if (
                  video.video_codec &&
                  !(
                    video.video_codec.toLowerCase() ===
                      validations.video_codec_h264 ||
                    video.video_codec.toLowerCase() ===
                      validations.video_codec_hecv
                  )
                ) {
                  errors.push({
                    text:
                      'For Instagram API, video codec needs to either be H264 or HEVC, ' +
                      video.video_codec +
                      ' provided.',
                  })
                }

                // should be between 23 and 60 fps
                if (
                  video.frame_rate &&
                  (video.frame_rate < validations.frame_rate_min ||
                    video.frame_rate > validations.frame_rate_max)
                ) {
                  errors.push({
                    text:
                      'For Instagram API, video framerate needs to be between 23fps and 60fps, ' +
                      video.frame_rate +
                      'fps provided.',
                  })
                }

                return
              }

              if (
                video.size >
                socialIntegrationsConfigurations[channelName].validations.video
                  .size
              ) {
                const msg =
                  socialIntegrationsConfigurations[channelName].validations
                    .video.size_error
                errors.push({
                  text: msg,
                })
              }
              if (
                video.duration_seconds >
                socialIntegrationsConfigurations[channelName].validations.video
                  .max_duration
              ) {
                const duration = video.duration_seconds
                const msg =
                  socialIntegrationsConfigurations[channelName].validations
                    .video.max_duration_error
                let dynamicMsg = ''
                if (channelName === 'pinterest') {
                  const formatMessage = `mm [minutes]${
                    moment.utc(duration * 1000).seconds() > 0
                      ? ' [and] ss [seconds]'
                      : '.'
                  }`
                  dynamicMsg = `${msg} Your video is ${moment
                    .utc(duration * 1000)
                    .format(formatMessage)}`
                } else {
                  if (video.duration_seconds > 60) {
                    const minutes = Math.floor(duration / 60) // Calculate minutes
                    const seconds = Math.floor(duration % 60) // Calculate remaining seconds
                    dynamicMsg =
                      msg +
                      `Your video is ${minutes} minutes and ${seconds} seconds. Please reduce the length of this video`
                  } else {
                    dynamicMsg =
                      msg +
                      `Your video is ${duration} seconds. Please reduce the length of this video`
                  }
                }
                errors.push({
                  text: dynamicMsg,
                })
              }

              if (
                video.duration_seconds <
                socialIntegrationsConfigurations[channelName].validations.video
                  .min_duration
              ) {
                const msg =
                  socialIntegrationsConfigurations[channelName].validations
                    .video.min_duration_error
                errors.push({
                  text: msg,
                })
              }

              if (
                channelName === 'facebook' &&
                this.facebookOptions.post_type === 'reel'
              ) {
                const fbValidations =
                  socialIntegrationsConfigurations.facebook.validations

                if (video.duration_seconds) {
                  if (
                    video.duration_seconds >
                    fbValidations.video.reel_max_duration
                  ) {
                    errors.push({
                      text: fbValidations.video.reel_max_duration_error,
                    })
                  }

                  if (
                    video.duration_seconds <
                    fbValidations.video.reel_min_duration
                  ) {
                    errors.push({
                      text: fbValidations.video.reel_min_duration_error,
                    })
                  }
                }

                if (video.width && video.height) {
                  if (video.width < fbValidations.video.reel_min_width) {
                    errors.push({
                      text: fbValidations.video.reel_min_width_error,
                    })
                  }

                  if (video.height < fbValidations.video.reel_min_height) {
                    errors.push({
                      text: fbValidations.video.reel_min_height_error,
                    })
                  }

                  const aspectRatio = (video.width / video.height).toFixed(2)
                  if (
                    aspectRatio !==
                    parseFloat(fbValidations.video.reel_aspect_ratio).toFixed(2)
                  ) {
                    errors.push({
                      text: socialIntegrationsConfigurations[channelName]
                        .validations.video.reel_aspect_ratio_error,
                    })
                  }
                }
              }

              if (
                channelName === 'facebook' &&
                this.facebookOptions.post_type === 'story'
              ) {
                const fbValidations =
                  socialIntegrationsConfigurations.facebook.validations

                if (video.duration_seconds) {
                  if (
                    video.duration_seconds >
                    fbValidations.video.story_max_duration
                  ) {
                    errors.push({
                      text: fbValidations.video.story_max_duration_error,
                    })
                  }

                  if (
                    video.duration_seconds <
                    fbValidations.video.story_min_duration
                  ) {
                    errors.push({
                      text: fbValidations.video.story_min_duration_error,
                    })
                  }

                  if (video.width && video.height) {
                    if (video.width < fbValidations.video.story_min_width) {
                      errors.push({
                        text: fbValidations.video.story_min_width_error,
                      })
                    }

                    if (video.height < fbValidations.video.story_min_height) {
                      errors.push({
                        text: fbValidations.video.story_min_height_error,
                      })
                    }

                    const aspectRatio = (video.width / video.height).toFixed(2)
                    if (
                      aspectRatio !==
                      parseFloat(
                        fbValidations.video.story_aspect_ratio
                      ).toFixed(2)
                    ) {
                      errors.push({
                        text: socialIntegrationsConfigurations[channelName]
                          .validations.video.story_aspect_ratio_error,
                      })
                    }
                  }
                }
              }

              if (
                channelName === 'youtube' &&
                this.youtubeOptions.post_type &&
                this.youtubeOptions.post_type === 'short'
              ) {
                if (video.width && video.height && video.width > video.height) {
                  errors.push({
                    text: 'Video should be in square or vertical orientation for Youtube shorts',
                  })
                }
                if (
                  video.duration_seconds &&
                  video.duration_seconds >
                    socialIntegrationsConfigurations.youtube.validations.video
                      .shorts_max_duration
                ) {
                  errors.push({
                    text: 'Video duration should be less than or equal to 180 seconds for Youtube shorts',
                  })
                }
              }

              if (channelName === 'tiktok') {
                if (video.width < 360 || video.height < 360) {
                  errors.push({
                    text: 'Video should be at least 360 pixels both for height and width',
                  })
                }
                if (
                  !video.converted_mime_type.includes('video/mp4') &&
                  !video.converted_mime_type.includes('video/webm') &&
                  !video.converted_mime_type.includes('video/quicktime')
                ) {
                  errors.push({
                    text: 'Video should be either .MP4 (recommended), WebM, or .MOV',
                  })
                }
              }
            }
          })
        }
      }

      let filteredErrors = errors.filter((error) => error.text.trim() !== '')
      filteredErrors = filteredErrors.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.text === obj.text)
      )
      return filteredErrors
    },
    /**
     * editor media box multimedia validations calculations
     * @returns {[{text: string}]|*[]}
     */
    multimediaErrors() {
      console.debug(
        'COMPUTE::multimediaErrors',
        this.common_box_status,
        this.account_selection
      )

      const multimedia =
        this.common_box_status &&
        this.sharing_details.common_sharing_details?.multimedia
          ? this.sharing_details.common_sharing_details.multimedia
          : []
      const hasMultimedia =
        multimedia.some((item) => typeof item === 'object') &&
        multimedia.some((item) => typeof item === 'string')
      const hasMultipleVideos =
        multimedia.filter((item) => typeof item === 'object').length > 1
      const accountSelection = this.account_selection
      for (const channelName in accountSelection) {
        if (
          this.isInvalidMediaSelection(
            accountSelection,
            channelName,
            hasMultimedia,
            hasMultipleVideos
          )
        ) {
          return [
            {
              text: 'Multiple types of media (images and videos) are now supported for Threads, Facebook, and Instagram (direct publishing). Other platforms allow either images or videos per post.',
            },
          ]
        }
      }

      return []
    },
    /**
     * editor media box images validations calclations
     * @returns {*[]}
     */
    imageErrors() {
      console.log('COMPUTE::imageErrors')

      const errors = []

      const mediaDetails = this.getSocialSharingMediaDetails
      const accountSelection = this.account_selection

      for (const channelName in accountSelection) {
        if (
          channelName === 'tiktok' &&
          this.tiktokOptions?.post_type !== 'carousel'
        ) {
          continue
        }

        const detail = this.common_box_status
          ? this.sharing_details.common_sharing_details
          : this.sharing_details[`${channelName}_sharing_details`]

        let image = []
        if (
          (MULTIMEDIA_ALLOWED_PLATFORMS.includes(channelName) ||
            this.common_box_status) &&
          detail?.multimedia?.length
        ) {
          image = detail.multimedia.filter((media) => typeof media === 'string')
        } else {
          image = detail?.image
        }

        if (this.threadsOptions.has_multi_threads && image) {
          const threads = this.threadsOptions.multi_threads
          threads.forEach((thread) => {
            if (thread?.multimedia?.length) {
              const threadsImages = thread.multimedia.filter(
                (media) => typeof media === 'string'
              )
              image = image.concat(threadsImages || [])
            }
          })
        }

        if (this.blueskyOptions.has_multi_bluesky && image) {
          const blueskys = this.blueskyOptions.multi_bluesky
          blueskys.forEach((bluesky) => {
            if (bluesky?.image?.length) {
              image = image.concat(bluesky.image || [])
            }
          })
        }

        if (accountSelection[channelName].length) {
          ;[].concat(image).every((img, indx) => {
            // image validation checks
            if (
              mediaDetails[img] &&
              mediaDetails[img].mime_type !== 'image/gif'
            ) {
              if (
                socialIntegrationsConfigurations[channelName].validations.image
              ) {
                // file max size check
                if (
                  mediaDetails[img].size >
                  socialIntegrationsConfigurations[channelName].validations
                    .image.max_size
                ) {
                  const msg =
                    mediaDetails[img].length > 1
                      ? socialIntegrationsConfigurations[channelName]
                          .validations.image.max_size_multiple_images_error
                      : socialIntegrationsConfigurations[channelName]
                          .validations.image.max_size_single_image_error
                  if (channelName === 'instagram') {
                    if (this.instagramPostingMethod === 'api') {
                      errors.push({
                        text: msg,
                      })
                    }
                  } else {
                    errors.push({
                      text: msg,
                    })
                  }
                  return false
                }

                // file min size check
                if (
                  mediaDetails[img].size <
                  socialIntegrationsConfigurations[channelName].validations
                    .image.min_size
                ) {
                  const msg =
                    image.length > 1
                      ? socialIntegrationsConfigurations[channelName]
                          .validations.image.min_size_multiple_images_error
                      : socialIntegrationsConfigurations[channelName]
                          .validations.image.min_size_single_image_error
                  if (channelName === 'instagram') {
                    if (this.instagramPostingMethod === 'api') {
                      errors.push({
                        text: msg,
                      })
                    }
                  } else {
                    errors.push({
                      text: msg,
                    })
                  }
                  return false
                }

                // file min height check
                if (
                  indx === 0 &&
                  (mediaDetails[img].height <
                    socialIntegrationsConfigurations[channelName].validations
                      .image.min_height ||
                    mediaDetails[img].width <
                      socialIntegrationsConfigurations[channelName].validations
                        .image.min_width)
                ) {
                  const msg =
                    image.length > 1
                      ? socialIntegrationsConfigurations[channelName]
                          .validations.image.min_dimension_multiple_images_error
                      : socialIntegrationsConfigurations[channelName]
                          .validations.image.min_dimension_single_image_error
                  if (channelName === 'instagram') {
                    if (this.instagramPostingMethod === 'api') {
                      errors.push({
                        text: msg,
                      })
                    }
                  }else if(channelName === 'gmb'){
                    if(mediaDetails[img]?.height && mediaDetails[img].width ){
                      errors.push({
                        text: msg,
                      })
                    }
                  } else {
                    errors.push({
                      text: msg,
                    })
                  }
                  return false
                }

                // file max height check
                if (
                  mediaDetails[img].height >
                    socialIntegrationsConfigurations[channelName].validations
                      .image.max_height ||
                  mediaDetails[img].width >
                    socialIntegrationsConfigurations[channelName].validations
                      .image.max_width
                ) {
                  const msg =
                    image.length > 1
                      ? socialIntegrationsConfigurations[channelName]
                          .validations.image.max_dimension_multiple_images_error
                      : socialIntegrationsConfigurations[channelName]
                          .validations.image.max_dimension_single_image_error
                  if (channelName === 'instagram') {
                    if (this.instagramPostingMethod === 'api') {
                      errors.push({
                        text: msg,
                      })
                    }
                  }else if(channelName === 'gmb'){
                    if(mediaDetails[img]?.height && mediaDetails[img].width ){
                      errors.push({
                        text: msg,
                      })
                    }
                  }else if(channelName === 'gmb'){
                    if(mediaDetails[img]?.height && mediaDetails[img].width ){
                      errors.push({
                        text: msg,
                      })
                    }
                  } else {
                    errors.push({
                      text: msg,
                    })
                  }
                  return false
                }
              }
            }

            // validation check for tiktok images file format
            if (channelName === 'tiktok' && mediaDetails[img]?.mime_type) {
              if (
                !socialIntegrationsConfigurations.tiktok.validations?.image?.allowed_formats?.includes(
                  mediaDetails[img]?.mime_type
                )
              ) {
                errors.push({
                  text: socialIntegrationsConfigurations.tiktok.validations
                    .image.format_error,
                })
              }
            }

            // gifs validations
            if (
              mediaDetails[img] &&
              mediaDetails[img].mime_type === 'image/gif'
            ) {
              if (channelName === 'instagram' || channelName === 'tiktok') {
                errors.push({
                  text: socialIntegrationsConfigurations[channelName]
                    .validations.gif.error,
                })
              }
              if (
                socialIntegrationsConfigurations[channelName].validations.gif
              ) {
                // file max size check
                if (
                  mediaDetails[img].size >
                  socialIntegrationsConfigurations[channelName].validations.gif
                    .max_size
                ) {
                  errors.push({
                    text: socialIntegrationsConfigurations[channelName]
                      .validations.gif.size_error,
                  })
                  return false
                }
              }
            }

            // file max height check
            if (
              mediaDetails[img] &&
              (mediaDetails[img].height >
                socialIntegrationsConfigurations[channelName].validations.gif
                  .max_height ||
                mediaDetails[img].width >
                  socialIntegrationsConfigurations[channelName].validations.gif
                    .max_width)
            ) {
              if (channelName === 'instagram') {
                if (this.instagramPostingMethod === 'api') {
                  errors.push({
                    text: socialIntegrationsConfigurations[channelName]
                      .validations.gif.max_dimension_error,
                  })
                } else {
                  errors.push({
                    text: socialIntegrationsConfigurations[channelName]
                      .validations.gif.max_dimension_error,
                  })
                }
                return false
              }
            }

            // instagram dimension check
            if (
              channelName === 'instagram' &&
              this.instagramPostingMethod === 'api' &&
              this.instagramPostType !== 'story' &&
              mediaDetails[img] &&
              mediaDetails[img].zapier &&
              !mediaDetails[img].zapier.status
            ) {
              const msg =
                image.length > 1
                  ? socialIntegrationsConfigurations[channelName].validations
                      .image.min_dimension_multiple_images_error
                  : socialIntegrationsConfigurations[channelName].validations
                      .image.min_dimension_single_image_error
              errors.push({
                text: msg,
              })
              return false
            }



            return true
          })
        }
      }

      return errors
    },

    /**
     * social post warning validations calculations
     * @returns {*[]}
     */
    socialPostWarnings() {
      console.log('COMPUTE::socialPostWarnings')

      const warnings = []

      if ('twitter' in this.account_selection) {
        if (
          this.account_selection.twitter.length &&
          this.publishTimeOptions.repeat === 'repeat'
        ) {
          warnings.push({
            text: 'Repeat posting are not allowed on X (Twitter)',
          })
        }

      if (
        this.account_selection?.twitter?.length > 0 &&
          ((this.twitterLinkDetails?.url &&
            this.twitterLinkDetails?.image?.length > 0 &&
            this.twitterLinkDetails?.message?.length > 280) ||
          (this.twitterOptions?.has_threaded_tweets &&
            this.twitterOptions?.threaded_tweets?.some(
              (tweet) =>
                tweet?.message?.length > 280 &&
                tweet?.image?.length > 0 &&
                tweet?.url
            )))
        ) {
          warnings.push({
            text: 'Long posts on X(twitter) will never show Link preview.',
          })
        }
      }

      if ('instagram' in this.account_selection) {
        if (
          this.account_selection.instagram.length &&
          (this.instagramPostType === 'story' || this.instagramShareToStory) &&
          this.firstComment.has_first_comment
        ) {
          warnings.push({
            text: 'Comments are not supported for Instagram story posts.',
          })
        }
      }

      const accountSelection = this.account_selection

      for (const channelName in accountSelection) {
        const details = this.common_box_status
          ? this.sharing_details.common_sharing_details
          : this.sharing_details[`${channelName}_sharing_details`]

        if (channelName === 'tiktok' && accountSelection.tiktok.length && getLinksFromText(details?.message)) {
          warnings.push({
            text: '<span>Links are not supported on TikTok, to learn more about posting on TikTok<a class="ml-1" href="#" data-beacon-article-modal="6225cf722ce7ed0fb09127ee">click here</a>.</span>',
          })

        }

        if (accountSelection[channelName].length) {
          // Handling the maximum image waring for the channel
          const maxImages =
            socialIntegrationsConfigurations[channelName]?.warnings?.image
              ?.max_images
          const maxImagesWarning =
            socialIntegrationsConfigurations[channelName]?.warnings?.image
              ?.max_images_warning

          if (maxImages && maxImagesWarning) {
            const totalImages = MULTIMEDIA_ALLOWED_PLATFORMS.includes(
              channelName
            )
              ? details?.multimedia?.length
              : details?.image?.length

            if ((totalImages > maxImages) && channelName !== 'facebook') {
              warnings.push({
                text: maxImagesWarning,
              })
            }

            if ((totalImages > maxImages) && (channelName === 'facebook' && this.carouselOptions?.is_carousel_post === true)) {
              warnings.push({
                text: maxImagesWarning,
              })
            }

            // Handling the maximum image waring for multi threads
            if (
              channelName === 'threads' &&
              this.threadsOptions.has_multi_threads
            ) {
              const threads = this.threadsOptions.multi_threads
              const hasThreadExceedingLimit = threads.some(
                (thread) => thread?.multimedia?.length > maxImages
              )
              if (hasThreadExceedingLimit) {
                warnings.push({
                  text: maxImagesWarning,
                })
              }
            }
          }

          // Handling the maximum image waring for multi bluesky
          if (
              channelName === 'bluesky' &&
              this.blueskyOptions.has_multi_bluesky
          ) {
            const blueskys = this.blueskyOptions.multi_bluesky
            const hasBlueskyExceedingLimit = blueskys.some(
                (bluesky) => bluesky?.image?.length > maxImages
            )
            if (hasBlueskyExceedingLimit) {
              warnings.push({
                text: maxImagesWarning,
              })
            }
          }

          if (
            channelName === 'facebook' &&
            this.facebookOptions?.facebook_background_id
          ) {
            const warningsConfig =
              socialIntegrationsConfigurations[channelName].warnings

            if (
              this.facebookGroupSelected &&
              this.facebookPageSelected &&
              this.facebookOptions?.facebook_background_id?.length &&
              !this.carousel?.is_carousel_post &&
              !details.url &&
              !details.image.length &&
              !details.video.thumbnail
            ) {
              warnings.push({
                text: warningsConfig.facebook_page_template_message,
              })
            }
          }

          // if facebook reel or story is selected and a group account is also selected, push facebook warning
          if (
            channelName === 'facebook' &&
            (this.facebookOptions.post_type === 'reel' ||
              this.facebookOptions.post_type === 'story' ||
                this.facebookOptions?.facebook_share_to_story
            ) &&
            this.facebookGroupSelected &&
            this.facebookPageSelected &&
            this.publishTimeOptions.time_type !== 'draft'
          ) {
            warnings.push({
              text: socialIntegrationsConfigurations[channelName]?.warnings
                ?.posting_as_feed,
            })
          }
        }
      }

      return warnings
    },

    contentCategoryList() {
      return this.getContentCategoryList
    },

    getFooterData() {
      console.log('COMPUTE::getFooterData')

      return {
        warnings: this.socialPostWarnings.concat(
          this.carouselPostErrors.warnings
        ),
        errors: this.multimediaErrors.concat(
          this.socialPostErrors,
          this.videoErrors,
          this.imageErrors,
          this.errors,
          this.threadedTweetsErrors.errors,
          this.multiThreadsErrors.errors,
          this.multiBlueskyErrors.errors,
          this.carouselPostErrors.errors
        ),
        publishTimeOptions: this.publishTimeOptions,
        approvalData: this.approval,
        globalContentCategory: this.globalContentCategory,
        postHasErrors: this.postHasErrors,
        isApprover: this.isApprover,
        nextContentCategorySlot: this.nextContentCategorySlot,
      }
    },

    globalContentCategory() {
      if (this.selectedContentCategory !== null) {
        return this.selectedContentCategory.category_state === 'global'
      }
      return false
    },
    username: function () {
      return this.getProfile.firstname
    },
    postHasErrors() {
      console.log('METHOD::postHasErrors -> ')
      return (
        this.multimediaErrors.length > 0 ||
        this.videoErrors.length > 0 ||
        this.imageErrors.length > 0 ||
        this.instagramErrors.length > 0 ||
        this.socialPostErrors.length > 0 ||
        this.gmbErrors.length > 0 ||
        this.youtubeErrors.length > 0 ||
        this.threadedTweetsErrors.errors.length > 0 ||
        this.multiThreadsErrors.errors.length > 0 ||
        this.multiBlueskyErrors.errors.length > 0 ||
        this.carouselPostErrors.errors.length > 0 ||
        this.errors.length > 0
      )
    },
    getCarouselValidAccounts() {
      return this.accountsList.filter(
        (account) =>
          account.channel_type === 'facebook' &&
          account.checked &&
          account.type === 'Page'
      )
    },
    getCarouselUrl() {
      const detail = this.common_box_status
        ? this.sharing_details.common_sharing_details
        : this.sharing_details.facebook_sharing_details
      const link = {}
      if (detail.url) {
        link.url = detail.url
        link.title = detail.title ? detail.title : ''
        link.description = detail.description ? detail.description : ''
        link.image = detail.image ? detail.image[0] : []
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (link.url) this.carouselUrl = link
      return this.carouselUrl
    },
  },
  watch: {
    'youtubeOptions.privacy_status': function () {
      this.updateFirstCommentSelectedAccounts('youtube')
    },
    'youtubeOptions.made_for_kids': function () {
      this.updateFirstCommentSelectedAccounts('youtube')
    },
    instagramPostingMethod: function (val) {
      this.updateFirstCommentSelectedAccounts('instagram')
      // if (val === 'mobile') this.instagramPostType = 'feed' //  no need to change post type when posting method change
    },
  },
  mounted() {
    EventBus.$on('set-facebook-post-type', (val) => {
      this.setFacebookPostType(val)
    })

    EventBus.$on(
      'facebook-group-selected',
      (val) => (this.facebookGroupSelected = val)
    )

    EventBus.$on(
      'facebook-page-selected',
      (val) => (this.facebookPageSelected = val)
    )

    EventBus.$on('publication-composer-post-loader', (loader) => {
      this.loader = loader
    })

    EventBus.$on(
      'publication-composer-post',
      this.handlePublicationComposerPost
    )

    EventBus.$on('reset-composer-data', () => {
      this.draftPlanId = ''
      this.isDraftComposer = false
      this.resetComponentData()
    })

    EventBus.$on('selected-accounts-length', (value) => {
      this.selectedAccountsLength = value
    })

    EventBus.$on('force-disable-schedule-button', () => {
      this.forceDisableButton = true
    })

    EventBus.$on('add-media-to-editor', (media) => {
      this.addMediaToEditor(media)
    })
    const workspace = this.$route.params.workspace || ''
    if (
      location.pathname.includes('/composer/') &&
      !location.pathname.includes('/blog') &&
      workspace &&
      workspace === this.getActiveWorkspace.slug
    ) {
      this.isDirectUrl = true
      setTimeout(() => {
        if (this.$route.params.id)
          this.editPublication(
            this.$route.params.id,
            false,
            false,
            true,
            'social'
          )
        else this.changePublishType('Composer Social')
      }, 1000) // wait for 1 second.
    }

    const element = document.getElementById('gs')
    if (element) element.classList.add('hidden')
  },
  beforeUnmount() {
    console.debug('SocialModal beforeUnmount')
    if (this.intervalId) window.clearInterval(this.intervalId)

    EventBus.$off('publication-composer-post-loader')
    EventBus.$off('publication-composer-post')
    EventBus.$off('reset-composer-data')
    EventBus.$off('set-facebook-post-type')
    EventBus.$off('selected-accounts-length')
    EventBus.$off('facebook-group-selected')
    EventBus.$off('facebook-page-selected')
    EventBus.$off('repeat-post-error')
    EventBus.$off('add-media-to-editor')

    const element = document.getElementById('gs')
    if (element) element.classList.remove('hidden')
  },
  methods: {
    updateLocation(payload) {

      const composerType = this.common_box_status ? 'common' : this.activeTab

      if(composerType==='common') {
        this.locations = payload
      }else if(composerType==='facebook' || composerType==='instagram') {

        // if payload is empty then return then remove the locations for facebook and instagram platforms
        // map over the locations  and  remove the location if platform is facebook or instagram
        if(!Object.keys(payload).length) {
          // map over the locations  and  remove the location if platform is facebook or instagram locations = { {}, {}, {} } structure
          const filteredLocations = {}
          Object.entries(this.locations).forEach(([accountId, location]) => {
          if (location.platform !== composerType) {
            filteredLocations[accountId] = location
           }
          })
          this.locations = filteredLocations

        }else {
          this.locations = {
            ...this.locations,
            ...payload
          }
        }

      }
    },
    handleRepeatPostIntervalError(payload) {
      const intervalErrorMessage = 'Repeat Interval must be greater than or equal to 3 Day(s)'
      const repeatTimeErrorMessage = 'Repeat Time must be greater than or equal to 1 Time(s)'
      // Check if the payload interval error is true then check in errors and if not present then push the error in errors if false then remove the error from errors
      if (payload.intervalError) {
        if (!this.errors.some((error) => error.text === intervalErrorMessage)) {
          this.errors.push({
            text: intervalErrorMessage,
          })
        }
      } else {
        this.errors = this.errors.filter((error) => error.text !== intervalErrorMessage)
      }

      // Check if the payload repeat time error is true then check in errors and if not present then push the error in errors if false then remove the error from errors
      if (payload.repeatTimeError) {
        if (!this.errors.some((error) => error.text === repeatTimeErrorMessage)) {
          this.errors.push({
            text: repeatTimeErrorMessage,
          })
        }
      } else {
        this.errors = this.errors.filter((error) => error.text !== repeatTimeErrorMessage)
      }
      },
    /**
     * this method is responsible for minimizing the composer
     */
    minimizeComposer() {
      this.isComposerModalOpen = false
      commonMethods?.toggleAiChat(false)
      this.isDraftComposer = true
      this.autoSave(false)
      this.stopAutoSave()
      this.$bvModal.hide('composer-modal')
      this.resetRoute(true)
    },
    /**
     * check if the media selection is invalid
     * @param accountSelection
     * @param channelName
     * @param hasMultimedia
     * @param hasMultipleVideos
     * @returns {false|*}
     */
    isInvalidMediaSelection(
      accountSelection,
      channelName,
      hasMultimedia,
      hasMultipleVideos
    ) {
      return (
        accountSelection[channelName]?.length &&
        (!MULTIMEDIA_ALLOWED_PLATFORMS.includes(channelName) ||
          (MULTIMEDIA_ALLOWED_PLATFORMS.includes(channelName) &&
            this.instagramPostingMethod !== 'api')) &&
        (hasMultimedia || hasMultipleVideos)
      )
    },
    handleModalOpen() {
      // Use $nextTick to ensure DOM is updated
      this.$nextTick(() => {
        const doc = document.getElementById('composer-modal')
        doc?.classList.add('mac-open')
        doc?.classList.remove('mac-close')
      })

      this.isGridView = false
      this.isComposerModalOpen = true
      this.isDraftComposer = false
      this.draftPlanId = ''
      commonMethods?.toggleAiChat(true)
    },
    handleModalClose() {
      const doc = document.getElementById('composer-modal')
      doc?.classList.add('mac-close')
      doc?.classList.remove('mac-open')

      // Wait for animation to complete before actually closing the modal
      setTimeout(() => {
        this.$refs['composer-modal'].hide()
      }, 300) // Match this to your animation duration
    },
    async handlePublicationComposerPost(payload) {
      this.resetComponentData()
      this.previousRoute = this.$route.fullPath
      this.loader = true
      this.$store.commit(commonTypes.RESET_TWITTER_VARIATIONS)
      this.$store.commit(approvalStages.RESET_APPROVAL_DATA)
      this.mobileDevices = this.getMobileDevices

      if (this.publishTimeOptions && this.publishTimeOptions.post_date)
        this.publishTimeOptions.post_date = moment()
          .tz(this.getWorkspaces.activeWorkspace.timezone)
          .add(10, 'minutes')
          .format('MMM DD, YYYY HH:mm')
      this.setSocialSharingTagsSuggestionsItems([])
      this.handleFacebookPostedAs()
      // this.accountsList = [...this.getSocialAccountsList]
      this.accountsList = clone(this.getSocialAccountsList) // setting up social accounts list.
      this.initAccountSelection()
      EventBus.$emit('AiCaptionModal_reset')
      this.isApprover = this.getUserRole === 'Approver'
      this.socialPostType = payload.mode
      switch (payload.mode) {
        case 'new':
          await this.newPost(payload)
          break
        case 'edit':
          await this.editPost(payload)
          break
        case 'discovery-post':
          this.initializeDiscoveryPost(payload)
          break
        case 'share-media':
          this.initializeShareMediaPost(payload)
          break
        case 'edit-csv-post':
          this.editCSVPost(payload.plan)
          break
      }
      this.fetchUtms()

      if (this.planId) {
        const workspace = this.$route.params.workspace
        history.pushState({}, null, `/${workspace}/composer/${this.planId}`)
      }
    },
    checkedAllSelectedAccount(accountSelection) {
      this.accountsList.forEach((channel) => {
        accountSelection[channel.channel_type].forEach((item) => {
          if (item === channel[channel.channel_identifier]) {
            this.account_selection[channel.channel_type].push(item)
            channel.checked = true
          }
        })
      })
    },
    copyRouteDetail() {
      this.processPlanLoader = false
      const route = this.previousRoute
      const directUrl = !!this.isDirectUrl
      Object.assign(this.$data, initialDataState())
      if (this.intervalId) clearInterval(this.intervalId)
      this.intervalId = null
      this.previousRoute = route
      this.isDirectUrl = directUrl
    },
    async onCloseConfirmation(event) {
      // We will this only when user wants to close the composer only
      if (this.isDraftComposer) {
        this.isComposerModalOpen = false
        return
      }

      if (this.modalClosable) {
        this.isComposerModalOpen = false
        this.modalClosable = false
      } else {
        event.preventDefault()
        const res = await this.$root.$bvModal.msgBoxConfirm(
          'Make sure, your changes are auto saved or you have manually saved it (draft) before exiting',
          {
            title: 'Close Composer',
            ...swalAttributes(),
            cancelTitle: 'Cancel',
            okTitle: 'Exit Anyway',
          }
        )
        if (res) {
          this.modalClosable = true
          localStorage.removeItem('composerWidgetPosition')

          // reset show New Chat
          this.showNewChat = false

          this.$store.commit(sharingTypes.SET_SOCIAL_SHARING_MEDIA_DETAILS, {})
          this.resetComponentData()
          this.$bvModal.hide('composer-modal')
          this.$refs['image-generator'].clearState()
          this.isComposerModalOpen = false

          // can't show chat widget on any of the grid views
          if (!this.previousRoute?.includes('grid-view')) {
            commonMethods?.toggleAiChat(false)
          }
        }
      }
    },
    onImageTagClick(imageUrl, type) {
      // get existing tags
      let existingImageTags =
        this.sharing_details[type + '_sharing_details'].image_tagging_details ||
        {}
      let mentionsList = []
      if (existingImageTags && existingImageTags[imageUrl]) {
        mentionsList = existingImageTags[imageUrl]
      }

      EventBus.$emit('profile-tagging-modal-show', {
        mentions: [...mentionsList],
        imageUrl,
        emitter: (mentions) => {
          // create a new tag object
          const newImageTags = {}

          // add the existing tag to the new object
          if (existingImageTags[imageUrl]) {
            newImageTags[imageUrl] = existingImageTags[imageUrl]
          }

          newImageTags[imageUrl] = []

          // add the new tag to the new object
          for (const mention of mentions) {
            newImageTags[imageUrl].push({
              x: mention.x,
              y: mention.y,
              username: mention.username,
            })
          }

          // set the new tags
          existingImageTags = { ...existingImageTags, ...newImageTags }

          this.sharing_details[
            type + '_sharing_details'
          ].image_tagging_details = existingImageTags
        },
      })
    },
    resetComponentData() {
      // When composer in in draft state we will not reset the data
      if (this.isDraftComposer) {
        return
      }

      if (this.previousRoute?.includes('grid-view')) {
        this.isGridView = true
      }

      console.debug('METHOD::resetComponentData', location.pathname)
      this.resetRoute(this.isDraftComposer)
      this.isDraftComposer = false
      Object.assign(this.$data, initialDataState())
      if (this.intervalId) clearInterval(this.intervalId)
      this.intervalId = null

      const element = document.getElementById('gs')
      if (element) element.classList.remove('hidden')
      EventBus.$emit('AiCaptionModal_reset')
      EventBus.$emit('RefreshPlans_event')
    },
    /**
     * reset the route to the previous route
     */
    resetRoute(isMinimizeCall = false) {
      this.draftPlanId = this.planId

      if (isMinimizeCall) {
        history.replaceState({}, null, `${this.$route.fullPath}`)
        return
      }

      if (
        (location.pathname.includes('/composer/') &&
          this.socialPostType === 'new') ||
        this.socialPostType === 'edit'
      ) {
        if ((this.planId || this.isDuplicatePost) && !this.isDirectUrl)
          history.replaceState({}, null, `${this.previousRoute}`)
        // this.$router.push(this.previousRoute)
        else if (this.isDirectUrl) {
          this.isDirectUrl = false
          this.$router.push({ name: 'planner_list_v2' })
        }
      }
    },
    async newPost(payload, updateLoader = true) {
      this.common_box_status = true
      this.sharing_details = clone(defaultSharingDetails)
      this.sharing_details.common_sharing_details.message = payload.description ?? ''
      this.instagramShareToStory = false
      this.instagramCollaborators = []
      this.instagramPostingMethod = 'api'
      this.initSharingOptions()
      if (payload.timeType) this.publishTimeOptions.time_type = payload.timeType
      if (payload.postDate) this.publishTimeOptions.post_date = payload.postDate
      else {
        this.publishTimeOptions.post_date = moment()
          .tz(this.getWorkspaces.activeWorkspace.timezone)
          .add(1, 'hours')
          .format('MMM DD, YYYY HH:mm')
      }
      if (updateLoader) this.loader = false
      await this.autoSave(false)
      // Auto save draft post
      this.startAutoSave()
    },
    /**
     * This method is responsible for auto save the composer
     */
    startAutoSave() {
      if (this.intervalId) clearInterval(this.intervalId)

      this.intervalId = setInterval(() => {
        console.debug('SocialModal AutoSave', this.processPlanLoader)
        if (
          !this.isDraftComposer &&
          !this.globalContentCategory &&
          !this.processPlanLoader
        ) {
          this.autoSave()
        }
      }, 30000) // 30 seconds
    },
    /**
     * This method is responsible for stopping the auto save
     */
    stopAutoSave() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
    },
    async editPost(payload) {
      this.loader = true
      try {
        this.reopen = payload.reopen
        const response = await this.CSFetchPost(payload.id) // fetching post data
        if (response) {
          const plan = response.plan || null
          if (plan) {
            console.log('SocialModal EditPost', plan)
            this.instagramPostingMethod = plan.instagram_posting_option || null
            this.instagramPostType = plan.instagram_post_type || 'feed'
            this.instagramShareToStory = plan?.instagram_share_to_story ?? false

            if (plan.instagram_post_type !== 'story') {
              this.instagramCollaborators = plan?.instagram_collaborators || []
            }

            // setting the facebook template id
            if (plan.facebook_options?.facebook_background_id) {
              this.facebookOptions.facebook_background_id =
                plan.facebook_options.facebook_background_id
            }

            // setting the facebook share to story
            if (plan.facebook_options?.facebook_share_to_story) {
              this.facebookOptions.facebook_share_to_story =
                  plan.facebook_options.facebook_share_to_story
            }

            this.common_box_status = plan.common_box_status

            // Handling the multimedia data on edit plan
            if (this.common_box_status) {
              this.onEditHandleMultimedia(plan.common_sharing_details, 'common')
            } else {
              MULTIMEDIA_ALLOWED_PLATFORMS.forEach((item) => {
                if (plan[`${item}_sharing_details`])
                  this.onEditHandleMultimedia(
                    plan[`${item}_sharing_details`],
                    item
                  )
              })
            }

            // locations
            if (plan?.locations) {
              this.locations = plan.locations
            }

            if (response.activities) {
              this.activities = response.activities
            }

            if (payload.duplicate === true) {
              this.planId = null
              this.postStatus = ''
              this.repost = true
              const workspace = this.$route.params.workspace
              history.pushState({}, null, `/${workspace}/composer`)
              this.isDuplicatePost = true

              // set the comments, and tasks
              this.comments = []
              this.tasks = []
              this.activities = { hits: [] }
              this.onChangeContentCategory(
                this.getContentCategoryById(plan.content_category_id) || null
              )
            } else {
              // in case of the existing post, get the category and other details.
              this.planId = plan._id
              this.onChangeContentCategory(
                this.getContentCategoryById(plan.content_category_id) || null
              )
              // this.selectedContentCategory = this.getContentCategoryById(plan.content_category_id) || null
              this.postStatus = plan.status
              if (plan.approval && plan.approval.status) {
                this.approval = plan.approval
              }
              // set the comments, and tasks
              this.tasks = plan.tasks

              this.comments = this.filterComments(plan.comments)

              // Map over the external comments and actions and append them to the comments array and add external_comment property to true
              if (plan.external_comments) {
                plan.external_comments.forEach((comment) => {
                  this.comments.push({
                    ...comment,
                    external_comment: true,
                  })
                })
              }
              if (plan.external_actions) {
                plan.external_actions.forEach((action) => {
                  this.comments.push({
                    ...action,
                    external_comment: true,
                  })
                })
              }

            }

            // this.$store.commit(blogPosting.SET_BLOG_POSTING_TYPE, plan.type) : TODO : check if this is needed.

            if (
              (plan.type === 'Composer Social' ||
                plan.type === 'Composer Article') &&
              !plan.folderId
            ) {
              this.folderId = 'default'
            } else {
              this.folderId = plan.folderId
            }
            this.labels = plan.labels
            this.members = plan.members

            this.publish_source = plan.publish_source || ''

            // set the execution time.
            this.execution_time = plan.execution_time

            // if it exists, it is the blog post. (dealing blog case)

            if (plan.blog_reference) {
              this.alertMessage(
                'Cannot open Blog post in Social Modal',
                'error'
              )
              return false // TODO : what to do here if its blog post?
            } else {
              // social case.

              const defaultTimeSelection = plan.publish_time_options
                ? plan.publish_time_options
                : defaultPublishTimeOptions

              if (payload.duplicate) {
                defaultTimeSelection.post_date = moment()
                  .tz(this.getActiveWorkspace.timezone)
                  .add(1, 'hours')
                  .format('MMM DD, YYYY hh:mm A')
              } else {
                defaultTimeSelection.post_date = moment
                  .utc(plan.execution_time.date, 'YYYY-MM-DD HH:mm')
                  .tz(this.getWorkspaces.activeWorkspace.timezone)
                  .format('MMMM DD, YYYY HH:mm')
              }
              if (plan.status === 'review') {
                defaultTimeSelection.plan_status = 'Under Review'
              }

              if (plan.status !== 'draft') {
                defaultTimeSelection.repeat = 1
                defaultTimeSelection.repeat_type = 'Day'
                defaultTimeSelection.repeat_times = 1
                defaultTimeSelection.repeat_gap = 1
              }

              // in case of collaborator which don't have access to post now
              if (
                !this.hasPermission('can_schedule_plan') &&
                defaultTimeSelection.time_type === 'now'
              ) {
                defaultTimeSelection.time_type = 'queued'
              }

              this.publishTimeOptions = defaultTimeSelection

              plan.common_sharing_details.message = he.decode(
                plan.common_sharing_details.message
              )
              this.sharing_details.common_sharing_details = clone(
                plan.common_sharing_details
              )
              const platforms = socialPlatformNames()
              platforms.forEach((platform) => {
                if (
                  plan[platform + '_sharing_details'] &&
                  !Array.isArray(plan[platform + '_sharing_details'])
                ) {
                  plan[platform + '_sharing_details'].message = he.decode(
                    plan[platform + '_sharing_details'].message || ''
                  )
                  this.sharing_details[platform + '_sharing_details'] = clone(
                    plan[platform + '_sharing_details'] ||
                      defaultSharingDetails[platform + '_sharing_details'] ||
                      {}
                  )
                }
              })
              // for sharing_media_details store is used. so we need to set sharing_media_details in store.
              this.$store.commit(
                sharingTypes.SET_SOCIAL_SHARING_MEDIA_DETAILS,
                plan.sharing_media_details ? plan.sharing_media_details : {}
              )
              // Youtube Options
              const ytOptions = clone(
                plan.youtube_options || defaultYoutubeOptions
              )
              ytOptions.post_type = ytOptions.post_type || 'video'
              this.youtubeOptions = ytOptions

              this.carouselOptions = clone(
                plan.carousel_options || defaultCarouselOptions
              )
              this.linkedin_options = clone(
                plan.linkedin_options || defaultLinkedinOptions
              )
              this.gmbOptions = clone(plan.gmb_options || defaultGmbOptions)
              this.pinterestOptions = clone(
                plan.pinterest_options || defaultPinterestOptions
              )
              this.twitterOptions = clone(
                plan.twitter_options || defaultTwitterOptions
              )

              this.threadsOptions = clone(
                plan.threads_options || defaultThreadsOptions
              )

              this.blueskyOptions = clone(
                  plan.bluesky_options || defaultBlueskyOptions
              )

              this.tiktokOptions = clone(
                plan.tiktok_options || defaultTiktokOptions
              )

              if (
                plan.facebook_options &&
                plan.facebook_options.posted_as &&
                !Array.isArray(plan.facebook_options.posted_as)
              )
                this.facebookOptions = clone(plan.facebook_options)
              else
                this.facebookOptions.post_type =
                  plan?.facebook_options?.post_type || 'feed'

              const firstComment = clone(defaultFirstComment)
              // First Comment case
              if (
                typeof plan.has_first_comment !== 'undefined' &&
                Array.isArray(plan.first_comment_accounts)
              ) {
                firstComment.has_first_comment = plan.has_first_comment || false
                firstComment.first_comment_message =
                  plan.first_comment_message || ''
                firstComment.first_comment_accounts =
                  plan.first_comment_accounts || []
              }

              this.firstComment = firstComment

              this.queueStatus = payload.duplicate
                ? false
                : plan.queued || false
              this.processAccountSelectionOnEdit(plan.account_selection)

              this.loader = false
            }
          } else if (response.message) {
            this.loader = false
            this.alertMessage(response.message, 'error')
          } else {
            this.loader = false
            this.alertMessage(
              'Unable to fetch publication.Please try again.',
              'error'
            )
          }
        } else {
          console.error('Error: While fetching publication details.', response)
          this.loader = false
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      } catch (e) {
        console.error('Error: Error in edit post.', e)
        this.loader = false
        this.alertMessage(UNKNOWN_ERROR, 'error')
      }
      this.loader = false
    },

    editCSVPost(plan) {
      try {
        this.loader = true
        this.postStatus = plan.status
        this.csvPlanId = plan._id || null

        this.labels = plan.labels
        this.members = plan.members

        const defaultTimeSelection = clone(
          defaultPublishTimeOptionsForSchedulePost
        )
        defaultTimeSelection.post_date = moment()
          .tz(this.getWorkspaces.activeWorkspace.timezone)
          .add(10, 'minutes')
          .format('MMM DD, YYYY HH:mm')
        if (
          plan.execution_time &&
          !this.timePassingCheckCSV(plan.execution_time.date)
        ) {
          this.execution_time = plan.execution_time
          defaultTimeSelection.post_date = moment
            .utc(plan.execution_time.date, 'YYYY-MM-DD HH:mm')
            .tz(this.getWorkspaces.activeWorkspace.timezone)
            .format('MMMM DD, YYYY HH:mm')
        } else {
          // defaultTimeSelection['time_type'] = 'queued'
          const currentDate = moment()
            .tz(this.getWorkspaces.activeWorkspace.timezone)
            .add(10, 'm')
          defaultTimeSelection.post_date = currentDate.format(
            'MMMM DD, YYYY HH:mm'
          )
        }
        if (plan.status === 'review') {
          defaultTimeSelection.plan_status = 'Under Review'
        }

        if (
          plan.content_category_id &&
          plan.dateSettingType &&
          plan.dateSettingType === 'content_category'
        ) {
          defaultTimeSelection.time_type = 'content_category'
          this.onChangeContentCategory(
            this.getContentCategoryById(plan.content_category_id) || null
          )
        }

        this.instagramPostingMethod = plan.instagram_posting_option || null
        this.instagramPostType = plan.instagram_post_type || 'feed'
        this.instagramShareToStory = plan?.instagram_share_to_story ?? false

        if(plan?.common_sharing_details?.image?.length){
          plan.common_sharing_details.multimedia = plan?.common_sharing_details?.image || []
        }

        this.publishTimeOptions = defaultTimeSelection
        this.common_box_status = plan.common_box_status

        plan.common_sharing_details.message = he.decode(
          plan.common_sharing_details.message
        )
        this.sharing_details.common_sharing_details = clone(
          plan.common_sharing_details
        )
        const platforms = socialPlatformNames()
        platforms.forEach((platform) => {
          if (
            plan[platform + '_sharing_details'] &&
            !Array.isArray(plan[platform + '_sharing_details'])
          ) {
            plan[platform + '_sharing_details'].message = he.decode(
              plan[platform + '_sharing_details'].message || ''
            )
            this.sharing_details[platform + '_sharing_details'] = clone(
              plan[platform + '_sharing_details'] ||
                defaultSharingDetails[platform + '_sharing_details'] ||
                {}
            )
          }
        })
        // for sharing_media_details store is used. so we need to set sharing_media_details in store.
        this.$store.commit(
          sharingTypes.SET_SOCIAL_SHARING_MEDIA_DETAILS,
          plan.sharing_media_details ? plan.sharing_media_details : {}
        )

        // Youtube Options
        const ytOptions = clone(plan.youtube_options || defaultYoutubeOptions)
        ytOptions.post_type = ytOptions.post_type || 'video'
        this.youtubeOptions = ytOptions

        this.carouselOptions = clone(
          plan.carousel_options || defaultCarouselOptions
        )
        this.gmbOptions = clone(plan.gmb_options || defaultGmbOptions)
        this.pinterestOptions = clone(
          plan.pinterest_options || defaultPinterestOptions
        )
        this.twitterOptions = clone(
          plan.twitter_options || defaultTwitterOptions
        )
        this.threadsOptions = clone(
          plan.threads_options || defaultThreadsOptions
        )
        this.linkedin_options = clone(
          plan.linkedin_options || defaultLinkedinOptions
        )
        this.tiktokOptions = clone(plan.tiktok_options || defaultTiktokOptions)
        if (
          plan.facebook_options &&
          plan.facebook_options.posted_as &&
          !Array.isArray(plan.facebook_options.posted_as)
        )
          this.facebookOptions = clone(plan.facebook_options)

        const firstComment = clone(defaultFirstComment)
        // First Comment case
        if (
          typeof plan.has_first_comment !== 'undefined' &&
          Array.isArray(plan.first_comment_accounts)
        ) {
          firstComment.has_first_comment = plan.has_first_comment || false
          firstComment.first_comment_message = plan.first_comment_message || ''
          firstComment.first_comment_accounts =
            plan.first_comment_accounts || []
        }

        this.firstComment = firstComment

        this.processAccountSelectionOnEdit(plan.account_selection)
        this.queueStatus = plan.queued

        this.loader = false
      } catch (e) {
        console.error('Error: Error in edit CSV post.', e)
        this.alertMessage(UNKNOWN_ERROR, 'error')
        this.loader = false
      }
    },

    processAccountSelectionOnEdit(accountSelection) {
      this.accountsList.forEach((channel) => {
        // eslint-disable-next-line no-unused-expressions
        accountSelection[channel.channel_type]?.forEach((item) => {
          if (
            item[channel.channel_identifier] ===
            channel[channel.channel_identifier]
          ) {
            this.account_selection[channel.channel_type].push(
              item[channel.channel_identifier]
            )
            channel.checked = true
          }
        })
      })
    },

    initializeDiscoveryPost(payload) {
      console.debug('Method:initializeDiscoveryPost', payload)
      const options = payload.options
      this.loader = true
      try {
        // default values
        this.common_box_status = true
        this.sharing_details = clone(defaultSharingDetails)
        this.initSharingOptions()
        this.firstComment = clone(defaultFirstComment)
        this.tasks = []
        this.comments = []
        this.selectedContentCategory = null
        this.draftCancel = false

        this.publishTimeOptions.post_date = moment()
          .tz(this.getWorkspaces.activeWorkspace.timezone)
          .add(10, 'minutes')
          .format('MMM DD, YYYY HH:mm')
        this.publishTimeOptions.time_type = 'queued'

        this.publish_source = payload.id ? payload.id : ''

        let message = ''
        if (options.title) {
          message += he.decode(options.title)
          if (options.url) {
            message = message.trim() + ' '
            // for solving two links issues(one link in title and one link is the post link)
            const urls = memoizedGetLinksFromText(message)
            if (urls) {
              urls.forEach(function (el) {
                message = message.replace(el, '')
              })
            }
          }
        }

        if (options.url) {
          // const response = this.fetchUtmBasedLinks(options.url, this.fetchActiveUtm)
          // this.sharing_details.common_sharing_details.url = response.common
          let commonMessage = message + options.url
          if (options.twitter_handler) {
            commonMessage = commonMessage + ' via ' + options.twitter_handler
          } else if (
            options.twitter_accounts &&
            options.twitter_accounts.constructor === Array
          ) {
            commonMessage =
              commonMessage + ' via ' + options.twitter_accounts[0]
          }

          this.sharing_details.common_sharing_details.message = commonMessage
          this.pinterestOptions.description = commonMessage
          // platforms.forEach((platform) => {
          //   if (platform !== 'tiktok') {
          //     this.sharing_details.common_sharing_details[platform + '_url'] = response[platform]
          //     if (platform === 'gmb') {
          //       this.sharing_details.common_sharing_details.cta_link = response[platform]
          //     }
          //   }
          // })
        } else {
          if (options.image) {
            this.sharing_details.common_sharing_details.image = [options.image]
          }
          this.sharing_details.common_sharing_details.message = message
        }
        this.loader = false
        // this.autoSave()
      } catch (e) {
        this.alertMessage(UNKNOWN_ERROR, 'error')
        console.error('Error: Error while initialize Discovery Post', e)
        this.loader = false
        this.$bvModal.hide('composer-modal')
        this.$refs['image-generator'].clearState()
      }
      this.loader = false
    },

    initializeShareMediaPost(payload) {
      console.debug('Method:initializeShareMediaPost', payload)
      this.newPost(payload, false)
      try {
        const options = payload.options || {}
        switch (options.mediaType) {
          case 'image':
            this.sharing_details.common_sharing_details.image =
              options.images || []
            break
          case 'video':
            this.sharing_details.common_sharing_details.video =
              options.video[0] || videoDefaultSharingDetails
            break
          case 'pdf':
            this.linkedin_options.is_carousel = true
            this.linkedin_options.title = options.doc.name || ''
            this.linkedin_options.document_added = true
            this.linkedin_options.document = options.doc
            break
        }
      } catch (error) {
        console.error('ERROR: initializeShareMediaPost error ', error)
        this.alertMessage(UNKNOWN_ERROR, 'error')
      }
      this.loader = false
    },

    /**
     * This method is responsible for handling multimedia in case of editing plan
     * @param sharingDetails
     * @param type
     */
    onEditHandleMultimedia(sharingDetails, type) {
      // for old plans we need to set the multimedia if user trying to upload multimedia
      if (!sharingDetails.multimedia && sharingDetails?.image?.length > 0) {
        sharingDetails.multimedia = []
        sharingDetails.multimedia = sharingDetails.image
        this.onChangeSharingDetails(sharingDetails, type)
      }

      // for old plans we need to set the multimedia if user trying to upload multimedia
      if (!sharingDetails.multimedia && sharingDetails?.video?.link) {
        sharingDetails.multimedia = []
        sharingDetails.multimedia.push(sharingDetails.video)
        this.onChangeSharingDetails(sharingDetails, type)
      }
    },

    isSeparateBoxes(status, isAutoThread = false) {
      this.changeSharingBoxOption(
        status,
        this.sharing_details.common_sharing_details,
        isAutoThread
      )
      // this.common_box_status = !status
    },

    changeSharingBoxOption(status, commonDetails, isAutoThread) {
      const message = commonDetails.message.trim()

      if (status) {
        const platforms = socialPlatformNames()
        // message case
        if (message) {
          const urls = memoizedGetLinksFromText(message)
          platforms.forEach((platform) => {
            if (isAutoThread && platform === 'twitter') return
            if (
              this.sharing_details[platform + '_sharing_details'].message !==
                undefined &&
              this.sharing_details[
                platform + '_sharing_details'
              ].message.trim() === ''
            ) {
              let newMessage = message
              if (
                commonDetails[platform + '_url'] &&
                commonDetails.url !== commonDetails[platform + '_url']
              ) {
                if (urls) {
                  urls.forEach(function (element) {
                    if (newMessage.includes(element)) {
                      newMessage = newMessage.replace(element, '')
                    }
                  })
                  newMessage += ' ' + commonDetails[platform + '_url']
                }
              }
              this.sharing_details[platform + '_sharing_details'].message =
                newMessage
              if (
                platform === 'facebook' ||
                platform === 'twitter' ||
                platform === 'linkedin'
              ) {
                if (commonDetails.mentions && commonDetails.mentions.length > 0)
                  this.sharing_details[platform + '_sharing_details'].mentions =
                    clone(commonDetails.mentions)
              }
            }
          })
        }
        // location case
        if (commonDetails.location) {
          if (
            commonDetails.location.facebook &&
            commonDetails.location.facebook.id &&
            !this.sharing_details.facebook_sharing_details.location?.id
          ) {
            this.sharing_details.facebook_sharing_details.location =
              commonDetails.location.facebook
          }
          if (
            commonDetails.location.instagram &&
            commonDetails.location.instagram.id &&
            !this.sharing_details.instagram_sharing_details.location?.id
          ) {
            this.sharing_details.instagram_sharing_details.location =
              commonDetails.location.instagram
          }
          if (
            commonDetails.location.twitter &&
            commonDetails.location.twitter.id &&
            !this.sharing_details.twitter_sharing_details.location?.id
          ) {
            this.sharing_details.twitter_sharing_details.location =
              commonDetails.location.twitter
          }
        }
        // link case
        if (commonDetails.url) {
          // setting each platform link preview if the link is null for that platform
          platforms.forEach((platform) => {
            if (
              platform === 'youtube' ||
              platform === 'tiktok' ||
              (isAutoThread && platform === 'twitter')
            )
              return
            if (
              !this.sharing_details[platform + '_sharing_details'].url &&
              this.sharing_details[platform + '_sharing_details'].video &&
              !this.sharing_details[platform + '_sharing_details'].video.link &&
              (!this.sharing_details[platform + '_sharing_details'].image ||
                this.sharing_details[platform + '_sharing_details'].image
                  .length === 0)
            ) {
              // setting link in states
              this.sharing_details[platform + '_sharing_details'].url =
                commonDetails[platform + '_url']
              if (platform === 'gmb') {
                this.sharing_details[platform + '_sharing_details'].cta_link =
                  commonDetails[platform + '_url']
              }
              const preview = this.CSParseBoxPreview(platform, commonDetails)
              switch (platform) {
                case 'facebook':
                case 'twitter':
                case 'linkedin':
                case 'threads':
                case 'bluesky':
                  this.sharing_details[platform + '_sharing_details'].image =
                    preview.image
                  this.sharing_details[platform + '_sharing_details'].title =
                    preview.title
                  this.sharing_details[
                    platform + '_sharing_details'
                  ].description = preview.description
                  this.sharing_details[platform + '_sharing_details'].website =
                    preview.website
                  this.sharing_details[
                    platform + '_sharing_details'
                  ].image_suggestions = preview.image_suggestions
                  break
                default:
                  this.sharing_details[platform + '_sharing_details'].image =
                    preview.image
              }
            }
          })
        }
        // if common box has multimedia
        if (commonDetails?.multimedia?.length) {
          if (
            !this.sharing_details.instagram_sharing_details?.multimedia?.length
          ) {
            platforms.forEach((platform) => {
              if (MULTIMEDIA_ALLOWED_PLATFORMS.includes(platform)) {
                this.sharing_details[platform + '_sharing_details'].multimedia =
                  commonDetails?.multimedia
                    ? clone(commonDetails.multimedia)
                    : []
              }
            })
          }

          // Here if the multimedia has first element type Array then we will pass the Images array otherwise video
          if (commonDetails?.multimedia?.length) {
            const videos = commonDetails.multimedia.filter(
              (media) => typeof media === 'object'
            )
            let isImageSet = false
            if (typeof commonDetails?.multimedia[0] === 'string') {
              this.setImagesForAllPlatforms(platforms, {
                image: commonDetails.multimedia.filter(
                  (media) => typeof media === 'string'
                ),
              })
              isImageSet = true
            }

            if (videos?.length) {
              if (!isImageSet) {
                this.setVideoForAllPlatforms(platforms, {
                  video: clone(videos[0]),
                })
              } else {
                // if image set then we will set the video for youtube and tiktok
                this.sharing_details.tiktok_sharing_details.image = []
                this.setVideoForAllPlatforms(['tiktok', 'youtube'], {
                  video: clone(videos[0]),
                })
              }
            }
          }
        }
        // if common box has video
        else if (commonDetails.video && commonDetails.video.link) {
          // setting each platform video if the video in null for that platform
          this.setVideoForAllPlatforms(platforms, commonDetails)
        }
        // if common box has image
        else if (commonDetails.image && commonDetails.image.length) {
          // setting each platform image if the image is null for that platform
          this.setImagesForAllPlatforms(platforms, commonDetails)
        }

        // image tagging case
        if (commonDetails?.image_tagging_details) {
          console.log('image tagging details in')
          if (
            isEmpty(
              this.sharing_details.instagram_sharing_details
                .image_tagging_details
            )
          ) {
            this.sharing_details.instagram_sharing_details.image_tagging_details =
              {
                ...commonDetails.image_tagging_details,
              }
          }
        }
        // pinterest source url case
        if (commonDetails?.source_url) {
          this.sharing_details.pinterest_sharing_details.source_url =
            commonDetails.source_url
        }
      }

      if (
        !status === false &&
        !this.publishTimeOptions.queueStatus &&
        !this.globalContentCategory &&
        (!this.selectedContentCategory || !this.selectedContentCategory._id)
      ) {
        // this.publishTimeOptions.time_type = 'queued'
      }

      if (status && this.globalContentCategory) {
        // service call here
        this.common_box_status = !status
        // this.fetchGlobalCategoryChannels()
      } else {
        this.common_box_status = !status
      }
    },
    /**
     * This method is responsible for seprating the video for all the platforms
     * @param platforms
     * @param commonDetails
     */
    setVideoForAllPlatforms(platforms, commonDetails) {
      // setting each platform video if the video in null for that platform
      platforms.forEach((platform) => {
        if (
          this.sharing_details[platform + '_sharing_details'].video &&
          !this.sharing_details[platform + '_sharing_details'].video.link &&
          (!this.sharing_details[platform + '_sharing_details']?.image ||
            this.sharing_details[platform + '_sharing_details']?.image
              ?.length === 0)
        ) {
          if (
            !(
              platform === 'facebook' ||
              platform === 'youtube' ||
              platform === 'linkedin' ||
              platform === 'pinterest' ||
              platform === 'tiktok'
            )
          ) {
            const commonData = { ...commonDetails.video }
            commonData.thumbnails_suggestions =
              commonData?.thumbnails_suggestions?.filter((thumbnail) => {
                if (
                  !thumbnail.includes('contentstudio-media-library-nearline')
                ) {
                  return thumbnail
                }
                return null
              })

            if (
              commonData?.thumbnails_suggestions?.length &&
              commonData?.thumbnail.includes(
                'contentstudio-media-library-nearline'
              )
            ) {
              commonData.thumbnail = commonData?.thumbnail
                ? commonData?.thumbnail
                : commonData.thumbnails_suggestions[0]
            }
            this.sharing_details[platform + '_sharing_details'].video =
              commonData
          } else {
            this.sharing_details[platform + '_sharing_details'].video = clone(
              commonDetails.video
            )
          }
        }
      })
    },
    /**
     * This method is responsible for seprating the images for all the platforms
     * @param platforms
     * @param commonDetails
     */
    setImagesForAllPlatforms(platforms, commonDetails) {
      platforms.forEach((platform) => {
        // youtube and tiktok don't allow image post
        if (platform === 'youtube') return

        if (
          this.sharing_details[platform + '_sharing_details'].video &&
          !this.sharing_details[platform + '_sharing_details'].video.link &&
          (!this.sharing_details[platform + '_sharing_details'].image ||
            this.sharing_details[platform + '_sharing_details'].image.length ===
              0)
        ) {
          this.sharing_details[platform + '_sharing_details'].video = clone(
            videoDefaultSharingDetails
          )
          const images = clone(commonDetails.image)
          switch (platform) {
            case 'facebook':
            case 'linkedin':
            case 'tiktok':
              this.sharing_details[platform + '_sharing_details'].image = images
              break
            case 'twitter':
              this.sharing_details[platform + '_sharing_details'].image =
                images.length > 3 ? images.slice(0, 4) : images
              break
            case 'instagram':
            case 'threads':
              this.sharing_details[platform + '_sharing_details'].image =
                images.length > 10 ? images.slice(0, 10) : images
              break
            case 'bluesky':
              this.sharing_details[platform + '_sharing_details'].image =
                  images.length > 4 ? images.slice(0, 4) : images
              break
            case 'pinterest':
            case 'tumblr':
            case 'gmb':
              this.sharing_details[platform + '_sharing_details'].image =
                images.length > 1 ? images.slice(0, 1) : images
              break
          }
        }
      })
    },
    /**
     * Handles the facebook accounts post as for each facebook accounts in the list.
     */
    handleFacebookPostedAs() {
      this.getSocialAccountsList.forEach((account) => {
        if (account.channel_type === 'facebook') {
          this.setFacebookPostAs(account)
        }
      })
    },

    setFacebookPostAs(account) {
      const self = this
      if (commonMethods.isFacebookGroupWithPostedAs(account)) {
        account.posted_as.forEach((postedAs) => {
          if (postedAs.default === true) {
            self.facebookOptions.posted_as[account.facebook_id] = postedAs.id
          }
        })
      }
    },

    getContentCategoryById(categoryId) {
      if (categoryId) {
        return this.contentCategoryList.find((category) => {
          return category._id === categoryId
        })
      }
      return null
    },

    fetchUtms() {
      console.debug('fetch_Utms')
      this.initializeUtms()
      this.utmData.items = this.getUtms.items
      // ********* here we can fetch the UTMS from the server *********
      // proxy.post(fetchUTMUrl, {
      //   workspace_id: this.getWorkspaces.activeWorkspace._id,
      // })
      //     .then(response => {
      //       if (response.data.status) {
      //         this.utmData.items = response.data.utm
      //       }
      //     })
      //   .catch(error => {
      //     console.error('CS-fetchUtms-error', error)
      //   })
    },

    /**
     * Initializes the account_selection Object with channel keys inside it.
     */
    initAccountSelection() {
      socialChannelsNameArray.forEach((item) => {
        if (!(item in this.account_selection)) {
          this.account_selection[item] = []
          // this.$set(this.account_selection, item.name, []) // makes the object property reactive.
        }
      })
    },

    /**
     * Initializes Sharing Options
     */
    initSharingOptions() {
      console.debug('CS-initSharingOptions')
      this.twitterOptions = clone(defaultTwitterOptions)
      this.youtubeOptions = clone(defaultYoutubeOptions)
      this.pinterestOptions = clone(defaultPinterestOptions)
      this.carouselOptions = clone(defaultCarouselOptions)
      this.threadsOptions = clone(defaultThreadsOptions)
      this.blueskyOptions = clone(defaultBlueskyOptions)
    },

    updateInstagramSelection() {
      this.$refs['main-composer'].updateInstagramSelection()
    },
    handleAccountsUpdate(accountsData) {
      this.accountsList = accountsData.accountList
      this.account_selection = accountsData.accountSelection
    },

    /**
     * update selected first comment accounts array
     * @param channel
     */
    updateFirstCommentSelectedAccounts(channel) {
      const firstCommentsAccounts = this.firstComment.first_comment_accounts
      this.accountsList.forEach((account) => {
        if (
          account.checked &&
          firstCommentChannels.includes(account.channel_type) &&
          account.channel_type === channel
        ) {
          const index = firstCommentsAccounts.indexOf(
            account[account.channel_identifier]
          )
          if (index !== -1) {
            if (!this.isAllowFirstComment(account)) {
              firstCommentsAccounts.splice(index, 1)
            }
          } else {
            if (this.isAllowFirstComment(account)) {
              firstCommentsAccounts.push(account[account.channel_identifier])
            }
          }
        }
      })
      this.firstComment.first_comment_accounts = firstCommentsAccounts
    },

    isAllowFirstComment(account) {
      switch (account.channel_type) {
        case 'facebook':
          return account.type === 'Page'
        case 'instagram':
          return this.instagramPostingMethod === 'api'
        case 'linkedin':
          return true
        case 'youtube':
          return (
            this.youtubeOptions.privacy_status === 'public' &&
            !this.youtubeOptions.made_for_kids
          )
      }
      return false
    },

    /**
     * Updates sharing details based in the val passed from child components.
     * @param type
     * @param details
     * @param errors
     */
    onChangeSharingDetails(type, details, errors) {
      this.sharing_details[type + '_sharing_details'] = details
      console.log(errors)
    },
    saveEditorUrl(type, urls) {
      this.sharing_details[type + '_sharing_details'].saved_urls = urls
    },

    onChangeSharingOptions(type, options) {
      switch (type) {
        case 'linkedin':
          this.linkedin_options = options
          break
        default:
          this[type + 'Options'] = options
      }
    },

    resetFacebookCarousel() {
      this.carouselUrl = {
        url: '',
        image: '',
        title: '',
        description: '',
      }
      this.carouselOptions = clone(defaultCarouselOptions)
    },

    onChangeFirstComment(firstComment) {
      this.firstComment = firstComment
    },

    handleCampaignsChanges(type, details) {
      // Details can be either folderId or campaign object { name: string, color: string }
      if (type === 'set') {
        this.folderId = details
      } else if (type === 'create') {
        // a new method can be placed here to create a new campaign
        this.getPublicationNewFolder.name = details.name
        this.getPublicationNewFolder.color = details.color
        this.createPublicationFolder()
      }
    },
    handleMemberChanges(value) {
      this.members = value
    },
    handleLabelChanges(value) {
      console.log('METHOD::handleLabelChanges ~ value -> ', value)
      this.labels = value
    },
    handleCommentChanges(value) {
      this.comments = value
    },
    handleTaskChanges(value) {
      this.tasks = value
    },
    handleApprover(value) {
      console.log('METHOD::handleApprover ~ value -> ', value)
      this.approval = value
    },

    async handleAutoSave(callbackFunc, payload) {
      const planId = await this.autoSave()
      if (planId) {
        callbackFunc(planId, payload)
      }
    },
    handleAiCaptionOutput(
      caption = '',
      isOldComposer = false,
      type = 'common'
    ) {
      // handle caption output for social modal editor box as well evergreen, old composer content boxes
      console.log('Method:handleAiCaptionOutput', caption, isOldComposer, type)
      if (isOldComposer) {
        this.setSharingMessage(type, caption)
        return
      }

      EventBus.$emit('insert-chat-content', caption)
    },

    /**
     * Adds/Updates the selected content category
     * @param category
     */
    onChangeContentCategory(category) {
      console.log(
        'METHOD::onChangeContentCategory ~ category, typeof -> ',
        category
      )
      this.selectedContentCategory = category
      this.nextContentCategorySlot = null
      if (this.selectedContentCategory !== null) {
        this.publishTimeOptions.time_type = 'content_category'
      } else {
        this.publishTimeOptions.time_type = 'queued'
      }
    },
    setCategorySlot(slot) {
      this.nextContentCategorySlot = slot
    },

    editQueuedPost() {
      this.queueStatus = false
    },

    duplicatePost() {
      this.planId = null
      this.postStatus = ''
      this.repost = true
      this.isDuplicatePost = true

      // set the comments, and tasks
      this.comments = []
      this.tasks = []
      this.activities = { hits: [] }
    },

    /**
     * @deprecated
     * @param postedId
     * @param accountId
     */
    onChangePostedAs(postedId, accountId) {
      const postedAs = { ...this.facebookOptions.posted_as }
      postedAs[accountId] = postedId
      this.facebookOptions.posted_as = postedAs
      // this.updateFirstCommentSelectedAccounts('facebook')
    },

    /**
     * reset sharing details
     */
    composerResetSharingDetails() {
      const details = clone(defaultSharingDetails)
      details.common_sharing_details = clone(
        this.sharing_details.common_sharing_details
      )
      this.sharing_details = details
    },

    async processGlobalContentCategoryPost() {
      console.debug('Method:processContentCategoryPost')
      this.processPlanLoader = true
      const payload = this.generatePayload()
      payload.status = 'scheduled'
      delete payload.account_selection
      await proxy
        .post(processSocialContentCategoryPostURL, payload)
        .then(async (resp) => {
          if (resp.data.status) {
            this.modalClosable = true
            this.$bvModal.hide('composer-modal')
            this.$refs['image-generator'].clearState()
            this.alertMessage(resp.data.message)
            if (
              !this.getActiveWorkspace.onboarding_steps.create_first_post.status
            ) {
              await this.onboardingStepsCompleted('create_first_post')
            }
            this.trackUserMaven('social_post_created')
            this.setSocialSettingsPreferences()
            this.refreshPlannerTable()
          } else {
            this.alertMessage(resp.data?.message, 'error')
          }
        })
        .catch((err) => {
          console.error('ERROR in processGlobalContentCategoryPost', err)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          return null
        })
        .finally(() => {
          this.processPlanLoader = false
        })
    },

    /**
     * Gets updated payload and performs an api call to publish the post.
     */
    async processSocialShareNew(
      draft = false,
      autoSaveRequest = false,
      redirect = false,
      taskCommentSave = '',
      approval = false,
      action = ''
    ) {
      if (this.globalContentCategory) {
        return await this.processGlobalContentCategoryPost()
      }
      this.processPlanLoader = true
      const self = this
      const generatedPayload = this.generatePayload(draft)
      this.gridViewPostData = generatedPayload
      if (
        this.approval &&
        this.approval.approvers &&
        this.approval.approvers.length &&
        this.publishTimeOptions.time_type !== 'now'
      )
        generatedPayload.approval = this.approval
      else if (this.socialPostType === 'edit') {
        generatedPayload.approval = null
      }
      if (!this.validateSocialShare(generatedPayload, autoSaveRequest)) {
        this.processPlanLoader = false
        return
      }
      // if post has custom schedule time map over the custom schedule and format the date
      if (generatedPayload.publish_time_options.is_custom_schedule) {
        generatedPayload.publish_time_options =
          this.mapCustomSchedule(generatedPayload)
      }
      generatedPayload.publish_time_options.post_date = moment(
        generatedPayload.publish_time_options.post_date
      ).format('YYYY-MM-DD HH:mm:ss')
      if (this.publishTimeOptions.time_type === 'draft' && draft) {
        generatedPayload.is_force_draft_save = true
      }

      proxy
        .post(processSocialShareUrl, generatedPayload)
        .then(async (resp) => {
          if (resp.data.status) {
            this.modalClosable = true
            this.alertMessage(resp.data.message)
            if (
              !this.getActiveWorkspace?.onboarding_steps?.create_first_post
                ?.status
            ) {
              this.onboardingStepsCompleted('create_first_post')
            }
            this.trackUserMaven('social_post_created')
            if (this.csvPlanId) this.refetchCSVPlans()
            this.refreshPlannerTable()
            this.setSocialSettingsPreferences()
            if (typeof action === 'string' && action !== '') {
              if (action === 'reopen-blank') {
                this.copyRouteDetail()
                this.handlePublicationComposerPost({
                  mode: 'new',
                })
                EventBus.$emit('toggle-repeat-post', false)
              }
              if (action === 'reopen-duplicate') {
                this.copyRouteDetail()
                this.handlePublicationComposerPost({
                  mode: 'edit',
                  id: resp.data?.id,
                  duplicate: true,
                })
              }
              if (action === 'reopen-with-account') {
                const selectedAccount = this.account_selection
                this.copyRouteDetail()
                this.handlePublicationComposerPost({ mode: 'new' })
                this.checkedAllSelectedAccount(selectedAccount)
                if(generatedPayload?.content_category_id){
                  this.selectedContentCategory = null
                  this.planId = null
                  await this.$nextTick(() => {
                    this.onChangeContentCategory(
                      this.getContentCategoryById(generatedPayload.content_category_id)
                    )
                  })
                }
                this.linkedin_options.accounts =
                  generatedPayload.linkedin_options.accounts || []
                this.firstComment.first_comment_accounts =
                  generatedPayload.first_comment_accounts || []
                this.carouselOptions.accounts =
                  generatedPayload.carousel_options.accounts || []
                EventBus.$emit('toggle-repeat-post', false)
              }
            } else {
              self.$bvModal.hide('composer-modal')
            }
          } else {
            this.alertMessage(resp.data?.message, 'error')
          }
        })
        .catch((err) => {
          console.error('ERR in processSocialShareNew', err)
          this.alertMessage(UNKNOWN_ERROR, 'error')
        })
        .finally(() => {
          this.processPlanLoader = false
        })
    },
    approverModalValidation(
      draft = false,
      autoSaveRequest = false,
      redirect = false,
      taskCommentSave = '',
      approval = false
    ) {
      console.log(
        'METHOD::approverModalValidation',
        autoSaveRequest,
        redirect,
        taskCommentSave,
        approval
      )

      const generatedPayload = this.generatePayload(draft)
      if (this.approval?.approvers?.length)
        generatedPayload.approval = this.approval

      const checkValidation = this.validateSocialShare(
        generatedPayload,
        autoSaveRequest
      )
      console.log(
        'METHOD::approverModalValidation ~ checkValidation -> ',
        checkValidation
      )
      return checkValidation
    },

    /**
     * generates a payload used for post request.
     * @returns Object payload
     */
    generatePayload(draft = false, isAutoSave = false) {
      if (draft) this.postStatus = 'draft'
      else this.postStatus = 'scheduled'

      return {
        account_selection: this.account_selection,
        common_box_status: this.common_box_status,
        common_sharing_details: this.sharing_details.common_sharing_details,
        facebook_sharing_details: this.sharing_details.facebook_sharing_details,
        twitter_sharing_details: this.sharing_details.twitter_sharing_details,
        threads_sharing_details: this.sharing_details.threads_sharing_details,
        bluesky_sharing_details: this.sharing_details.bluesky_sharing_details,
        instagram_sharing_details:
          this.sharing_details.instagram_sharing_details,
        linkedin_sharing_details: this.sharing_details.linkedin_sharing_details,
        pinterest_sharing_details:
          this.sharing_details.pinterest_sharing_details,
        tumblr_sharing_details: this.sharing_details.tumblr_sharing_details,
        gmb_sharing_details: this.sharing_details.gmb_sharing_details,
        youtube_sharing_details: this.sharing_details.youtube_sharing_details,
        tiktok_sharing_details: this.sharing_details.tiktok_sharing_details,
        facebook_options: this.facebookOptions,
        gmb_options: this.gmbOptions,
        youtube_options: this.youtubeOptions,
        twitter_options: this.twitterOptions,
        pinterest_options: this.pinterest_options,
        linkedin_options: this.linkedin_options,
        carousel_options: this.carouselOptions,
        threads_options: this.threadsOptions,
        bluesky_options: this.blueskyOptions,
        tiktok_options: this.tiktokOptions,
        labels: this.labels,
        members: this.members,
        publish_time_options: this.publishTimeOptions,
        workspace_id: this.getActiveWorkspace._id,
        queue: this.queueStatus,
        reopen: this.reopen,
        csv_post_id: this.csvPlanId || null,
        content_category_id:
          this.selectedContentCategory &&
          this.publishTimeOptions.time_type === 'content_category'
            ? this.selectedContentCategory._id
            : null,
        folderId: this.folderId,
        publish_source: this.publish_source || null,
        instagram_posting_option: this.instagramPostingMethod,
        instagram_post_type: this.instagramPostType,
        sharing_media_details: this.getSocialSharingMediaDetails, // get social sharing media details from the store
        has_first_comment: this.firstComment.has_first_comment || false,
        first_comment_message: this.firstComment.first_comment_message || '',
        first_comment_accounts: this.firstComment.first_comment_accounts || [],
        type: 'Composer Social',
        planId: this.planId ? this.planId : '',
        status: this.postStatus,
        visible: !this.isEmptyAccountSelection(),
        is_approver: this.isApprover,
        instagram_collaborators:
          this.instagramPostType !== 'story' ? this.instagramCollaborators : [],
        instagram_share_to_story: this.instagramShareToStory,
        is_force_draft_save: this.isForceDraftSave,
        is_auto_save: isAutoSave,
        locations: this.locations,
      }
    },

    isEmptyAccountSelection() {
      return (
        Object.entries(this.account_selection).find(
          ([value]) => value.length > 0
        ) === undefined
      )
    },

    /**
     * Auto-saves the composer after an interval or on update of content.
     */
    async autoSave(setLastUpdated = true) {
      const self = this
      this.processPlanLoader = true
      const generatedPayload = this.generatePayload(true, true)
      // Format the date to prevent the processSocialShare to break
      generatedPayload.publish_time_options.post_date = moment(
        generatedPayload.publish_time_options.post_date
      ).format('YYYY-MM-DD HH:mm:ss')

      return proxy
        .post(processSocialShareUrl, generatedPayload)
        .then((resp) => {
          if (resp.data.status) {
            self.setSocialSettingsPreferences()
            if (resp.data.id) {
              self.planId = resp.data.id
              if (setLastUpdated && resp.data.last_updated)
                self.lastUpdated = resp.data.last_updated
              return resp.data.id
            }
          }
        })
        .catch((err) => {
          console.error('ERR ~ err -> ', err)
          return null
        })
        .finally(() => {
          this.processPlanLoader = false
        })
    },
    /**
     * Validating the social share payload and moving forward to posting logic
     * @param payload
     * @param autoSaveRequest default false
     * @returns {boolean}
     */
    validateSocialShare(payload, autoSaveRequest = false) {
      console.log('METHOD::validateSocialShare ~ payload -> ', payload)

      // Now in case of draft post type will not user to restrict to validate his composer errors
      if (this.postStatus === 'draft') {
        let isContentAdded = false
        let isAccountSelected = false

        // Check if any content is added
        for (const channelName in this.sharing_details) {
          const details = this.sharing_details.common_sharing_details
          if (
            details.message.trim() !== '' ||
            details.image.length > 0 ||
            (details.video && details.video.link !== '')
          ) {
            isContentAdded = true
            break
          }
        }

        // Check if any account is selected
        for (const channelName in this.account_selection) {
          if (this.account_selection[channelName].length > 0) {
            isAccountSelected = true
            break
          }
        }

        if (isContentAdded || isAccountSelected) {
          return true
        }

        this.alertMessage(
          'Please add content (text, image or video) or select at least one account to save your draft.',
          'error'
        )
        return false
      }

      if (this.postStatus === 'draft' || this.postStatus === 'scheduled') {
        console.debug('publishTimeOptions', this.publishTimeOptions.post_date)
        if (this.publishTimeOptions.post_date === 'Invalid date') {
          this.alertMessage('Post schedule date is required.', 'error')
          return false
        }
      }

      if (
        (this.publishTimeOptions.time_type === 'schedule' &&
          !this.publishTimeOptions.is_custom_schedule) ||
        this.publishTimeOptions.time_type === 'draft'
      ) {
        if (
          moment(this.publishTimeOptions.post_date)
            .tz(this.workspaceTimezone, true)
            .isBefore(moment().tz(this.workspaceTimezone))
        ) {
          this.alertMessage(
            'The time you have selected has passed. Please select future time.',
            'error'
          )
          return false
        }
      }

      let accountSelected = 0

      for (const channelName in this.account_selection) {
        accountSelected += this.account_selection[channelName].length
        if (this.account_selection[channelName].length && !autoSaveRequest) {
          const details = this.common_box_status
            ? this.sharing_details.common_sharing_details
            : this.sharing_details[`${channelName}_sharing_details`]
          switch (channelName) {
            case 'facebook':
              if (
                details.message.trim() === '' &&
                details.image.length === 0 &&
                (!details.video || details.video.link === '')
              ) {
                this.alertMessage(
                  'Please add text, an image, or a video to your facebook post. It cannot be empty',
                  'error'
                )
                return false
              }
              if (
                this.carouselOptions?.is_carousel_post &&
                !this.getCarouselUrl.url
              ) {
                this.alertMessage(
                  `Please insert the URL for the Facebook Carousel post.`,
                  'error'
                )
                return false
              }
              break
            case 'twitter':
            case 'linkedin':
            case 'tumblr':
            case 'gmb':
              if (
                details.message.trim() === '' &&
                details.image.length === 0 &&
                (!details.video || details.video.link === '')
              ) {
                if (
                  channelName === 'linkedin' &&
                  this.linkedin_options?.document_added
                )
                  break
                this.alertMessage(
                  `Message box ` +
                    (this.common_box_status
                      ? ''
                      : `for ${
                          channelName.charAt(0).toUpperCase() +
                          channelName.slice(1)
                        }`) +
                    ` is empty, please add something to post on  ${
                      channelName.charAt(0).toUpperCase() + channelName.slice(1)
                    }.`,
                  'error'
                )
                return false
              }
              break
            case 'instagram':
              if (
                details.image.length === 0 &&
                (!details.video || details.video.link === '')
              ) {
                this.alertMessage(
                  'Please add at least one image or video for Instagram post. It cannot be empty.',
                  'error'
                )
                return false
              }
              break
            case 'threads':
              if (
                details.message.trim() === '' &&
                details.image.length === 0 &&
                details.multimedia.length === 0 &&
                (!details.video || details.video.link === '')
              ) {
                this.alertMessage(
                  'Please add text, an image, or a video to your Threads post. It cannot be empty.',
                  'error'
                )
                return false
              }
              break
            case 'bluesky':
              if (
                  details.message.trim() === '' &&
                  details.image.length === 0 &&
                  (!details.video || details.video.link === '')
              ) {
                this.alertMessage(
                    'Please add text, an image, or a video to your Bluesky post. It cannot be empty.',
                    'error'
                )
                return false
              }
              break
            case 'pinterest':
              if (!details?.image?.length && !details?.video?.link) {
                this.alertMessage(
                  'Please add an image or video for Pinterest post. It cannot be empty.',
                  'error'
                )
                return false
              }
              if (details.source_url) {
                if (!this.isValidUrl(details.source_url)) {
                  this.alertMessage(
                    'Please enter a valid source URL for pinterest.',
                    'error'
                  )
                  return false
                }
              }
              break
            case 'tiktok':
              const postType = this.tiktokOptions?.post_type || 'video'
              if (postType === 'video' && !details.video?.link) {
                this.alertMessage(
                  'Please add a video for TikTok video post. It cannot be empty.',
                  'error'
                )
                return false
              } else if (
                postType === 'carousel' &&
                (details.image?.length < 2 || details.image?.length > 35)
              ) {
                this.alertMessage(
                  'Please add at least 2 and at most 35 images for TikTok carousel post.',
                  'error'
                )
                return false
              }
              break
            case 'youtube':
              if (!details.video || details.video.link === '') {
                this.alertMessage(
                  `Please add a video for ${
                    channelName.charAt(0).toUpperCase() + channelName.slice(1)
                  } post. It cannot be empty.`,
                  'error'
                )
                return false
              }
              break
          }
        }
      }

      // dispatching error if no account(s) selected.
      if (accountSelected === 0) {
        this.$store.dispatch('toastNotification', {
          message: 'Please select social account(s) to continue.',
          type: 'error',
        })
        return false
      }

      // twitter and youtube account validation
      for (const channelName in this.account_selection) {
        if (channelName === 'twitter') {
          if (this.account_selection[channelName].length > 1) {
            this.$store.dispatch('toastNotification', {
              message: `Please select only one ${channelName} account to continue.`,
              type: 'error',
            })
            return false
          }
        }
      }

      // first comment validation
      if (this.firstComment.has_first_comment) {
        if (this.firstComment.first_comment_message.trim() === '') {
          this.alertMessage(
            'First comment box is empty, please add something to it!',
            'error'
          )
          return false
        }
        // check if first comment social selection is empty
        if (this.firstComment.first_comment_accounts.length === 0) {
          this.alertMessage(
            'Please select a social account in first comment section, or disable the first comment option.',
            'error'
          )
          return false
        }
      }

      // threaded tweet validation
      if (this.twitterOptions.has_threaded_tweets) {
        const tweets = this.twitterOptions.threaded_tweets
        for (const i in tweets) {
          console.log('ThreadedTweetsError', tweets[i], i)
          if (
            tweets[i].message.trim() === '' &&
            tweets[i].image.length === 0 &&
            (!tweets[i].video.link || tweets[i].video.link === '')
          ) {
            this.alertMessage(
              'Threaded Tweet box is empty, please add something to it!',
              'error'
            )
            return false
          }
        }
      }

      // multi threads validation
      if (this.threadsOptions.has_multi_threads) {
        const tweets = this.threadsOptions.multi_threads
        for (const i in tweets) {
          console.log('multiThreadsError', tweets[i], i)
          if (
            tweets[i].message.trim() === '' &&
            tweets[i].image.length === 0 &&
            tweets[i].multimedia.length === 0 &&
            (!tweets[i].video.link || tweets[i].video.link === '')
          ) {
            this.alertMessage(
              'Please add text, an image, or a video to your Threads post. It cannot be empty.',
              'error'
            )
            return false
          }
        }
      }

      // multi threads validation
      if (this.blueskyOptions.has_multi_bluesky) {
        const bluesky = this.blueskyOptions.multi_bluesky
        for (const i in bluesky) {
          console.log('multiBlueskyError', bluesky[i], i)
          if (
              bluesky[i].message.trim() === '' &&
              bluesky[i].image.length === 0 &&
              bluesky[i].image.length === 0 &&
              (!bluesky[i].video.link || bluesky[i].video.link === '')
          ) {
            this.alertMessage(
                'Please add text, an image, or a video to your Bluesky post. It cannot be empty.',
                'error'
            )
            return false
          }
        }
      }

      // carousel post validation
      if (this.carouselOptions && this.carouselOptions.is_carousel_post) {
        if (this.carouselOptions.accounts.length === 0) {
          this.alertMessage(
            'Please select a social account for the carousel post',
            'error'
          )
          return false
        }
        if (this.carouselOptions.cards.length < 2) {
          this.alertMessage(
            'You need to have at least 2 cards in a carousel post.',
            'error'
          )
          return false
        }
      }

      return true
    },

    /**
     * social post editor validation calculation
     * @param errors
     */
    editorErrors(errors) {
      console.log('METHOD::editorErrors -> ', errors)
      this.errors = errors
    },
    setActiveTab(val) {
      this.activeTab = val
    },
    /**
     * setter event for instagram posting api
     * @param val
     */
    setInstaPostingApi(val) {
      this.instagramPostingMethod = val
    },

    setInstagramPostType(val) {
      this.instagramPostType = val
    },

    setFacebookBackgroundId(val) {
      this.facebookOptions.facebook_background_id = val
    },

    setFacebookShareToStory(val) {
      this.facebookOptions.facebook_share_to_story = val
    },

    setInstagramCollaborators(val) {
      this.instagramCollaborators = val
    },

    setInstagramShareToStory(val) {
      this.instagramShareToStory = val
    },

    setFacebookPostType(val) {
      this.facebookOptions = clone({
        ...this.facebookOptions,
        post_type: val,
      })
    },

    setFacebookVideoTitle(val) {
      if (this.common_box_status) {
        this.sharing_details.common_sharing_details.video.title = val
      } else {
        this.sharing_details.video.title = val
      }
    },

    setYoutubePostType(val) {
      this.youtubeOptions.post_type = val
    },

    clearCarouselCards() {
      this.carouselUrl = {
        url: '',
        image: '',
        title: '',
        description: '',
      }
      this.carouselOptions = merge({}, defaultCarouselOptions, {
        accounts: this.carouselOptions.accounts,
      })
      this.carouselOptions.is_carousel_post = true
    },

    /**
     * Setter for first comment accounts list
     * @param firstCommentAccountsList
     */
    setFirstCommentAccountsList(firstCommentAccountsList) {
      console.log(
        'METHOD::setFirstCommentAccountsList ~ firstCommentAccountsList -> ',
        firstCommentAccountsList
      )
      this.firstCommentAccountsList = firstCommentAccountsList
    },

    refreshPlannerTable() {
      console.log(
        'METHOD::refreshPlannerTable ~ route name -> ',
        this.$route.name
      )
      if (
        this.$route.name === 'planner_list_v2' ||
        this.$route.name === 'planner_list_compact_v2' ||
        this.$route.name === 'planner_feed_v2' ||
        this.$route.name === 'planner_calendar_v2'
      ) {
        EventBus.$emit('refreshPlannerTableV2')
      }

      if (this.$route.name.includes('_grid_view')) {
        EventBus.$emit('refresh-planner-grid-view', this.gridViewPostData)
      }
    },

    /**
     * Sets behavioral YouTube and gmb options settings on account select. Emitted from AccountSelectionAside.vue
     * @param channelType
     * @param options
     */
    setYoutubeGmbOptions(channelType, options) {
      console.log(
        'METHOD::setYoutubeGmbOptions ~ channelType, options -> ',
        channelType,
        options
      )
      if (channelType === 'youtube' && !this.youtubeOptionsEdited) {
        this.youtubeOptions = { ...this.youtubeOptions, ...options }
      } else if (
        channelType === 'gmb' &&
        !this.gmbOptionsEdited &&
        options?.cta_link
      ) {
        this.gmbOptions.action_type = options.action_type.toUpperCase()
        if (this.common_box_status) {
          this.sharing_details.common_sharing_details.cta_link =
            options.cta_link
        } else {
          this.sharing_details.gmb_sharing_details.cta_link = options.cta_link
        }
      }
    },

    /**
     * Sets social setting preferences on auto-save and user action button for posting.
     */
    setSocialSettingsPreferences() {
      console.log(
        'METHOD::setSocialSettingsPreferences ~ youtubeOptions -> ',
        this.youtubeOptions
      )
      const selectedYoutubeId =
        this.account_selection.youtube[
          this.account_selection.youtube.length - 1
        ]
      const gmbAccountIds = this.account_selection.gmb

      // set youTube preference settings
      const youtubeAccount = this.getSocialAccountsList.find(
        (channel) => channel[channel.channel_identifier] === selectedYoutubeId
      )
      if (youtubeAccount) {
        youtubeAccount.social_preference_settings = clone(this.youtubeOptions)
        delete youtubeAccount.social_preference_settings.title
        delete youtubeAccount.social_preference_settings.tags
      }

      // set gmb preference settings
      gmbAccountIds.forEach((id) => {
        const gmbOptions = clone(this.gmbOptions)
        const socialAccount = this.getSocialAccountsList.find(
          (account) => account[account.channel_identifier] === id
        )
        if (
          gmbOptions.topic_type.toLowerCase() === 'event' ||
          gmbOptions.topic_type.toLowerCase() === 'standard'
        ) {
          const actionType = gmbOptions.action_type
          const buttonLink = this.common_box_status
            ? this.sharing_details.common_sharing_details.cta_link
            : this.sharing_details.gmb_sharing_details.cta_link
          socialAccount.social_preference_settings = gmbOptions
          socialAccount.social_preference_settings.action_type = actionType
          socialAccount.social_preference_settings.cta_link = buttonLink
          this.gmbOptions.cta_link = buttonLink
        }
      })
    },

    /**
     * Upload images link to media library and then push uploaded images to images array
     * @param images
     * @returns {Promise<void>}
     */
    async uploadImagesFromLink(images) {
      const self = this
      const type = this.common_box_status ? 'common' : this.activeTab
      const oldComposer = self.$refs['image-generator'].oldComposer
      const imagesList = []
      this.selectedTab = this.common_box_status ? 'common' : this.activeTab
      const details = this.sharing_details[type + '_sharing_details']
      if (images.length === 0) {
        this.alertMessage('Image is required', 'error')
        return
      }

      // For in case of multimedia we wil not check this
      if (!this.isTypeCommonOrInstagram(type)) {
        if (
          (details.video && details.video.link) ||
          this.getCommonSharingDetails.video.link
        ) {
          // checking video already uploaded or not showing modal to confirm the video replacing
          if (!(await self.confirmCurrentTypeReplace(self.type))) return
        }
        details.video = videoDefaultSharingDetails
        this.getCommonSharingDetails.video = videoDefaultSharingDetails
      }

      try {
        // if already uploading then return
        if (self.$refs['image-generator'].mediaUploading) {
          return
        }

        self.$refs['image-generator'].mediaUploading = true
        EventBus.$emit('image-uploading', true)
        const filters = {
          images,
          folder_id: null,
        }
        await this.uploadByteFilesHelper(
          filters,
          (status, message, mediaContainer) => {
            if (mediaContainer) {
              self.$store.dispatch('updateSocialSharingMedia', mediaContainer)

              // For type instagram and common we will allow the multimedia
              if (this.isTypeCommonOrInstagram(type)) {
                console.debug('TYPE::common_or_instagram', details, imagesList)
                EventBus.$emit('add-media-from-media-library', {
                  type,
                  media: mediaContainer.map((el) => el.link),
                  mediaType: 'multimedia',
                })
              } else {
                mediaContainer.forEach((item) => {
                  if (status && item && item.link && !oldComposer) {
                    self.$refs['main-composer'].addSingleImage(type, item.link)
                  }
                  imagesList.push(item.link)
                })

                oldComposer &&
                  this.setCommonSharingImage([
                    ...this.getCommonSharingDetails.image,
                    ...imagesList,
                  ])
              }
            }
            EventBus.$emit('image-uploading', false)
            self.$refs['image-generator'].mediaUploading = false
          }
        )
      } catch (exception) {
        this.alertMessage(UNKNOWN_ERROR, 'error')
        EventBus.$emit('image-uploading', false)
        self.$refs['image-generator'].mediaUploading = false
      }
    },

    updateCarouselCards(cards) {
      if (cards?.length) {
        this.carouselOptions.cards = cards
      }
    },
    addMediaToEditor(media) {
    console.log('METHOD::addMediaToEditor ~ media -> ', media)
     const type = this.common_box_status ? 'common' : this.activeTab
     const imagesList = []
     const self = this
     const oldComposer = self.$refs['image-generator'].oldComposer

    // incase of image just add the link but in case of video we need the whole object
    const temp = media?.map((el) => {
      if (el?.mime_type?.includes('video')) return el
      return  el?.link
    })
    this.$store.dispatch('updateSocialSharingMedia', temp)

     // For type instagram and common we will allow the multimedia
     if (this.isTypeCommonOrInstagram(type)) {
         EventBus.$emit('add-media-from-media-library', {
             type,
             media: temp,
             mediaType: 'multimedia',
         })
     } else {
         media?.forEach((item) => {
             if (item && item.link && !oldComposer) {
                 self.$refs['main-composer'].addSingleImage(type, item.link)
             }
             imagesList.push(item.link)
         })

         oldComposer &&
             this.setCommonSharingImage([
                 ...this.getCommonSharingDetails.image,
                 ...imagesList,
             ])
     }
 },
  },
}
</script>

<style>
@keyframes minimizeEffect {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: scale(0.3) translate(50%, 50%);
    opacity: 0.8;
  }
}

.mac-open {
  animation: macOpen 0.3s ease-out forwards;
}

.mac-close {
  animation: minimizeEffect 0.3s ease-in-out forwards;
}
</style>
