<template>
  <div
      class="cst-editor color-border bg-white rounded-xl border border-gray-200 p-6 mt-4 relative"
      :class="{ 'drag-active': isDragging }"
      @dragenter.prevent="dragEnter"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="handleDrop"
      @paste.prevent="handlePaste"
  >
    <!-- Drag overlay: Displayed when files are being dragged over the component -->
    <div v-if="isDragging" class="drag-overlay">
      <div class="drag-message">
        <img
        src="@assets/img/composer/upload-icon.svg"
        alt=""
        class="w-10 h-10 my-2"
      />
        <p>Drop here</p>
      </div>
    </div>

    <!-- Main upload area: Clickable to open file selection dialog -->
    <div class="text-center mb-6 " >
      <div class="p-3">
        <img
          src="@assets/img/composer/upload-icon.svg"
          alt=""
          class="w-10 h-10 mx-auto cursor-pointer"
          @click="triggerFileInput"
        />
      </div>
      <h5 class="my-2 font-medium"
        >Paste, Drop or <span class="text-[#2E7CFF] hover:underline cursor-pointer" @click="triggerFileInput">Upload</span></h5
      >
      <p class="font-normal text-sm text-gray-700">JPEG, PNG, GIF, MP4, PDF</p>
    </div>

    <!-- Hidden file inputs for different file types -->
    <input
        ref="fileInput"
        type="file"
        multiple
        :accept="SUPPORTED_FILE_TYPES.image.join(', ') + ', ' + SUPPORTED_FILE_TYPES.video.join(', ') + ', ' + SUPPORTED_FILE_TYPES.pdf.join(', ')"
        class="hidden"
        @change="handleFileUpload"
    />


    <!-- Media source options -->
    <div class="flex justify-center items-center gap-x-1">
      <!-- Media Library button -->
      <div
        v-tooltip="'Select Media from Media Library'"
        class="mx-1 cursor-pointer px-2.5 py-2 flex items-center justify-center bg-cs-ultra-violet hover:bg-opacity-75 transition-all ease-in-out rounded-md hover-trigger relative"
        @click="openMediaLibrary"
      >
        <img src="@assets/img/composer/media-icon.svg" alt="" class="w-5" />
        <span class="ml-2 text-sm font-normal text-black-200 leading-none hidden 2xl:block"
          >Media Library</span
        >
      </div>

      <!-- Google Drive button -->
      <div
        v-tooltip="'Select Media from Drive'"
        class="crello-dropdown mx-1 cursor-pointer px-2.5 py-2 flex items-center justify-center bg-cs-ultra-blue hover:bg-opacity-75 transition-all ease-in-out rounded-md hover-trigger relative"
        @click="openDrive"
      >
        <img
          src="@assets/img/composer/google-drive.svg"
          alt="Google Drive Logo"
          class="w-5"
        />
        <span class="ml-2 text-sm font-normal text-black-200 leading-none hidden 2xl:block"
          >Drive</span
        >
      </div>

      <!-- Dropbox button -->
      <div
        v-tooltip="'Select Media from Dropbox'"
        class="mx-1 cursor-pointer px-2.5 py-2 flex items-center justify-center bg-cs-ultra-green hover:bg-opacity-75 transition-all ease-in-out rounded-md hover-trigger relative"
        @click="openDropbox"
      >
        <img src="@assets/img/composer/dropbox.svg" alt="" class="w-5" />
        <span class="ml-2 text-sm font-normal text-black-200 leading-none hidden 2xl:block"
          >Dropbox</span
        >
      </div>

    <template v-if="!isWhiteLabelDomain">

         <!-- Separator -->
         <div class="border border-r-2 border-gray-200 h-6 mx-4"></div>

         <!-- Canva button with dropdown -->
         <div
           v-tooltip="'Design image with Canva'"
           class="canva-dropdown mx-1 cursor-pointer px-2.5 py-2 flex items-center justify-center bg-cs-ultra-green hover:bg-opacity-75 transition-all ease-in-out rounded-md hover-trigger relative"
         >
           <img src="@assets/img/composer/canva-logo.svg" alt="" class="w-5" />
           <span class="ml-2 text-sm font-normal text-black-200 leading-none hidden 2xl:block"
             >Canva</span
           >
           <div
             class="hover-target group absolute top-full left-0 bg-white rounded p-1 shadow text-sm w-44 z-50"
           >
             <div
               class="p-2 hover:bg-cs-secondary"
               @click="openCanva('SocialMedia')"
               >Social Media</div
             >
             <div
               class="p-2 hover:bg-cs-secondary"
               @click="openCanva('FacebookPost')"
               >Facebook Post</div
             >
             <div
               class="p-2 hover:bg-cs-secondary"
               @click="openCanva('TwitterPost')"
               >X (Twitter) Post</div
             >
             <div
               class="p-2 hover:bg-cs-secondary"
               @click="openCanva('InstagramPost')"
               >Instagram Post</div
             >
             <div
               class="p-2 hover:bg-cs-secondary"
               @click="openCanva('InstagramStory')"
               >Instagram Story</div
             >
             <div
               class="p-2 hover:bg-cs-secondary"
               @click="openCanva('YouTubeThumbnail')"
               >YouTube Thumbnail</div
             >
           </div>
         </div>

         <!-- Vista Create button with dropdown -->
         <div
           v-if="type !== 'youtube' || !hideVista"
           v-tooltip="'Design image with Vista'"
           class="crello-dropdown mx-1 cursor-pointer px-2.5 py-2 flex items-center justify-center bg-cs-ultra-blue hover:bg-opacity-75 transition-all ease-in-out rounded-md hover-trigger relative"
         >
           <img
             src="@assets/img/composer/vista-logo.svg"
             alt="VistaCreate Logo"
             class="w-5"
           />
           <span class="ml-2 text-sm font-normal text-black-200 leading-none hidden 2xl:block"
             >VistaCreate</span
           >
           <div
             class="hover-target group absolute top-full left-0 bg-white rounded p-1 shadow text-sm w-48 z-50"
           >
             <div
               class="p-2 hover:bg-cs-secondary"
               @click="openVista('facebookSM')"
               >Facebook SM</div
             >
             <div
               class="p-2 hover:bg-cs-secondary"
               @click="openVista('facebookVideoStoryAN')"
               >Facebook Video Story AN</div
             >
             <div
               class="p-2 hover:bg-cs-secondary"
               @click="openVista('instagramSM')"
               >Instagram SM</div
             >
             <div
               class="p-2 hover:bg-cs-secondary"
               @click="openVista('instagramVideoStoryAN')"
               >Instagram Video Story AN</div
             >
             <div class="p-2 hover:bg-cs-secondary" @click="openVista('twitterSM')"
               >X (Twitter) SM</div
             >
             <div
               class="p-2 hover:bg-cs-secondary"
               @click="openVista('tikTokVideoAN')"
               >TikTok Video AN</div
             >
           </div>
         </div>

         <!-- PostNitro button -->
         <div
           v-tooltip="'Design carousels with PostNitro'"
           class="mx-1 cursor-pointer px-2.5 py-2 flex items-center justify-center bg-cs-ultra-green hover:bg-opacity-75 transition-all ease-in-out rounded-md hover-trigger relative"
           @click="designWithPostnitro"
         >
           <img src="@assets/img/postnitro.svg" alt="" class="w-5" />
           <span class="ml-2 text-sm font-normal text-black-200 leading-none hidden 2xl:block"
             >PostNitro</span
           >
         </div>
    </template>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { EventBus } from '@common/lib/event-bus'
import {useStore} from "@state/base";
import usePermissions from '@common/composables/usePermissions';
import useWhiteLabelApplication from '@modules/setting/composables/whitelabel/useWhiteLabelApplication'

 defineProps({
  type: {
    type: String,
    default: 'common',
  },
})

const { dispatch } = useStore()
const emit = defineEmits(['media-action', 'handle-articles'])
const { hideVista } = usePermissions()
const { isWhiteLabelDomain } = useWhiteLabelApplication()

const isDragging = ref(false)
const dragCounter = ref(0)

const fileInput = ref(null)

// Define supported file types
const SUPPORTED_FILE_TYPES = {
  image: ['image/jpeg', 'image/png', 'image/gif','.gif','.jpeg','.jpg','.png','.heic'],
  video: ['video/mp4','video/avi','video/mov','video/m4v','.mp4','.avi','.mov','.m4v'],
  pdf: ['application/pdf']
}

const dragEnter = (event) => {
  event.preventDefault()
  event.stopPropagation()
  dragCounter.value++
  isDragging.value = true
}

const dragOver = (event) => {
  event.preventDefault()
  event.stopPropagation()
}

const dragLeave = (event) => {
  event.preventDefault()
  event.stopPropagation()
  dragCounter.value--
  if (dragCounter.value <= 0) {
    isDragging.value = false
    dragCounter.value = 0
  }
}

const handleDrop = (event) => {
  event.preventDefault()
  event.stopPropagation()
  isDragging.value = false
  dragCounter.value = 0

  const data = event.dataTransfer.getData('myData')

  // check if the event has article data
  if (data) {
    emit('handle-articles', event)
    return
  }

  const files = event.dataTransfer.files

  handleFiles(Array.from(files))
}


//  Method to handle file upload
const handleFiles = (files) => {
  // Group files by type
  const imageFiles = []
  const videoFiles = []
  const pdfFiles = []
  const unsupportedFiles = []

  files.forEach((file) => {
    const fileType = file.type.toLowerCase()
    if (SUPPORTED_FILE_TYPES.image.includes(fileType)) {
      imageFiles.push(file)
    } else if (SUPPORTED_FILE_TYPES.video.includes(fileType)) {
      videoFiles.push(file)
    } else if (SUPPORTED_FILE_TYPES.pdf.includes(fileType)) {
      pdfFiles.push(file)
    } else {
      unsupportedFiles.push(file)
    }
  })

  // Process each type in batch
  if (imageFiles.length) {
    emit('media-action', {
      type: 'upload',
      mediaType: 'image',
      files: imageFiles,
    })
  }

  if (videoFiles.length) {
    emit('media-action', {
      type: 'upload',
      mediaType: 'video',
      files: videoFiles,
    })
  }

  if (pdfFiles.length) {
    emit('media-action', {
      type: 'upload',
      mediaType: 'pdf',
      files: pdfFiles,
    })
  }

  // Reset file input
  if (fileInput.value?.value) {
    fileInput.value.value = null
  }

  // Show error for unsupported files
  if (unsupportedFiles.length) {
    dispatch('toastNotification',{
      message: `Unsupported file type.\n Supported types: ${SUPPORTED_FILE_TYPES.image.join(
        ', '
      )}, ${SUPPORTED_FILE_TYPES.video.join(', ')}, ${SUPPORTED_FILE_TYPES.pdf.join(
        ', '
      )}`,
      type: "error"
    })
  }
}

const triggerFileInput = () => {
  fileInput.value?.click()
}

const handleFileUpload = (event) => {
  const files = event.target.files
  handleFiles(Array.from(files))
}

const handlePaste = (event) => {
  const clipboardItems = event.clipboardData.items
  const files = []

  for (let i = 0; i < clipboardItems.length; i++) {
    const item = clipboardItems[i]
    if (item.kind === 'file') {
      const file = item.getAsFile()
      if (file && SUPPORTED_FILE_TYPES.image.includes(file.type) || SUPPORTED_FILE_TYPES.video.includes(file.type) || SUPPORTED_FILE_TYPES.pdf.includes(file.type)) {
        files.push(file)
      }
    }
  }

  if (files.length > 0) {
    handleFiles(files)
  } else {
    dispatch('toastNotification',{
      message:"No supported files found in clipboard data.",
      type:"error"
      })
    }
}

// Open media library
const openMediaLibrary = () => {
  emit('media-action', { type: 'openMediaLibrary' })
}

// Open Google Drive
const openDrive = () => {
  EventBus.$emit('show-media-library-modal', {
    source: 'common',
    details: {},
    sideTabIndex: 9,
  })
}

// Open Dropbox
const openDropbox = () => {
  EventBus.$emit('show-media-library-modal', {
    source: 'common',
    details: {},
    sideTabIndex: 8,
  })
}

// Open Canva with specific design type
const openCanva = (designType) => {
  emit('media-action', { type: 'openCanva', designType })
}

// Open Vista Create with specific design type
const openVista = (designType) => {
  emit('media-action', { type: 'openVista', designType })
}

// Open PostNitro
const designWithPostnitro = () => {
  emit('media-action', { type: 'openPostnitro' })
}

// Expose the triggerFileInput method to parent components
defineExpose({ triggerFileInput })
</script>

<style scoped>
/* Styles for the main container */
.cst-editor {
  transition: all 0.3s ease;
}

/* Styles for active drag state */
.drag-active {
  border: 2px dashed #2e7cff;
  background-color: rgba(46, 124, 255, 0.1);
}

/* Styles for the drag overlay */
.drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px dashed #2e7cff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

/* Styles for the drag message */
.drag-message {
  text-align: center;
  color: #2e7cff;
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
